import React from "react";
import { connect } from "react-redux";

import {
  closeModal,
  secondarySubmitModal,
  submitModal,
} from "../actions/modal";
import { modalProps } from "../selectors/modal";
import { GenericModal } from "../components/ui/modals/Modal";
import {
  MODAL_ADD_BACKUP_PERSON,
  MODAL_ADD_BENEFICIARY_CHARITY,
  MODAL_ADD_BENEFICIARY_PERSON,
  MODAL_ADD_CHILD_FORM,
  MODAL_ADD_CUSTOM_BENEFICIARY_CHARITY,
  MODAL_ADD_EXECUTORS,
  MODAL_ADD_FINANCIAL_ASSET,
  MODAL_ADD_INSURANCE_ASSET,
  MODAL_ADD_PET_GUARDIAN,
  MODAL_ADD_PHYSICAL_ASSET,
  MODAL_ADD_PROPERTY_ATTORNEY,
  MODAL_ADD_SPOUSE,
  MODAL_BNPL_EXPERIMENT,
  MODAL_CHANGE_SSO_PASSWORD,
  MODAL_DELETE_ACCOUNT,
  MODAL_DELETE_ACCOUNT_GOOGLE_SSO,
  MODAL_EDIT_BENEFICIARY_CHARITY,
  MODAL_EDIT_BENEFICIARY_CUSTOM_CHARITY,
  MODAL_EDIT_BENEFICIARY_PERSON,
  MODAL_EDIT_CHILD_FORM,
  MODAL_EDIT_EXECUTORS,
  MODAL_EDIT_PET,
  MODAL_ADD_PET,
  MODAL_ADD_GUARDIAN,
  MODAL_EDIT_GUARDIAN,
  MODAL_EDIT_PET_GUARDIAN,
  MODAL_EDIT_PROPERTY_ATTORNEY,
  MODAL_EDIT_SPOUSE,
  MODAL_INVITE_USER,
  MODAL_REMOVE_BENEFICIARY,
  MODAL_ADD_PERSONAL_ATTORNEY,
  MODAL_EDIT_PERSONAL_ATTORNEY,
} from "../constants/modal";
import DeleteAccountForm from "../components/forms/your-account/DeleteAccountForm";
import InviteUserForm from "../components/forms/your-account/InviteUserForm";
import PhysicalAssetForm from "../components/forms/asset-list/PhysicalAssetForm";
import InsuranceAssetForm from "../components/forms/asset-list/InsuranceAssetForm";
import FinancialAssetForm from "../components/forms/asset-list/FinancialAssetForm";
import PersonAllocationForm from "../components/forms/your-estate/PersonAllocationForm";
import CharityAllocationForm from "../components/forms/your-estate/CharityAllocationForm";
import CustomCharityAllocationForm from "../components/forms/your-estate/CustomCharityAllocationForm";
import AddPersonBackupForm from "../components/forms/your-estate/AddPersonBackupForm";
import RemoveBeneficiaryForm from "../components/forms/your-estate/RemoveBeneficiaryForm";
import AddEditExecutorsForm from "../components/forms/your-arrangements/AddEditExecutorsForm";
import BNPLForm from "../components/forms/checkout/BNPLForm";
import DeleteGoogleSSOAccountForm from "../components/forms/your-account/DeleteGoogleSSOAccountForm";
import { resetBNPLExperimentForm } from "../actions/checkout";
import { TextButton } from "../components/ui/Button";
import { Body } from "../components/ui/Typography";
import ChildModal from "../components/forms/your-family/ChildModal";
import SpouseModal from "../components/forms/your-family/SpouseModal";
import PetModal from "../components/forms/your-family/PetModal";
import ChildrenGuardiansModal from "../components/forms/your-family/ChildrenGuardiansModal";
import PetGuardianModal from "../components/forms/your-family/PetGuardianModal";
import PropertyAttorneyModal from "../components/forms/your-arrangements/PropertyAttorneyModal";
import PersonalAttorneyModal from "../components/forms/your-arrangements/PersonalAttorneyModal";

const renderChildren = (modalKey, submitModal, secondarySubmitModal, props) => {
  switch (modalKey) {
    case MODAL_CHANGE_SSO_PASSWORD: {
      const { translations } = props;
      const { paragraphOne, paragraphTwo, linkText, link } = translations[
        modalKey
      ];
      return (
        <>
          <Body>
            <p>{paragraphOne}</p>
            <p style={{ margin: 0 }}>
              {paragraphTwo}{" "}
              <TextButton
                onClick={() => window.open(link, "_blank")}
                text={linkText}
              />
            </p>
          </Body>
        </>
      );
    }
    case MODAL_INVITE_USER:
      return (
        <InviteUserForm
          onSubmit={() => submitModal(MODAL_INVITE_USER)}
          {...props}
        />
      );
    case MODAL_DELETE_ACCOUNT:
      return (
        <DeleteAccountForm
          onSubmit={() => submitModal(MODAL_DELETE_ACCOUNT)}
          {...props}
        />
      );
    case MODAL_DELETE_ACCOUNT_GOOGLE_SSO:
      return (
        <DeleteGoogleSSOAccountForm
          onSubmit={() => submitModal(MODAL_DELETE_ACCOUNT_GOOGLE_SSO)}
          {...props}
        />
      );
    case MODAL_ADD_PHYSICAL_ASSET:
      return (
        <PhysicalAssetForm
          onSubmit={() => submitModal(MODAL_ADD_PHYSICAL_ASSET)}
          handleDelete={() => secondarySubmitModal(MODAL_ADD_PHYSICAL_ASSET)}
          {...props}
        />
      );
    case MODAL_ADD_FINANCIAL_ASSET:
      return (
        <FinancialAssetForm
          onSubmit={() => submitModal(MODAL_ADD_FINANCIAL_ASSET)}
          handleDelete={() => secondarySubmitModal(MODAL_ADD_FINANCIAL_ASSET)}
          {...props}
        />
      );
    case MODAL_ADD_INSURANCE_ASSET:
      return (
        <InsuranceAssetForm
          onSubmit={() => submitModal(MODAL_ADD_INSURANCE_ASSET)}
          handleDelete={() => secondarySubmitModal(MODAL_ADD_INSURANCE_ASSET)}
          {...props}
        />
      );
    case MODAL_ADD_BENEFICIARY_PERSON:
    case MODAL_EDIT_BENEFICIARY_PERSON:
      return (
        <PersonAllocationForm
          onSubmit={() => submitModal(modalKey)}
          {...props}
        />
      );
    case MODAL_ADD_BENEFICIARY_CHARITY:
      return (
        <CharityAllocationForm
          onSubmit={() => {
            submitModal(modalKey);
          }}
          handleManualCharityClick={() => secondarySubmitModal(modalKey)}
          {...props}
        />
      );
    case MODAL_EDIT_BENEFICIARY_CHARITY:
      return (
        <CharityAllocationForm
          onSubmit={() => {
            submitModal(modalKey);
          }}
          {...props}
        />
      );
    case MODAL_ADD_CUSTOM_BENEFICIARY_CHARITY:
    case MODAL_EDIT_BENEFICIARY_CUSTOM_CHARITY:
      return (
        <CustomCharityAllocationForm
          onSubmit={() => submitModal(modalKey)}
          {...props}
        />
      );
    case MODAL_REMOVE_BENEFICIARY:
      return (
        <RemoveBeneficiaryForm
          onSubmit={() => submitModal(modalKey)}
          {...props}
        />
      );
    case MODAL_ADD_BACKUP_PERSON:
      return (
        <AddPersonBackupForm
          onSubmit={() => submitModal(modalKey)}
          {...props}
        />
      );
    case MODAL_ADD_EXECUTORS:
    case MODAL_EDIT_EXECUTORS:
      return (
        <AddEditExecutorsForm
          onSubmit={() => submitModal(modalKey)}
          {...props}
        />
      );
    case MODAL_ADD_CHILD_FORM:
    case MODAL_EDIT_CHILD_FORM:
      return <ChildModal onSubmit={() => submitModal(modalKey)} {...props} />;
    case MODAL_BNPL_EXPERIMENT:
      return (
        <BNPLForm
          onSubmit={() => submitModal(modalKey)}
          // Will need to revisit for proper functionality
          {...props}
        />
      );
    case MODAL_ADD_SPOUSE:
    case MODAL_EDIT_SPOUSE:
      return <SpouseModal onSubmit={() => submitModal(modalKey)} {...props} />;
    case MODAL_ADD_PET:
    case MODAL_EDIT_PET:
      return <PetModal onSubmit={() => submitModal(modalKey)} {...props} />;
    case MODAL_ADD_GUARDIAN:
    case MODAL_EDIT_GUARDIAN:
      return (
        <ChildrenGuardiansModal
          onSubmit={() => submitModal(modalKey)}
          {...props}
        />
      );
    case MODAL_ADD_PET_GUARDIAN:
    case MODAL_EDIT_PET_GUARDIAN:
      return (
        <PetGuardianModal onSubmit={() => submitModal(modalKey)} {...props} />
      );
    case MODAL_ADD_PROPERTY_ATTORNEY:
    case MODAL_EDIT_PROPERTY_ATTORNEY:
      return (
        <PropertyAttorneyModal
          onSubmit={() => submitModal(modalKey)}
          {...props}
        />
      );
    case MODAL_ADD_PERSONAL_ATTORNEY:
    case MODAL_EDIT_PERSONAL_ATTORNEY:
      return (
        <PersonalAttorneyModal
          onSubmit={() => submitModal(modalKey)}
          {...props}
        />
      );
    default:
      return null;
  }
};

const getCustomCloseModal = (modalKey, restOfProps) => {
  switch (modalKey) {
    case MODAL_BNPL_EXPERIMENT:
      return () => restOfProps.resetBNPLExperimentForm(modalKey);
    default:
      return null;
  }
};

export const Modal = ({
  modalKey,
  isVisible,
  closeModal,
  submitModal,
  secondarySubmitModal,
  ...restOfProps
}) => {
  if (!isVisible) return null;

  // this allows us to call a custom action when closing the modal
  // using the close "x" button
  const customCloseModal = getCustomCloseModal(modalKey, restOfProps);
  // NOTE: analytics events are only triggered for modal closes that have a key
  // I.e. if a modal is generically closed (e.g. by opening another modal), then the Close Modal analytics event will NOT fire
  const closeModalWithKey = () => closeModal(modalKey);

  return (
    <GenericModal
      onClose={customCloseModal || closeModalWithKey}
      onSubmit={() => submitModal(modalKey)}
      {...restOfProps}
    >
      {renderChildren(modalKey, submitModal, secondarySubmitModal, {
        closeModal: closeModalWithKey,
        ...restOfProps,
      })}
    </GenericModal>
  );
};

export default connect(modalProps, {
  submitModal,
  secondarySubmitModal,
  closeModal,
  resetBNPLExperimentForm,
})(Modal);
