import React, { useEffect } from "react";
import { connect } from "react-redux";

import { personalAttorneysNextPage } from "../../../actions/personal-attorneys";
import { personalAttorneysPageProps } from "../../../selectors/personal-attorneys";

import PersonalAttorneysForm from "../../../components/forms/your-arrangements/PersonalAttorneysForm";
import { PROPERTY_ATTORNEYS_PATH } from "../../../constants/routes";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import { PERSONAL_ATTORNEYS_FORM_ID } from "../../../constants/forms";
import { getFormData } from "../../../actions/forms";

const PersonalAttorneysPage = ({
  getFormData,
  hasLoaded,
  personalAttorneysNextPage,
  ...restOfProps
}) => {
  useEffect(() => {
    getFormData(PERSONAL_ATTORNEYS_FORM_ID);
  }, [getFormData]);
  if (!hasLoaded) return <LoadingSpinner />;
  return (
    <PersonalAttorneysForm
      backLink={PROPERTY_ATTORNEYS_PATH}
      onSubmit={() => personalAttorneysNextPage()}
      {...restOfProps}
    />
  );
};

export default connect(personalAttorneysPageProps, {
  getFormData,
  personalAttorneysNextPage,
})(PersonalAttorneysPage);
