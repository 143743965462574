export const SPOUSE_QUERY_PARAM = "?spouse_nav=true";

// API Endpoints
const API_ROOT = "/api/v1";
export const EXTERNAL_LOGIN_ENDPOINT = `${API_ROOT}/auth/external_login`;
export const AUTH_SIGNUP_ENDPOINT = `${API_ROOT}/auth`;
export const SIGNUP_ENDPOINT = `${API_ROOT}/auth/sign_up`;
export const AUTH_DELETE_ACCOUNT_ENDPOINT = `${API_ROOT}/auth`;
export const AUTH_DELETE_GOOGLE_SSO_ACCOUNT_ENDPOINT = `${API_ROOT}/auth/delete_google_sso_account`;
export const AUTH_LOGIN_ENDPOINT = `${API_ROOT}/auth/sign_in`;
export const AUTH_SIGNOUT_ENDPOINT = `${API_ROOT}/auth/sign_out`;
export const AUTH_PASSWORD_ENDPOINT = `${API_ROOT}/auth/password`;
export const AUTH_PASSWORD_UPDATE_ENDPOINT = `${API_ROOT}/auth/passwords/authenticated`;
export const AUTH_VALIDATE_TOKEN_ENDPOINT = `${API_ROOT}/auth/validate_token`;
export const INVITE_USER_ENDPOINT = `${API_ROOT}/invites`;
export const VALIDATE_INVITE_TOKEN_ENDPOINT = `${API_ROOT}/invites/validate`;
export const USER_ENDPOINT = `${API_ROOT}/user`;
export const USER_RESEARCH_PARTICIPANTION_ENDPOINT = `${API_ROOT}/user/update_research_participation`;
export const USER_REASONS_ENDPOINT = `${API_ROOT}/user/update_reasons`;
export const USER_AGREED_TO_TOS_ENDPOINT = `${API_ROOT}/user/agreed_to_tos`;
export const CHECK_EMAIL_AVAILABILITY_ENDPOINT = `${API_ROOT}/auth/check_email_availability`;
export const FORGOT_SSO_PASSWORD_ENDPOINT = `${API_ROOT}/auth/passwords/forgot_current_user_password`;
export const PLANS_ENDPOINT = `${API_ROOT}/plans`;
export const UPGRADE_PLANS_ENDPOINT = `${API_ROOT}/upgrades`;
export const CART_ENDPOINT = `${API_ROOT}/cart`;
export const DASHBOARD_ENDPOINT = `${API_ROOT}/`;
export const SPOUSE_ENDPOINT = `${API_ROOT}/spouse`;
export const SPOUSE_NEXT_PAGE_ENDPOINT = `${API_ROOT}/spouse/next_page`;
export const CHILDREN_ENDPOINT = `${API_ROOT}/children`;
export const CHILDREN_NEXT_PAGE_ENDPOINT = `${API_ROOT}/children/next_page`;
export const PETS_ENDPOINT = `${API_ROOT}/pets`;
export const PETS_NEXT_PAGE_ENDPOINT = `${API_ROOT}/pets/next_page`;
export const GIFTS_ENDPOINT = `${API_ROOT}/gifts`;
export const DONATIONS_ENDPOINT = `${API_ROOT}/donations`;
export const EXECUTORS_ENDPOINT = `${API_ROOT}/executors`;
export const EXECUTORS_NEXT_PAGE_ENDPOINT = `${API_ROOT}/executors/next_page`;
export const PROPERTY_ATTORNEYS_ENDPOINT = `${API_ROOT}/property_attorneys`;
export const PERSONAL_ATTORNEYS_ENDPOINT = `${API_ROOT}/personal_attorneys`;
export const PERSONAL_ATTORNEYS_NEXT_PAGE_ENDPOINT = `${API_ROOT}/personal_attorneys/next_page`;
export const CHILDREN_GUARDIANS_ENDPOINT = `${API_ROOT}/guardians`;
export const CHILDREN_GUARDIANS_NEXT_PAGE_ENDPOINT = `${API_ROOT}/guardians/next_page`;
export const PET_GUARDIANS_ENDPOINT = `${API_ROOT}/pet_guardians`;
export const PET_GUARDIANS_UPDATE_ENDPOINT = `${API_ROOT}/pet_guardians/update_pet_guardian`;
export const PET_GUARDIANS_NEXT_PAGE_ENDPOINT = `${API_ROOT}/pet_guardians/next_page`;
export const ALLOCATIONS_PRIMARY_ENDPOINT = `${API_ROOT}/allocations/primary`;
export const ALLOCATIONS_ENDPOINT = `${API_ROOT}/allocations`;
export const ALLOCATIONS_PREDECEASE_ENDPOINT = `${API_ROOT}/allocations/predecease`;
export const BACKUPS_ENDPOINT = `${API_ROOT}/backups`;
export const INHERITANCE_ENDPOINT = `${API_ROOT}/inheritance`;
export const CHECKOUT_ENDPOINT = `${API_ROOT}/payment`;
export const FETCH_CHECKOUT_ENDPOINT = `${API_ROOT}/payment/show_payment_page`;
export const PARTNER_DISCOUNT_ENDPOINT = `${API_ROOT}/payment/partner_discount`;
export const APPLY_PARTNER_DISCOUNT_ENDPOINT = `${API_ROOT}/payment/reapply_partner_discount`;
export const PROMO_CODE_ENDPOINT = `${API_ROOT}/payment/promo`;
export const RECEIPT_ENDPOINT = `${API_ROOT}/payment/receipt`;
export const VALIDATE_CAA_ENDPOINT = `${API_ROOT}/payment/validate_caa_membership`;
export const WISHES_ENDPOINT = `${API_ROOT}/wishes`;
export const HEALTHCARE_ENDPOINT = `${API_ROOT}/healthcare`;
export const DOCUMENTS_ENDPOINT = `${API_ROOT}/documents`;
export const LEADS_ENDPOINT = `${API_ROOT}/leads`;
export const PROVINCES_ENDPOINT = `${API_ROOT}/provinces`;
export const REFERRAL_ENDPOINT = `${API_ROOT}/payment/referral`;
export const PURCHASES_ENDPOINT = `${API_ROOT}/purchases`;
export const LANGUAGE_ENDPOINT = `${API_ROOT}/language`;
export const NOTIFICATIONS_ENDPOINT = `${API_ROOT}/notifications`;
export const ASSET_LIST_ENDPOINT = `${API_ROOT}/assets`;
export const WELCOME_BACK_ENDPOINT = `${API_ROOT}/welcome-back`;
export const UPDATE_PAYMENT_METHOD_ENDPOINT = `${API_ROOT}/payment_methods/update`;
export const STRIPE_SESSION_STATUS_ENDPOINT = `${API_ROOT}/payment/stripe_session_status`;
export const PROCESS_STRIPE_CHECKOUT_SESSION_ENDPOINT = `${API_ROOT}/payment/process_stripe_checkout_session`;
export const CREATE_STRIPE_CLIENT_SECRET_ENDPOINT = `${API_ROOT}/payment/create_stripe_client_secret`;
export const GOOGLE_OAUTH_ENDPOINT = `${API_ROOT}/auth/google_auth`;

// Front and Back End Paths

export const DASHBOARD_PATH = "/";
export const YOUR_ACCOUNT_PATH = "/your-account";
export const CLAIM_INVITE_PATH = "/claim-invite";
export const INVALID_INVITE_PATH = "/invalid-invite";
export const FULL_NAME_PATH = "/full-name";
export const DOB_PATH = "/date-of-birth";
export const RESIDENCE_PATH = "/residence";
export const GENDER_PATH = "/gender";
export const THANK_YOU_REDIRECT_PATH = "/thank-you";
export const SORRY_PATH = "/sorry";
export const MARITAL_STATUS_PATH = "/marital-status";
export const SPOUSE_PATH = "/spouse";
export const CHILDREN_PATH = "/children";
export const CHILDREN_GUARDIANS_PATH = "/guardians";
export const PETS_PATH = "/pets";
export const PET_GUARDIANS_PATH = "/pet-guardians";
export const PETS_TRUST_PATH = "/pet-trust";
export const EXECUTORS_PATH = "/executors";
export const PET_TRUST_PATH = "/pet-trust";
export const GIFTS_PATH = "/gifts";
export const DONATIONS_PATH = "/donations";
export const PRIMARY_BENEFICIARY_PATH = "/primary-beneficiary";
export const ALLOCATIONS_PATH = "/allocations";
export const PREDECEASE_PATH = "/predecease";
export const BACKUPS_PATH = "/backups";
export const INHERITANCE_PATH = "/inheritance";
export const PROPERTY_ATTORNEYS_PATH = "/property_attorneys";
export const PERSONAL_ATTORNEYS_PATH = "/personal_attorneys";
export const WISHES_RESTING_PATH = "/wishes/resting";
export const ASHES_PATH = "/wishes/ashes";
export const WISHES_CEREMONY_PATH = "/wishes/ceremony";
export const LIFE_PROLONG_PATH = "/healthcare/life_prolong";
export const PAIN_MANAGE_PATH = "/healthcare/pain_manage";
export const SPOUSE_GIFTS_PATH = "/spouse-gifts";
export const SPOUSE_DONATIONS_PATH = "/spouse-donations";
export const SPOUSE_EXECUTORS_PATH = "/spouse-executors";
export const SPOUSE_PROPERTY_ATTORNEYS_PATH = "/spouse-property-attorneys";
export const SPOUSE_PERSONAL_ATTORNEYS_PATH = "/spouse-personal-attorneys";
export const SPOUSE_RESTING_PATH = "/spouse-resting";
export const SPOUSE_ASHES_PATH = "/spouse-ashes";
export const SPOUSE_CEREMONY_PATH = "/spouse-ceremony";
export const SPOUSE_PAIN_MANAGE_PATH = "/spouse-pain-manage";
export const SPOUSE_LIFE_PROLONG_PATH = "/spouse-life-prolong";
export const CHECKOUT_PATH = "/payment";
export const RECEIPT_PATH = "/payment/receipt";
export const PLANS_PATH = "/plans";
export const PLANS_COMPARISON_TABLE_PATH = "/plans#essentials";
export const DOCUMENTS_PATH = "/documents";
export const DOCUMENTS_ASSETS_PATH = "/documents#asset-list";
export const LOGIN_PATH = "/login";
export const SIGNUP_PATH = "/sign-up";
export const EXTERNAL_LOGIN_PATH = "/external-login";
export const FORGOT_PASSWORD_PATH = "/forgot-password";
export const RESET_PASSWORD_PATH = "/reset-password";
export const RESET_SUCCESS_PATH = "/reset-successful";
export const ACCOUNT_DELETED_PATH = "/account-deleted";
export const REVIEW_ABOUT_YOU_PATH = "/review-about-you";
export const REVIEW_YOUR_ARRANGEMENTS_PATH = "/review-your-arrangements";
export const REVIEW_YOUR_ESTATE_PATH = "/review-your-estate";
export const REVIEW_YOUR_FAMILY_PATH = "/review-your-family";
export const REVIEW_SPOUSE_ESTATE_PATH = "/review-spouse-estate";
export const REVIEW_SPOUSE_ARRANGEMENTS_PATH = "/review-spouse-arrangements";
export const INTENDED_USE_PATH = "/intended-use";
export const PREMIUM_RECOMMENDED_PATH = "/premium-recommendation";
export const FAMILY_RECOMMENDED_PATH = "/family-recommendation";
export const WELCOME_PATH = "/welcome";
export const WELCOME_BACK_PATH = "/welcome-back";
export const ASSET_LIST_OVERVIEW_PATH = "/asset-list-overview";
export const IMPORTANT_NOTE_PATH = "/important-note";
export const OVERVIEW_HOW_IT_WORKS_PATH = "/how-it-works";
export const OVERVIEW_SELECT_A_PLAN_PATH = "/select-a-plan-overview";
export const OVERVIEW_SPOUSE_ESTATE_PATH = "/spouse-estate-overview";
export const OVERVIEW_SPOUSE_ARRANGEMENTS_PATH =
  "/spouse-arrangements-overview";
export const OVERVIEW_CONGRATULATIONS_PATH = "/congratulations";
export const APPOINTEES_PATH = "/appointees";
export const ASSET_LIST_PATH = "/asset-list";
export const BACKUPS_GUIDANCE_PATH = "/backups-guidance";
export const ALLOCATIONS_GUIDANCE_PATH = "/allocations-guidance";
export const FAMILY_PLAN_GUIDANCE_PATH = "/family-plan-guidance";
export const ESSENTIALS_PLAN_GUIDANCE_PATH = "/essentials-plan-guidance";
export const PREMIUM_PLAN_GUIDANCE_PATH = "/premium-plan-guidance";
export const ESSENTIALS_PLAN_DOC_PREVIEW_PATH = "/essentials-doc-preview";
export const PREMIUM_PLAN_DOC_PREVIEW_PATH = "/premium-doc-preview";

export const WEBSITE_URL = "https://willful.co";
export const CANADA_HELPS_URL = "https://www.canadahelps.org";
export const CANADA_HELPS_FR_URL = "https://www.canadahelps.org/fr/";
export const RETHINK_BREAST_CANCER_URL = "https://rethinkbreastcancer.com/";
export const CHANGE_PLANS_URL =
  "https://willful.zendesk.com/hc/en-us/articles/360042440894-How-do-I-change-my-plan-on-Willful";
export const EMAIL_US_CHANGE_TO_ESSENTIALS_ONLY_PROVINCE_EN =
  "mailto:support@willful.co?subject=New%20Brunswick%20Essentials%20Plan%20Update";
export const EMAIL_US_CHANGE_TO_ESSENTIALS_ONLY_PROVINCE_FR =
  "mailto:support@willful.co?subject=Mise%20à%20jour%20de%20forfait%20Essentiels%20Légales%20de%20Nouveau-Brunswick";
export const EMAIL_US_CHANGE_TO_NEW_PROVINCE_EN =
  "mailto:support@willful.co?subject=New%20province%20plan%20update";
export const EMAIL_US_CHANGE_TO_NEW_PROVINCE_FR =
  "mailto:support@willful.co?subject=Mise%20à%20jour%20de%20forfait%20de%20nouvelle%20province";
export const COMPLEX_CASES_URL = "https://willful.co/learn/complex-cases";
export const BULK_PURCHASE_GIFTS_URL = "https://www.willful.co/gifts";
export const VOLUME_PRICING_URL = "https://www.willful.co/volume-pricing";
export const WILLFUL_FEATURES_URL = "https://willful.co/learn/willful-features";
export const WILLFUL_LEARN_CENTRE_EN_URL = "https://willful.co/learn";
export const WILLFUL_LEARN_CENTRE_FR_URL =
  "https://www.willful.co/fr-qc/centre-dapprentissage";
export const TOS_URL = "https://www.willful.co/terms-of-service";
export const PRIVACY_POLICY_URL = "https://www.willful.co/privacy-policy";

export const WILL_REGISTRY_URL = "https://www.canadawillregistry.org";

export const REFERRAL_PROGRAM_WILLFUL_ZENDESK_URL =
  "https://willful.zendesk.com/hc/en-us/sections/360007445734-Referral-Program";
export const REFERRAL_PROGRAM_AMAZON_GIFTCARD_URL =
  "https://www.amazon.ca/gc-legal";

export const ACCOUNT_DELETE_SURVERY_FR_URL =
  "https://willfulwills.typeform.com/to/VgTANXXE";
export const ACCOUNT_DELETE_SURVERY_EN_URL =
  "https://willfulwills.typeform.com/to/qWbguzAU";

export const BBB_TRACKING_SCRIPT_URL =
  "https://securepubads.g.doubleclick.net/tag/js/gpt.js";

export const BOOK_SUPPORT_CALL_EN_URL =
  "https://calendly.com/willful/phone-support-sessions";

export const BOOK_SUPPORT_CALL_FR_URL =
  "https://calendly.com/willful-support/demande-de-rappel-willful";

export const WILLFUL_SUPPORT_CENTRE_EN_URL = "https://www.willful.co/support";
export const WILLFUL_SUPPORT_CENTRE_FR_URL =
  "https://www.willful.co/fr-qc/support";
export const WILLFUL_BILL_21_URL =
  "https://willful.co/digital-wills-bc-bill-21";
export const BC_LAW_SOCIETY_INNOVATION_SANDBOX_URL =
  "https://lawsociety.bc.ca/our-initiatives/innovation-sandbox";

// Notarization-related URLS

export const CALENDLY_NOTARIZATION_BOOKING_EN_URL =
  "https://willfulwills.typeform.com/to/vI62whPd#rating=xxxxx";
export const CALENDLY_NOTARIZATION_BOOKING_FR_URL =
  "https://willfulwills.typeform.com/to/vI62whPd#rating=xxxxx";

export const NOTARIZATION_GUIDE_PDF_EN_URL =
  "https://uploads-ssl.webflow.com/5e9e13b793cb7451bcaea9df/65c0f3f79e1688e2b5485b8e_Willful%20Notarial%20Will%20Guide%20-%20January%202024%20-%20EN.pdf";
export const NOTARIZATION_GUIDE_PDF_FR_URL =
  "https://uploads-ssl.webflow.com/5e9e13b793cb7451bcaea9df/65c0f3fe595807266e8e58ca_Willful%20Notarial%20Will%20Guide%20-%20January%202024%20-%20FR.pdf";

// Email Preview URLs

// EN ROC
export const LEARN_DUTIES_EXECUTORS_EN =
  "https://www.willful.co/learn/duties-of-an-executor";
export const LEARN_DUTIES_PET_GUARDIANS_EN =
  "https://www.willful.co/learn/what-is-a-pet-guardian";
export const LEARN_DUTIES_GUARDIANS_EN =
  "https://www.willful.co/learn/being-named-as-a-guardian-in-a-will";
export const LEARN_DUTIES_PERSONAL_ATTORNEYS_EN =
  "https://www.willful.co/learn/power-of-attorney-healthcare-duties";
export const LEARN_DUTIES_PROPERTY_ATTORNEYS_EN =
  "https://www.willful.co/learn/power-of-attorney-property-duties";

// EN QC
export const LEARN_DUTIES_EXECUTORS_EN_QC =
  "https://www.willful.co/en-qc-learn/duties-of-a-liquidator";
export const LEARN_DUTIES_PET_GUARDIANS_EN_QC =
  "https://www.willful.co/en-qc-learn/what-is-a-pet-guardian-quebec";
export const LEARN_DUTIES_GUARDIANS_EN_QC =
  "https://www.willful.co/en-qc-learn/being-named-as-a-tutor-in-a-will";

// FR QC
export const LEARN_DUTIES_EXECUTORS_FR_QC =
  "https://www.willful.co/fr-qc-apprendre/taches-du-liquidateur-quebec";
export const LEARN_DUTIES_PET_GUARDIANS_FR_QC =
  "https://www.willful.co/fr-qc-apprendre/tuteurs-animaux-de-compagnie";
export const LEARN_DUTIES_GUARDIANS_FR_QC =
  "https://www.willful.co/fr-qc-apprendre/tache-de-tuteur-quebec";

export const EMAIL_PREVIEW_LEARN_URLS_BY_ROLE_EN_ROC = {
  executor: LEARN_DUTIES_EXECUTORS_EN,
  childrenGuardian: LEARN_DUTIES_GUARDIANS_EN,
  petGuardian: LEARN_DUTIES_PET_GUARDIANS_EN,
  personalAttorneys: LEARN_DUTIES_PERSONAL_ATTORNEYS_EN,
  propertyAttorneys: LEARN_DUTIES_PROPERTY_ATTORNEYS_EN,
  spouseExecutors: LEARN_DUTIES_EXECUTORS_EN,
  spousePersonalAttorneys: LEARN_DUTIES_PERSONAL_ATTORNEYS_EN,
  spousePropertyAttorneys: LEARN_DUTIES_PROPERTY_ATTORNEYS_EN,
};

export const EMAIL_PREVIEW_LEARN_URLS_BY_ROLE_EN_QC = {
  executor: LEARN_DUTIES_EXECUTORS_EN_QC,
  childrenGuardian: LEARN_DUTIES_GUARDIANS_EN_QC,
  petGuardian: LEARN_DUTIES_PET_GUARDIANS_EN_QC,
};

export const EMAIL_PREVIEW_LEARN_URLS_BY_ROLE_FR_QC = {
  executor: LEARN_DUTIES_EXECUTORS_FR_QC,
  childrenGuardian: LEARN_DUTIES_GUARDIANS_FR_QC,
  petGuardian: LEARN_DUTIES_PET_GUARDIANS_FR_QC,
};
