import {
  CHECKOUT_PATH,
  FAMILY_RECOMMENDED_PATH,
  PREMIUM_RECOMMENDED_PATH,
  WELCOME_PATH,
} from "./routes";

export const PAGES_WITH_STICKY_CTA = [
  CHECKOUT_PATH,
  WELCOME_PATH,
  PREMIUM_RECOMMENDED_PATH,
  FAMILY_RECOMMENDED_PATH,
];
