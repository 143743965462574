import React from "react";

import { reduxForm } from "redux-form";
import Box from "@material-ui/core/Box";
import { Body } from "../../ui/Typography";

import { PrimaryButton } from "../../ui/Button";

import { REMOVE_BENEFICIARY_FORM_ID } from "../../../constants/forms";

const RemoveBeneficiaryForm = ({ handleSubmit, isLoading, extraProps }) => {
  const { translations } = extraProps;
  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" flexDirection="column" justifyContent="center" pt={2}>
        <Body data-hj-suppress>{translations.paragraph1}</Body>
        <Body>{translations.paragraph2}</Body>
        <Box pt={1}>
          <PrimaryButton
            fullWidth
            isLoading={isLoading}
            text={translations.button.submit}
            destructive
          />
        </Box>
      </Box>
    </form>
  );
};

export default reduxForm({
  form: REMOVE_BENEFICIARY_FORM_ID,
})(RemoveBeneficiaryForm);
