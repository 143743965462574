import React from "react";
import { Field } from "redux-form";
import NumberFormat from "react-number-format";

import Box from "@material-ui/core/Box";

import { formatCurrency } from "../../../utilities/helpers";
import { SmallBody } from "../Typography";
import { Input } from "./TextInput";
import { ErrorMessage } from "../Error";

const AmountInput = ({
  input,
  meta: { touched, error },
  label,
  languageCode,
}) => {
  const { name, value, onChange } = input;
  const shouldDisplayError = error && touched;
  const hasValue = value !== "";
  const handleChange = ({ floatValue }) => onChange(floatValue);

  return (
    <Box display="flex" flexDirection="column" mb={2}>
      <label htmlFor={name}>
        <SmallBody>{label}</SmallBody>
      </label>
      <NumberFormat
        hasError={shouldDisplayError}
        hasValue={hasValue}
        id={name}
        customInput={Input}
        allowNegative={false}
        thousandSeparator
        isNumericString
        format={(inputValue) => formatCurrency(inputValue, languageCode)}
        decimalScale={2}
        value={value}
        onValueChange={handleChange}
      />
      {shouldDisplayError && (
        <Box mt={0.5}>
          <ErrorMessage error={error} />
        </Box>
      )}
    </Box>
  );
};

const AmountField = ({ fieldName, label, validate, languageCode }) => (
  <Field
    name={fieldName}
    label={label}
    validate={validate}
    languageCode={languageCode}
    component={AmountInput}
  />
);

export default AmountField;
