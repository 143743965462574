import React from "react";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import { media } from "../../config/Theme";

// Bottom padding is requested 160px (mobile 72px) + 110px for the sticky bottom nav
export const PageLayout = styled(Box)`
  padding: 32px 0;

  ${media.desktop} {
    padding: 120px 0;
  }
`;

export const PageLayoutWithPrevious = styled(Box)`
  padding: 24px 0;

  ${media.desktop} {
    padding: 72px 0;
  }
`;

export const PageLayoutWithMiniForm = styled(Box)`
  padding: 24px 0;

  ${media.desktop} {
    padding: 72px 0;
  }
`;
