import React from "react";
import { FieldArray, reduxForm } from "redux-form";
import Box from "@material-ui/core/Box";
import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import { AllocationsFieldArrayRenderer } from "../../ui/inputs/FieldArrayRenderer";
import {
  ALLOCATIONS_FORM_ID,
  ALLOCATIONS_FIELD_ID,
  ASYNC_BLUR_ALLOCATIONS_FIELD_NAME,
} from "../../../constants/forms";
import AllocationCard from "../../ui/AllocationCard";
import { FormButtons } from "../../ui/Form";
import AddBeneficiaryForm from "./AddBeneficiaryForm";
import { asyncValidateAllocations } from "../utils/allocations-form-validations";
import PieChart from "../../ui/pie-chart/PieChart";
import InfoBoxGroup from "../../ui/InfoBox";

const AllocationsForm = ({
  error,
  handleSubmit,
  distributeAllocationsEvenly,
  removeAllocation,
  translations,
  charities,
  change,
  isLoading,
  backLink,
  plansLink,
  modalKey,
  onSubmitModal,
  addBeneficiaryForm,
  displayModal,
  totalPercentage,
  updateAllocationCard,
  chartAllocations,
  hasAllocations,
  pieChartAnimationKey,
  isPrimaryBeneficiarySpouse,
}) => {
  const minWidth = 280;
  const maxWidth = 348;
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="row" justify="">
        <Grid
          item
          xl={hasAllocations ? 6 : 12}
          lg={hasAllocations ? 6 : 12}
          md={12}
          xs={12}
        >
          <Box>
            <FieldArray
              name={ALLOCATIONS_FIELD_ID}
              handleChange={change}
              charities={charities}
              component={AllocationsFieldArrayRenderer}
              RenderedComponent={AllocationCard}
              translations={translations}
              onRemove={removeAllocation}
              totalPercentage={totalPercentage}
              distributeAllocationsEvenly={distributeAllocationsEvenly}
              error={error}
              updateAllocationCard={updateAllocationCard}
              isPrimaryBeneficiarySpouse={isPrimaryBeneficiarySpouse}
            />

            <AddBeneficiaryForm
              displayModal={displayModal}
              plansLink={plansLink}
              modalKey={modalKey}
              onSubmit={() => onSubmitModal()}
              {...addBeneficiaryForm}
            />

            <Box mt={2}>
              <FormButtons
                isLoading={isLoading}
                backLink={backLink}
                translations={translations}
              />
            </Box>
          </Box>
        </Grid>
        {hasAllocations && (
          <>
            <Grid item xl={2} lg={2} md={12} xs={12} />
            <Grid item xl={4} lg={4} md={12} xs={12}>
              <Box mt={isDesktop ? 0 : 2}>
                <PieChart
                  key={`pie-chart-${pieChartAnimationKey}`}
                  chartData={chartAllocations}
                  useShortTitle={isDesktop}
                  minWidth={minWidth}
                  maxWidth={maxWidth}
                />
              </Box>
              <Box mt={isDesktop ? 4.5 : 3} minWidth={minWidth}>
                <InfoBoxGroup infoBox={translations.infoBox} />
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </form>
  );
};

export default reduxForm({
  form: ALLOCATIONS_FORM_ID,
  destroyOnUnmount: false, // required to preserve redux form state when adding beneficiary
  asyncValidate: asyncValidateAllocations,
  asyncBlurFields: [ASYNC_BLUR_ALLOCATIONS_FIELD_NAME],
})(AllocationsForm);
