export const formatCurrency = (amount, lang = "en", decimalPlaces = null) => {
  const locale = lang === "fr" ? "fr-CA" : "en-US";
  const decimalPlacesHardCoded = decimalPlaces !== null;

  const amountHasDecimalPlace = amount - Math.floor(amount) !== 0;
  const decimalPlacesDynamicallyChosen = amountHasDecimalPlace ? 2 : 0;
  const minimumFractionDigits = decimalPlacesHardCoded
    ? decimalPlaces
    : decimalPlacesDynamicallyChosen;

  const price = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: "CAD",
    minimumFractionDigits,
  }).format(amount);
  // remove "CA" from formatted price
  // currencyDisplay: "narrowSymbol" not support on safari
  // https://stackoverflow.com/questions/64144596/currencydisplay-must-be-either-code-symbol-or-name-safari-issue
  return price.replace("CA", "");
};

export const formatPercentage = (amount, decimalPlaces = 0, lang = "en") => {
  const locale = lang === "fr" ? "fr-CA" : "en-US";
  const percentage = new Intl.NumberFormat(locale, {
    style: "percent",
    minimumFractionDigits: decimalPlaces,
  }).format(amount);

  return percentage;
};

export const isTaplyticsReady = () =>
  window.Taplytics && window.Taplytics.isReady();

export const isHotjarReady = () => {
  if (typeof window.hj === "undefined") {
    console.warn("Hotjar is not ready, window.hj is undefined");
    return false;
  }
  return true;
};

export const isTikTokReady = () => {
  if (typeof window.ttq === "undefined") {
    return false;
  }
  return true;
};

export const replaceBundleSizeOptions = (
  translations,
  bundlePrices,
  selectLanguageCode,
  showExclusiveOffer,
) => {
  if (bundlePrices === undefined) {
    return [];
  }
  return translations.bundleSizeOptions.map((bundle, index) => {
    const label = bundle.label.replace(
      "DISCOUNTED_PRICE",
      formatCurrency(
        showExclusiveOffer
          ? bundlePrices[index].discounted_price
          : bundlePrices[index].price,
        selectLanguageCode,
      ),
    );
    const labelStrikeThrough = bundle.labelStrikeThrough.replace(
      "MARKETING_PRICE",
      formatCurrency(bundlePrices[index].marketing_price, selectLanguageCode),
    );
    const srOnlyCopy = bundle.srOnlyCopy.replace(
      "MARKETING_PRICE",
      formatCurrency(bundlePrices[index].marketing_price, selectLanguageCode),
    );

    return {
      ...bundle,
      label,
      labelStrikeThrough,
      srOnlyCopy,
    };
  });
};

export const getTranspiledMessage = (target, replacement, originalText) => {
  if (Array.isArray(originalText)) {
    return originalText.map((text) => text.replace(target, replacement));
  }
  return originalText.replace(target, replacement);
};
