import R from "ramda";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";
import { useDispatch, useSelector } from "react-redux";

import {
  SectionTitle,
  H4,
  H3,
  SmallBody,
  SmallExternalLink,
  MiceType,
} from "../ui/Typography";
import { SecondaryButton, ButtonLink, PrimaryButton } from "../ui/Button";
import SelectInput from "../ui/inputs/DropdownSelect/SelectInput";
import StatusLabel from "../ui/StatusLabel";

import { productConstants } from "./PlanConstants";

import { PLANS_PATH, DASHBOARD_PATH } from "../../constants/routes";
import { transformBundleOptionList } from "../ui/utils/BundleOptionList";
import CheckmarkLight from "../ui/icons/CheckmarkLight";
import { bundlePlan, essentialsPlan, premiumPlan } from "../../constants/plans";
import LoadingSpinner from "../ui/LoadingSpinner";
import { getPageTranslations } from "../../i18n/translations";
import { useStickyBottomBoxStyle } from "../ui/utils/sticky-bottom-box";
import BodyWithUnderlineLink from "../ui/BodyWithUnderlineLink";
import { displayTooltipModal } from "../../actions/tooltip-modal";
import KlarnaIcon from "../ui/icons/KlarnaIcon";
import {
  selectPayInInstallmentsText,
  selectShoudlShowPayInInstallments,
} from "../../selectors/plans";

const TopBox = styled(Box)`
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-top: ${(props) => (props.selected ? "2px" : "1px")} solid
    ${(props) => (props.selected ? "#0064DC" : "#cacdd4")};
  border-right: ${(props) => (props.selected ? "2px" : "1px")} solid
    ${(props) => (props.selected ? "#0064DC" : "#cacdd4")};
  border-left: ${(props) => (props.selected ? "2px" : "1px")} solid
    ${(props) => (props.selected ? "#0064DC" : "#cacdd4")};
  margin: 0 auto;
`;

const YellowBox = styled(Box)`
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

const BottomBox = styled(Box)`
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom: ${(props) =>
      props.selected && !props.hideBorderBottomHighlight ? "2px" : "1px"}
    solid
    ${(props) =>
      props.selected && !props.hideBorderBottomHighlight
        ? "#0064DC"
        : "#cacdd4"};
  border-right: ${(props) => (props.selected ? "2px" : "1px")} solid
    ${(props) => (props.selected ? "#0064DC" : "#cacdd4")};
  border-left: ${(props) => (props.selected ? "2px" : "1px")} solid
    ${(props) => (props.selected ? "#0064DC" : "#cacdd4")};
`;

const PayInInstallmentsText = ({ hidden, price }) => {
  const installmentTranslation = useSelector(
    selectPayInInstallmentsText(price),
  );
  if (hidden) return null;

  return (
    <MiceType
      color="primary"
      display="flex"
      margin="0"
      style={{
        height: 0,
        marginTop: "16px",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      {installmentTranslation}
      <KlarnaIcon marginLeft={0.5} />
    </MiceType>
  );
};

const PlanCardCta = ({
  viewOnly,
  activeSymbol,
  ctaText,
  handleSubmit,
  isLoading,
  loadingLabel,
  isUpgrade,
  isPlanSelected,
  isFamilyUpgradeAndQuantityChanged,
  isRecommended = false,
}) => {
  if (viewOnly) {
    return (
      <ButtonLink
        fullWidth
        to={PLANS_PATH}
        displayArrowRight
        className={`qa-submit vwo-select-plan ${activeSymbol}`}
        text={ctaText}
      />
    );
  }

  if (isUpgrade && isPlanSelected && !isFamilyUpgradeAndQuantityChanged) {
    return (
      <ButtonLink
        fullWidth
        secondary
        to={DASHBOARD_PATH}
        displayArrowRight
        className={`qa-submit vwo-select-plan ${activeSymbol}`}
        text={ctaText}
      />
    );
  }

  if (isRecommended) {
    return (
      <PrimaryButton
        fullWidth
        displayArrowRight
        className={`qa-submit vwo-select-plan ${activeSymbol}`}
        isLoading={isLoading}
        loadingLabel={loadingLabel}
        onClick={handleSubmit}
        text={ctaText}
        type="button"
      />
    );
  }

  return (
    <SecondaryButton
      fullWidth
      displayArrowRight
      className={`qa-submit vwo-select-plan ${activeSymbol}`}
      isLoading={isLoading}
      loadingLabel={loadingLabel}
      onClick={handleSubmit}
      text={ctaText}
      type="button"
    />
  );
};

const StrikeThroughBody = styled(SmallBody)`
  text-decoration: line-through;
`;

const PlanCardTitleAndPricing = ({
  showExclusiveOffer,
  planTranslations,
  translations,
  strikeThroughPrice,
  price,
  isBundle,
  viewOnly,
  quantityError,
  handleOnChange,
  textColor,
  bundleOptions,
  isMobile,
}) => {
  const showPayInInstallments = useSelector(selectShoudlShowPayInInstallments);
  const payInInstallmentsPadding = showPayInInstallments ? 0 : 2;
  const defaultPaddingBottom = isMobile ? 0.5 : payInInstallmentsPadding;

  if (isBundle) {
    return (
      <>
        <Box>
          {showExclusiveOffer && (
            <Box
              display="flex"
              width="100%"
              justifyContent="space-between"
              alignItems="center"
              paddingBottom={0.5}
            >
              <StatusLabel
                status="discountBlue"
                label={planTranslations.exclusiveOffer}
              />
              <Box>
                <StrikeThroughBody component="span" align="right">
                  {strikeThroughPrice}
                </StrikeThroughBody>
              </Box>
            </Box>
          )}
          <Box
            display="flex"
            width="100%"
            justifyContent="space-between"
            alignItems="center"
            paddingBottom={showExclusiveOffer ? 0 : 0.5}
          >
            <H4 align="left" data-testid="plan-name">
              {planTranslations.planName}
            </H4>
            <Box display="flex" flexDirection="column">
              <H3 marginBottom="0" align="right" data-testid="plan-price">
                {price}
              </H3>
            </Box>
          </Box>
          {!showExclusiveOffer && (
            <Box
              display="flex"
              width="100%"
              justifyContent="right"
              pb={isBundle ? 0 : 1}
            >
              {isBundle && (
                <StrikeThroughBody component="p" align="right">
                  {planTranslations.planSavingsSlashout}
                </StrikeThroughBody>
              )}
            </Box>
          )}
        </Box>
        <PayInInstallmentsText hidden={!showPayInInstallments} price={price} />
        {!viewOnly && (
          <Box mt={2} mb={1} display="none">
            <SelectInput
              value={2}
              error={quantityError}
              onChange={handleOnChange}
              label={translations.bundlePlanLabel}
              labelColor={textColor}
              optionList={bundleOptions}
              placeholder={translations.bundlePlanPlaceholder}
              marginBottom={0}
            />
          </Box>
        )}
      </>
    );
  }

  return (
    <>
      <Box paddingBottom={showExclusiveOffer ? 0 : defaultPaddingBottom}>
        {showExclusiveOffer && (
          <Box
            display="flex"
            width="100%"
            justifyContent="space-between"
            alignItems="center"
            paddingBottom={0.5}
          >
            <StatusLabel
              status="discountBlue"
              label={planTranslations.exclusiveOffer}
            />
            <Box>
              <StrikeThroughBody component="span" align="right">
                {strikeThroughPrice}
              </StrikeThroughBody>
            </Box>
          </Box>
        )}
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          <H4 align="left" data-testid="plan-name">
            {planTranslations.planName}
          </H4>
          <Box display="flex" flexDirection="column">
            <H3 marginBottom="0" align="right" data-testid="plan-price">
              {price}
            </H3>
          </Box>
        </Box>
        <PayInInstallmentsText hidden={!showPayInInstallments} price={price} />
      </Box>
    </>
  );
};

const PlanCardTitle = ({
  showExclusiveOffer,
  planTranslations,
  strikeThroughPrice,
}) => {
  return (
    <>
      <Box>
        {showExclusiveOffer && (
          <Box
            display="flex"
            width="100%"
            justifyContent="space-between"
            alignItems="center"
            paddingBottom={0.5}
          >
            <StatusLabel
              status="discountBlue"
              label={planTranslations.exclusiveOffer}
            />
            <Box>
              <StrikeThroughBody component="span" align="right">
                {strikeThroughPrice}
              </StrikeThroughBody>
            </Box>
          </Box>
        )}
        <Box display="flex" width="100%" justifyContent="space-between">
          <H4 align="left" data-testid="plan-name">
            {planTranslations.planName}
          </H4>
        </Box>
      </Box>
    </>
  );
};

const PlanCard = ({
  onClick,
  viewOnly,
  defaultQuantity,
  isPlanSelected,
  isLoading,
  isBundle = false,
  isUpgrade = false,
  bundlePrices,
  symbol,
  noCta,
  analyticsClickExternalLink = () => {},
  price,
  upgradePrice,
  translations,
  isQuebecSelected,
  strikeThroughPrice,
  showExclusiveOffer = false,
  recommendedForYou,
  noMaxWidth = false,
  showInfoOnly = false,
  showMobileStickyCta = false,
}) => {
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(defaultQuantity);
  const [quantityError, setQuantityError] = useState("");
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const stickyButtonStyle = useStickyBottomBoxStyle();

  const getIsFamilyUpgradeAndQuantityChanged = () => {
    if (isUpgrade && isBundle && isPlanSelected) {
      return defaultQuantity !== quantity;
    }
    return false;
  };
  const isFamilyUpgradeAndQuantityChanged = getIsFamilyUpgradeAndQuantityChanged();

  const handleSubmit = () => {
    if (isBundle && !isUpgrade && quantity <= 1) {
      setQuantityError(translations.quantityRequired);
    } else {
      onClick(quantity);
    }
  };

  const handleOnChange = (value) => {
    setQuantityError("");
    setQuantity(value);
  };

  // during certain route transitions from a page that has a plan card on it
  // to another one, plan cards are mounted without data.
  // this causes our `const planTranslations = translations[activeSymbol];`
  // line to break many things dependent on it like `planTranslations.details.map`
  if (!symbol) {
    return null;
  }
  const activeSymbol = isBundle ? "bundle" : symbol;
  const isEssentialsPlan = symbol === "essentials";
  const isNotarialWill = symbol === "notarial";

  const planTranslations = translations[activeSymbol];
  const textColor = R.path([activeSymbol, "textColor"])(productConstants);

  const bundleOptions = transformBundleOptionList(
    translations.bundleSizeOptions,
  );

  const getPrimaryButtonText = () => {
    if (isUpgrade) {
      let ctaUpgradePrice = upgradePrice;
      if (isPlanSelected && !isFamilyUpgradeAndQuantityChanged) {
        return translations.button.currentPlan;
      }
      if (isBundle) {
        const bundleQuantityPrice =
          R.find(R.propEq("quantity", quantity))(bundlePrices) || {};
        ctaUpgradePrice = bundleQuantityPrice.upgradePrice;
      }
      return translations.button.upgradeFor.replace("PRICE", ctaUpgradePrice);
    }
    if (isPlanSelected) {
      return translations.button.selected;
    }
    if (viewOnly) {
      return translations.button.viewPlans;
    }
    return translations.button.unselected;
  };

  const tabletMobileMarginTop = isTablet ? 0 : 2.5;
  const detailsCopy = planTranslations.detailsDeprecated;

  const planCardCta = (
    <PlanCardCta
      viewOnly={viewOnly}
      activeSymbol={activeSymbol}
      ctaText={getPrimaryButtonText()}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      isUpgrade={isUpgrade}
      isPlanSelected={isPlanSelected}
      isFamilyUpgradeAndQuantityChanged={isFamilyUpgradeAndQuantityChanged}
      loadingLabel={translations.button.loadingLabel}
      isRecommended={recommendedForYou}
    />
  );

  const shouldDispalyStickyButton = isMobile && showMobileStickyCta;
  const planCardCtaForScreenResolution = shouldDispalyStickyButton ? (
    <Box mt={0} className={stickyButtonStyle.stickyBottomBox} width="100%">
      {planCardCta}
    </Box>
  ) : (
    <Box paddingTop={2}>{planCardCta}</Box>
  );

  return (
    <Box>
      <Box mb={2} mt={!recommendedForYou ? tabletMobileMarginTop : 0}>
        {recommendedForYou && (
          <YellowBox
            bgcolor="yellow.main"
            borderColor="yellow.main"
            display="flex"
            border={0}
            justifyContent="center"
            py={0.5}
          >
            <SectionTitle>{planTranslations.recommendedForYou}</SectionTitle>
          </YellowBox>
        )}
        <TopBox
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          bgcolor="willfulWhite.main"
          selected={isPlanSelected && !isFamilyUpgradeAndQuantityChanged}
          p={isTablet ? 1.5 : 2}
          paddingBottom={0}
        >
          {showInfoOnly ? (
            <PlanCardTitle
              showExclusiveOffer={showExclusiveOffer}
              planTranslations={planTranslations}
              translations={translations}
              strikeThroughPrice={strikeThroughPrice}
              price={price}
              isBundle={isBundle}
              viewOnly={viewOnly}
              quantityError={quantityError}
              handleOnChange={handleOnChange}
              textColor={textColor}
              bundleOptions={bundleOptions}
            />
          ) : (
            <>
              <PlanCardTitleAndPricing
                showExclusiveOffer={showExclusiveOffer}
                planTranslations={planTranslations}
                translations={translations}
                strikeThroughPrice={strikeThroughPrice}
                price={price}
                isBundle={isBundle}
                viewOnly={viewOnly}
                quantityError={quantityError}
                handleOnChange={handleOnChange}
                textColor={textColor}
                bundleOptions={bundleOptions}
                isMobile={isMobile}
              />
              {!noCta && planCardCtaForScreenResolution}
            </>
          )}
        </TopBox>
        <BottomBox
          display="flex"
          selected={isPlanSelected && !isFamilyUpgradeAndQuantityChanged}
          flexDirection="column"
          bgcolor="willfulWhite.main"
          p={2}
        >
          {detailsCopy.map((planDetail) => {
            const { underlines } = planTranslations;
            const underline =
              underlines?.find((e) => e.text === planDetail) || {};
            const { text, underlineText, modalKey } = underline;

            return (
              <Box
                maxWidth={noMaxWidth ? "100%" : "330px"}
                mb={0.5}
                key={`${planDetail}`}
              >
                <Box component="span" display="flex">
                  <Box mt="2px">
                    <CheckmarkLight width={20} height={20} />
                  </Box>
                  <Box ml={0.5}>
                    {underlineText ? (
                      <SmallBody key={planDetail} align="left">
                        <BodyWithUnderlineLink
                          text={text}
                          underlineText={underlineText}
                          onClick={() =>
                            dispatch(displayTooltipModal(modalKey))
                          }
                        />
                      </SmallBody>
                    ) : (
                      <SmallBody
                        key={planDetail}
                        align="left"
                        dangerouslySetInnerHTML={{ __html: planDetail }}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
            );
          })}
        </BottomBox>
        {isPlanSelected && !isFamilyUpgradeAndQuantityChanged && (
          <Box mt={1} display="flex" justifyContent="center">
            <SmallBody color="willfulBlue">
              {translations.selectedPlanLabel}
            </SmallBody>
          </Box>
        )}

        {isEssentialsPlan && isQuebecSelected && (
          <Box mt={1} display="flex" textAlign="center" justifyContent="center">
            <SmallExternalLink
              onClick={() =>
                analyticsClickExternalLink(translations.learnMoreWitnessesLink)
              }
              text={translations.learnMoreWitnesses}
              href={translations.learnMoreWitnessesLink}
            />
          </Box>
        )}

        {isNotarialWill && (
          <Box mt={1} display="flex" justifyContent="center">
            <SmallExternalLink
              onClick={() =>
                analyticsClickExternalLink(translations.learnMoreNotarialLink)
              }
              text={translations.learnMoreNotarial}
              href={translations.learnMoreNotarialLink}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

const LegalEssentialsPlanCard = ({
  onClick,
  viewOnly,
  defaultQuantity,
  isPlanSelected,
  isLoading,
  isBundle = false,
  isUpgrade = false,
  bundlePrices,
  symbol = essentialsPlan.symbol,
  noCta,
  analyticsClickExternalLink = () => {},
  price,
  upgradePrice,
  isQuebecSelected,
  strikeThroughPrice,
  showExclusiveOffer = false,
  recommendedForYou,
  noMaxWidth = false,
  showInfoOnly = false,
  pageKey = "plansCards",
  showMobileStickyCta,
}) => {
  const [translations, setTranslations] = useState(null);
  const langKey = useSelector((state) => state.aboutYou.lang);
  useEffect(() => {
    setTranslations(getPageTranslations(langKey, pageKey));
  }, [langKey, pageKey]);

  if (!translations) {
    return <LoadingSpinner />;
  }

  return (
    <PlanCard
      onClick={onClick}
      viewOnly={viewOnly}
      defaultQuantity={defaultQuantity}
      isPlanSelected={isPlanSelected}
      isLoading={isLoading}
      isBundle={isBundle}
      isUpgrade={isUpgrade}
      bundlePrices={bundlePrices}
      symbol={symbol}
      noCta={noCta}
      analyticsClickExternalLink={analyticsClickExternalLink}
      price={price}
      upgradePrice={upgradePrice}
      translations={translations}
      isQuebecSelected={isQuebecSelected}
      strikeThroughPrice={strikeThroughPrice}
      showExclusiveOffer={showExclusiveOffer}
      recommendedForYou={recommendedForYou}
      noMaxWidth={noMaxWidth}
      showInfoOnly={showInfoOnly}
      showMobileStickyCta={showMobileStickyCta}
    />
  );
};

const PremiumPlanCard = ({
  onClick,
  viewOnly,
  defaultQuantity,
  isPlanSelected,
  isLoading,
  isBundle = false,
  isUpgrade = false,
  bundlePrices,
  symbol = premiumPlan.symbol,
  noCta,
  analyticsClickExternalLink = () => {},
  price,
  upgradePrice,
  isQuebecSelected,
  strikeThroughPrice,
  showExclusiveOffer = false,
  recommendedForYou,
  noMaxWidth = false,
  showInfoOnly = false,
  pageKey = "plansCards",
  showMobileStickyCta,
}) => {
  const [translations, setTranslations] = useState(null);
  const langKey = useSelector((state) => state.aboutYou.lang);
  useEffect(() => {
    setTranslations(getPageTranslations(langKey, pageKey));
  }, [langKey, pageKey]);

  if (!translations) {
    return <LoadingSpinner />;
  }

  return (
    <PlanCard
      onClick={onClick}
      viewOnly={viewOnly}
      defaultQuantity={defaultQuantity}
      isPlanSelected={isPlanSelected}
      isLoading={isLoading}
      isBundle={isBundle}
      isUpgrade={isUpgrade}
      bundlePrices={bundlePrices}
      symbol={symbol}
      noCta={noCta}
      analyticsClickExternalLink={analyticsClickExternalLink}
      price={price}
      upgradePrice={upgradePrice}
      translations={translations}
      isQuebecSelected={isQuebecSelected}
      strikeThroughPrice={strikeThroughPrice}
      showExclusiveOffer={showExclusiveOffer}
      recommendedForYou={recommendedForYou}
      noMaxWidth={noMaxWidth}
      showInfoOnly={showInfoOnly}
      showMobileStickyCta={showMobileStickyCta}
    />
  );
};

const FamilyBundlePlanCard = ({
  onClick,
  viewOnly,
  defaultQuantity = 2,
  isPlanSelected,
  isLoading,
  isUpgrade = false,
  bundlePrices,
  symbol = bundlePlan.symbol,
  noCta,
  analyticsClickExternalLink = () => {},
  price,
  upgradePrice,
  isQuebecSelected,
  strikeThroughPrice,
  showExclusiveOffer = false,
  recommendedForYou,
  noMaxWidth = false,
  showInfoOnly = false,
  pageKey = "plansCards",
  showMobileStickyCta,
}) => {
  const [translations, setTranslations] = useState(null);
  const langKey = useSelector((state) => state.aboutYou.lang);
  useEffect(() => {
    setTranslations(getPageTranslations(langKey, pageKey));
  }, [langKey, pageKey]);

  if (!translations) {
    return <LoadingSpinner />;
  }

  return (
    <PlanCard
      onClick={onClick}
      viewOnly={viewOnly}
      defaultQuantity={defaultQuantity}
      isPlanSelected={isPlanSelected}
      isLoading={isLoading}
      isUpgrade={isUpgrade}
      isBundle
      bundlePrices={bundlePrices}
      symbol={symbol}
      noCta={noCta}
      analyticsClickExternalLink={analyticsClickExternalLink}
      price={price}
      upgradePrice={upgradePrice}
      translations={translations}
      isQuebecSelected={isQuebecSelected}
      strikeThroughPrice={strikeThroughPrice}
      showExclusiveOffer={showExclusiveOffer}
      recommendedForYou={recommendedForYou}
      noMaxWidth={noMaxWidth}
      showInfoOnly={showInfoOnly}
      showMobileStickyCta={showMobileStickyCta}
    />
  );
};

export default PlanCard;
export { LegalEssentialsPlanCard, PremiumPlanCard, FamilyBundlePlanCard };
