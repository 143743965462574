import React, { useEffect } from "react";
import styled from "styled-components";
import Box from "@material-ui/core/Box";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme, withTheme } from "@material-ui/styles";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { Hidden } from "@material-ui/core";
import { PageLayout } from "../../components/ui/PageLayout";
import { PageWrapper } from "../Layout";
import {
  Body,
  H2,
  H4,
  SmallBody,
  SmallExternalLink,
} from "../../components/ui/Typography";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import { displayTooltipModal } from "../../actions/tooltip-modal";
import {
  PreviousLink,
  PrimaryButton,
  TextButton,
} from "../../components/ui/Button";
import { fetchDashboard } from "../../actions/dashboard";
import { importantNoteProps } from "../../selectors/important-note";
import { displayChat } from "../../actions/customer-service";
import {
  analyticsClickExternalLink,
  analyticsOpenedChat,
} from "../../actions/analytics";
import {
  WILLFUL_LEARN_CENTRE_EN_URL,
  WILLFUL_LEARN_CENTRE_FR_URL,
  WILLFUL_SUPPORT_CENTRE_EN_URL,
  WILLFUL_SUPPORT_CENTRE_FR_URL,
} from "../../constants/routes";
import ImportantNotePageIcon from "../../components/ui/icons/ImportantNotePageIcon";
import EducationIcon from "../../components/ui/icons/EducationIcon";
import { TOOLTIP_MODAL_IMPORTANT_NOTE } from "../../constants/modal";
import { updateAgreedToTos } from "../../actions/user";

const BulletList = styled.ul`
  padding-left: 20px;
  margin-bottom: 0;
`;

const EducationalContentCard = ({
  translations,
  isFrenchSelected,
  analyticsClickExternalLink,
}) => {
  const learnCentreUrl = isFrenchSelected
    ? WILLFUL_LEARN_CENTRE_FR_URL
    : WILLFUL_LEARN_CENTRE_EN_URL;
  const supportCentreUrl = isFrenchSelected
    ? WILLFUL_SUPPORT_CENTRE_FR_URL
    : WILLFUL_SUPPORT_CENTRE_EN_URL;

  return (
    <Box
      bgcolor="text.white"
      border={1}
      borderColor="border.main"
      px={1.5}
      py={1.5}
      borderRadius="4px"
    >
      <Box mb={1}>
        <Box ml={1} style={{ float: "right" }}>
          <EducationIcon />
        </Box>
        <Box mb={0.5}>
          <H4 align="left">{translations.browseEducationalContent}</H4>
        </Box>
        <SmallBody>{translations.educationalContentDescription}</SmallBody>
      </Box>
      <Box display="flex">
        <Box mr={1.5}>
          <SmallExternalLink
            href={learnCentreUrl}
            text={translations.learnCentre}
            onClick={() => analyticsClickExternalLink(learnCentreUrl)}
          />
        </Box>
        <SmallExternalLink
          href={supportCentreUrl}
          text={translations.supportCentre}
          onClick={() => analyticsClickExternalLink(supportCentreUrl)}
        />
      </Box>
    </Box>
  );
};

const ImportantNoteBulletList = ({ translations, displayTooltipModal }) => {
  const arraySize = translations.bullets.length - 1;
  const bulletPoints = translations.bullets.map((bullet, i) => {
    const isLastBullet = arraySize === i;
    return (
      <li key={`bullet-list-${i + 1}`}>
        <Box mb={0.5}>
          <Body align="left" component="span" bold={false}>
            {bullet.text}
            {isLastBullet && (
              <TextButton
                onClick={() => {
                  displayTooltipModal(TOOLTIP_MODAL_IMPORTANT_NOTE);
                }}
                text={bullet.moreExamplesText}
              />
            )}
          </Body>
        </Box>
      </li>
    );
  });
  return bulletPoints;
};

const TOSBody = ({ translations }) => {
  const { tos } = translations;
  return (
    <Body>
      {tos.startText}
      <TextButton
        onClick={() => window.open(tos.termsOfServiceLink, "_blank")}
        text={tos.termsOfServiceText}
      />
      {tos.connector}
      <TextButton
        onClick={() => window.open(tos.privacyPolicyLink, "_blank")}
        text={tos.privacyPolicyText}
      />
      {tos.endText}
    </Body>
  );
};

const ImportantNotePage = ({
  translations,
  hasLoaded,
  fetchDashboard,
  displayTooltipModal,
  isFrenchSelected,
  analyticsClickExternalLink,
  continueLink,
  updateAgreedToTos,
  previousLink,
}) => {
  useEffect(() => {
    if (!hasLoaded) {
      fetchDashboard();
    }
  }, [fetchDashboard, hasLoaded]);

  const theme = useTheme();
  const isLargeDesktop = useMediaQuery(theme.breakpoints.only("xl"));
  const isDesktop = useMediaQuery(theme.breakpoints.only("lg"));

  if (!hasLoaded) {
    return (
      <Box flexGrow="1">
        <LoadingSpinner />
      </Box>
    );
  }

  return (
    <Box
      width="100%"
      height="100%"
      bgcolor="background.main"
      flex="1"
      display="flex"
      flexDirection="column"
      alignItems="center"
      flexGrow={1}
    >
      <PageWrapper flexGrow={1}>
        <PageLayout>
          <Box mb={1}>
            <PreviousLink
              className="qa-back"
              to={previousLink}
              text={translations.previousButton}
              displayArrowLeft
            />
          </Box>
          <Grid container>
            <Grid item xl={1} lg={0} md={1} />
            <Grid item xl={10} lg={12} md={10} xs={12}>
              <Grid item container xl={12} lg={12} md={12} xs={12}>
                <Grid item xl={6} lg={6} md={12} xs={12}>
                  <Box mb={isLargeDesktop || isDesktop ? 4.5 : 3}>
                    <Box mb={1}>
                      <H2 align="left" marginBottom="0">
                        {translations.title}
                      </H2>
                    </Box>
                    <Box>
                      <BulletList>
                        <ImportantNoteBulletList
                          translations={translations}
                          displayTooltipModal={displayTooltipModal}
                        />
                      </BulletList>
                    </Box>
                    <Box mt={2.5}>
                      <PrimaryButton
                        displayArrowRight
                        className="qa-continue"
                        text={translations.continueButton}
                        onClick={() => updateAgreedToTos(continueLink)}
                      />
                    </Box>
                    <Box mt={1}>
                      <TOSBody translations={translations} />
                    </Box>
                    <Box mt={2}>
                      <SmallBody align="left">
                        {translations.bottomText}
                      </SmallBody>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xl={2} lg={2} md={0} />
                <Grid item xl={4} lg={4} sm={12}>
                  <Hidden mdDown>
                    <Box align="right" mb={2}>
                      <ImportantNotePageIcon isDesktop />
                    </Box>
                  </Hidden>
                  <EducationalContentCard
                    translations={translations}
                    isFrenchSelected={isFrenchSelected}
                    analyticsClickExternalLink={analyticsClickExternalLink}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xl={1} lg={0} md={1} />
          </Grid>
        </PageLayout>
      </PageWrapper>
    </Box>
  );
};

export default connect(importantNoteProps, {
  fetchDashboard,
  displayTooltipModal,
  displayChat,
  analyticsClickExternalLink,
  analyticsOpenedChat,
  updateAgreedToTos,
})(withTheme(ImportantNotePage));
