import R from "ramda";

import { createStructuredSelector, createSelector } from "reselect";
import { selectHasLoadedDataById, selectIsLoadingById } from "./requests";
import { selectTranslations } from "./translations";
import { selectQuestions } from "./faq";
import {
  selectIsCurrentProvinceSelectedByCode,
  selectIsEssentialsOnlyProvinceSelected,
  selectProvinceCode,
} from "./provinces";
import { selectLanguageCode, selectIsFrenchSelected } from "./language";
import { PRODUCTS_FORM_ID } from "../constants/forms";
import {
  PREMIUM_PLAN,
  COUPLES_PLAN,
  ESSENTIALS_PLAN,
  NOTARIAL_PLAN,
  bundlePlan,
} from "../constants/plans";
import {
  FAMILY_RECOMMENDED_PATH,
  PLANS_PATH,
  PREMIUM_RECOMMENDED_PATH,
} from "../constants/routes";
import { selectHasPartnerDiscount } from "./partner-discount";
import { formatCurrency, replaceBundleSizeOptions } from "../utilities/helpers";
import { selectCurrentRoute } from "./location";

export const selectActivePlan = R.pathOr({}, ["plans", "activePlan"]);
export const selectHasUserPaid = R.pathOr(false, ["plans", "hasPaid"]);

const selectAllPlans = R.pathOr([], ["plans", "availablePlans"]);

// This is only be for paid users
export const selectIsUpgradableUser = R.pathOr(false, [
  "plans",
  "hasUpgradablePlan",
]);

export const selectIsUserUpgrading = createSelector(
  selectHasUserPaid,
  selectIsUpgradableUser,
  (hasPaid, isUpgradeable) => hasPaid && isUpgradeable,
);

const selectPeopleQuantityIntent = R.pathOr(undefined, [
  "aboutYou",
  "peopleQuantityIntent",
]);

export const selectActivePlanSymbol = createSelector(
  selectActivePlan,
  R.propOr("", "symbol"),
);

// this selector defaults to 1
// assumed that this is only used for default of unpaid users
// this should not be exported or used outside of that context
const selectActivePlanQuantity = createSelector(
  selectActivePlan,
  R.propOr(1, "quantity"),
);

export const selectAvailableProducts = createSelector(
  selectAllPlans,
  R.filter((plan) => plan.symbol !== COUPLES_PLAN),
);

export const selectUpgradedPlan = createSelector(
  selectProvinceCode,
  (currentProvinceCode) => {
    if (currentProvinceCode === "QC") {
      return "notarial";
    }
    return "premium";
  },
);

export const selectPlanQuantity = R.pathOr("", [
  "plans",
  "activePlan",
  "quantity",
]);

export const selectIsActivePlanPremium = createSelector(
  selectActivePlanSymbol,
  (planSymbol) => planSymbol === PREMIUM_PLAN,
);

export const selectIsActivePlanBundle = createSelector(
  selectActivePlanSymbol,
  selectPlanQuantity,
  (plan, quantity) => plan === PREMIUM_PLAN && quantity > 1,
);

export const selectIsCouplesPlan = createSelector(
  selectActivePlanSymbol,
  (plan) => plan === COUPLES_PLAN,
);

export const selectIsWillOnly = createSelector(selectActivePlanSymbol, (plan) =>
  [ESSENTIALS_PLAN, NOTARIAL_PLAN].includes(plan),
);

export const selectHasActivePlan = createSelector(
  selectActivePlanSymbol,
  (activePlan) => !!activePlan,
);

export const selectPlanName = createSelector(
  selectHasActivePlan,
  selectActivePlanSymbol,
  selectPlanQuantity,
  (hasActivePlan, planSymbol, planQty) => {
    const planName = `${planSymbol}${planQty > 1 ? `x${planQty}` : ""}`;
    return hasActivePlan ? planName : null;
  },
);

export const selectLoadingPlan = R.pathOr(null, ["formUi", "loadingPlan"]);

export const selectIsLoadingPlanBundle = createSelector(
  selectLoadingPlan,
  (loadingPlan) => R.propOr(false, "isBundle")(loadingPlan),
);

export const selectNotarizationStatus = R.pathOr({}, [
  "plans",
  "notarizationStatus",
]);

export const selectIsNotarialWill = createSelector(
  selectActivePlanSymbol,
  (activePlanSymbol) => activePlanSymbol === "notarial",
);

export const selectShouldShowOverviewLink = createSelector(
  selectPeopleQuantityIntent,
  selectIsEssentialsOnlyProvinceSelected,
  selectHasActivePlan,
  (peopleQuantityIntent, isEssentialsOnlyProvinceSelected, hasSelectedPlan) =>
    peopleQuantityIntent &&
    !isEssentialsOnlyProvinceSelected &&
    !hasSelectedPlan,
);

export const selectOverviewLinkPath = createSelector(
  selectPeopleQuantityIntent,
  (peopleQuantityIntent) => {
    let link = "";

    switch (peopleQuantityIntent) {
      case "one_person":
        link = PREMIUM_RECOMMENDED_PATH;
        break;
      case "two_people":
      case "multi_people":
        link = FAMILY_RECOMMENDED_PATH;
        break;
      default:
        break;
    }

    return link;
  },
);

export const selectPlansPercentComplete = createSelector(
  selectHasActivePlan,
  (hasActivePlan) => (hasActivePlan ? 100 : 0),
);

export const selectPlansTotalPages = () => 1;

export const selectPlansCurrentPageNumber = createSelector(
  selectHasActivePlan,
  (hasActivePlan) => (hasActivePlan ? 1 : 0),
);

export const selectPlansStatus = createStructuredSelector({
  sectionKey: () => "plans",
  isComplete: selectHasActivePlan,
  progress: selectPlansPercentComplete,
  translations: selectTranslations(["plans"]),
});

export const selectShowExclusiveOffer = createSelector(
  selectHasPartnerDiscount,
  selectHasUserPaid,
  (hasPartnerDiscount, hasUserPaid) => {
    return hasPartnerDiscount && !hasUserPaid;
  },
);

export const selectEssentialsPrice = createSelector(
  selectAvailableProducts,
  selectShowExclusiveOffer,
  selectLanguageCode,
  (availablePlans, showExclusiveOffer, languageCode) => {
    const plans = R.filter((plan) => plan.symbol === "essentials")(
      availablePlans,
    );
    const price = plans.map((plan) =>
      showExclusiveOffer ? plan.discountedPrice : plan.price,
    )[0];
    return formatCurrency(price, languageCode);
  },
);

export const selectEssentialsStrikeThroughPrice = createSelector(
  selectAvailableProducts,
  selectLanguageCode,
  (availablePlans, languageCode) => {
    const plans = R.filter((plan) => plan.symbol === "essentials")(
      availablePlans,
    );
    const strikeThroughPrice = plans.map((plan) => plan.price)[0];
    return formatCurrency(strikeThroughPrice, languageCode);
  },
);

export const selectPremiumPrice = createSelector(
  selectAvailableProducts,
  selectShowExclusiveOffer,
  selectLanguageCode,
  (availablePlans, showExclusiveOffer, languageCode) => {
    const plans = R.filter((plan) => plan.symbol === "premium")(availablePlans);
    const price = plans.map((plan) =>
      showExclusiveOffer ? plan.discountedPrice : plan.price,
    )[0];
    return formatCurrency(price, languageCode);
  },
);

export const selectPremiumStrikeThroughPrice = createSelector(
  selectAvailableProducts,
  selectLanguageCode,
  (availablePlans, languageCode) => {
    const plans = R.filter((plan) => plan.symbol === "premium")(availablePlans);
    const strikeThroughPrice = plans.map((plan) => plan.price)[0];
    return formatCurrency(strikeThroughPrice, languageCode);
  },
);

export const selectAllBundlePrices = createSelector(
  selectAvailableProducts,
  (availablePlans) => {
    const plans = R.filter(
      (plan) => plan.bundlePrices?.length !== 0 && plan.symbol === "premium",
    )(availablePlans);
    return plans.map((plan) => plan.bundlePrices)[0];
  },
);

export const selectFamilyPriceForTwoAdults = createSelector(
  selectShowExclusiveOffer,
  selectAllBundlePrices,
  selectLanguageCode,
  (showExclusiveOffer, bundlePrices, languageCode) => {
    const price = showExclusiveOffer
      ? bundlePrices?.[0].discounted_price
      : bundlePrices?.[0].price;
    return formatCurrency(price, languageCode);
  },
);

export const selectFamilyStrikeThroughPriceForTwoAdults = createSelector(
  selectAllBundlePrices,
  selectLanguageCode,
  (bundlePrices, languageCode) => {
    const strikeThroughPrice = bundlePrices?.[0].marketing_price;
    return formatCurrency(strikeThroughPrice, languageCode);
  },
);

const selectBundleUpgradePrices = createSelector(
  selectAvailableProducts,
  (availablePlans) => {
    const plans = R.filter((plan) => plan.isFamily)(availablePlans);

    return plans.map((plan) => ({
      quantity: plan.quantity,
      upgradePrice: plan.upgradePrice,
    }));
  },
);

const selectUpgradePlans = createSelector(
  selectAvailableProducts,
  selectActivePlan,
  selectIsActivePlanBundle,
  selectBundleUpgradePrices,
  selectIsLoadingById(PRODUCTS_FORM_ID),
  selectLoadingPlan,
  selectIsLoadingPlanBundle,
  (
    availablePlans,
    activePlan,
    isActivePlanBundle,
    bundlePrices,
    isLoading,
    loadingPlan,
    isLoadingPlanBundle,
  ) => {
    const hasFamilyAvailable = R.any((plan) => plan.isFamily)(availablePlans);
    let plans = R.filter((plan) => !plan.isFamily)(availablePlans);

    // ensure plans have correct loading data
    plans = R.map(
      (plan) => ({
        ...plan,
        jsxKey: plan.symbol,
        isLoading:
          isLoading &&
          loadingPlan.symbol === plan.symbol &&
          !isLoadingPlanBundle,
        defaultQuantity: 1,
      }),
      plans,
    );

    if (hasFamilyAvailable) {
      // https://www.notion.so/willful/Family-Plan-quantity-defaults-5cae728b2a0845599d3ca76c817af2f2
      // family plan users should see placeholder when upgrading
      let defaultQuantity = null;
      if (!isActivePlanBundle) {
        // Essentials should see family quantity of 2 and premium should see 1
        defaultQuantity = activePlan.symbol === ESSENTIALS_PLAN ? 2 : 1;
      }
      plans = R.append({
        ...bundlePlan,
        isLoading: isLoading && isLoadingPlanBundle,
        bundlePrices,
        isPlanSelected: isActivePlanBundle,
        defaultQuantity,
      })(plans);
    }

    if (isActivePlanBundle) {
      return plans;
    }

    return [
      { ...activePlan, isPlanSelected: true, defaultQuantity: 1 },
      ...plans,
    ];
  },
);

const selectUnpaidPlans = createSelector(
  selectAvailableProducts,
  selectIsEssentialsOnlyProvinceSelected,
  selectActivePlanSymbol,
  selectIsActivePlanBundle,
  selectActivePlanQuantity,
  selectIsLoadingById(PRODUCTS_FORM_ID),
  selectLoadingPlan,
  selectIsLoadingPlanBundle,
  selectAllBundlePrices,
  (
    availablePlans,
    isEssentialsOnly,
    activePlanSymbol,
    isActivePlanBundle,
    activePlanQuantity,
    isLoading,
    loadingPlan,
    isBundleLoading,
    bundlePrices,
  ) => {
    let plans = availablePlans;
    if (!isEssentialsOnly) {
      plans = R.append(bundlePlan)(plans);
    }

    return plans
      .map((plan) => {
        if (plan.isBundle) {
          // https://www.notion.so/willful/Family-Plan-quantity-defaults-5cae728b2a0845599d3ca76c817af2f2
          let defaultQuantity = 2;
          if (isActivePlanBundle) {
            defaultQuantity = activePlanQuantity;
          }
          return {
            ...plan,
            bundlePrices,
            isPlanSelected: isActivePlanBundle,
            isLoading: isLoading && isBundleLoading,
            defaultQuantity,
          };
        }
        return {
          ...plan,
          jsxKey: plan.symbol,
          isPlanSelected:
            activePlanSymbol === plan.symbol && !isActivePlanBundle,
          isLoading:
            isLoading && loadingPlan.symbol === plan.symbol && !isBundleLoading,
          defaultQuantity: 1,
        };
      }) // ensure essentials is displayed as first plan if BE returns out of order
      .sort((a, b) => a.id - b.id);
  },
);

const selectAvailablePlans = createSelector(
  selectIsUserUpgrading,
  selectUnpaidPlans,
  selectUpgradePlans,
  (isUpgrading, unpaidPlans, upgradePlans) =>
    isUpgrading ? upgradePlans : unpaidPlans,
);

export const selectIsNotUpgradablePlan = createSelector(
  selectAvailablePlans,
  (availablePlans) => availablePlans.length === 0,
);

const upgradeDescriptionMap = {
  essentials: "upgradeDescriptionEssentials",
  premium: "upgradeDescriptionPremium",
  bundle: "upgradeDescriptionBundle",
  essentialsQC: "upgradeDescriptionEssentialsQC",
};

const getDescriptionKey = (activePlanSymbol, isQuebec, isBundle) => {
  let planKey = activePlanSymbol;
  if (isQuebec) {
    planKey = "essentialsQC";
  }
  if (isBundle) {
    planKey = "bundle";
  }
  return upgradeDescriptionMap[planKey];
};

const selectPlansQuestions = selectQuestions([
  "familyPlanDetails",
  "planDifferences",
  "planSharing",
  "poaBenefits",
  "jointWill",
]);

const plansPageTranslations = selectTranslations([
  "global",
  "plansCards",
  "plansTable",
  "trustIcon",
  "plansAndRecommendationsPages",
]);

const selectPlansPageTranslations = createSelector(
  plansPageTranslations,
  selectPlansQuestions,
  selectActivePlanSymbol,
  selectIsUserUpgrading,
  selectBundleUpgradePrices,
  selectIsCurrentProvinceSelectedByCode("QC"),
  selectIsActivePlanBundle,
  selectAllBundlePrices,
  selectShowExclusiveOffer,
  (
    paramBaseTranslations,
    paramFaqTranslations,
    activePlanSymbol,
    isUpgrading,
    upgradePrices,
    isQuebecSelected,
    isActivePlanBundle,
    bundlePrices,
    showExclusiveOffer,
  ) => {
    if (isUpgrading) {
      const descriptionKey = getDescriptionKey(
        activePlanSymbol,
        isQuebecSelected,
        isActivePlanBundle,
      );
      const labels = paramBaseTranslations.bundleUpgradeOptions;

      const bundleSizeOptions = upgradePrices.map((option, index) => {
        let label;
        if (activePlanSymbol !== ESSENTIALS_PLAN) {
          label =
            index === 0
              ? labels.premiumLabelSingular.replace("QUANTITY", option.quantity)
              : labels.premiumLabel.replace("QUANTITY", option.quantity);
        } else {
          label = labels.essentialsLabel.replace("QUANTITY", option.quantity);
        }

        return {
          label,
          value: option.quantity,
        };
      });

      return {
        ...paramBaseTranslations,
        description: paramBaseTranslations[descriptionKey],
        selectedPlanLabel: paramBaseTranslations.upgradingSelectedPlanLabel,
        questions: paramFaqTranslations,
        bundleSizeOptions,
        bundlePlanLabel:
          activePlanSymbol === ESSENTIALS_PLAN
            ? paramBaseTranslations.bundlePlanLabel
            : paramBaseTranslations.bundlePlanLabelPremium,
      };
    }

    const bundleSizeOptions = replaceBundleSizeOptions(
      paramBaseTranslations,
      bundlePrices,
      selectLanguageCode,
      showExclusiveOffer,
    );

    return {
      ...paramBaseTranslations,
      bundleSizeOptions,
      questions: paramFaqTranslations,
      selectedPlanLabel: paramBaseTranslations.unpaidSelectedPlanLabel,
    };
  },
);

const selectPricesObjectMap = createSelector(
  selectAvailablePlans,
  selectShowExclusiveOffer,
  selectLanguageCode,
  (availablePlans, showExclusiveOffer, languageCode) => {
    return availablePlans.reduce((obj, plan) => {
      const discountedPrice = plan.isBundle
        ? plan.bundlePrices?.[0].discounted_price
        : plan.discountedPrice;
      const price = showExclusiveOffer ? discountedPrice : plan.price;
      return { ...obj, [plan.jsxKey]: formatCurrency(price, languageCode) };
    }, {});
  },
);

const selectStrikeThroughPricesObjectMap = createSelector(
  selectAvailablePlans,
  selectLanguageCode,
  (availablePlans, languageCode) => {
    return availablePlans.reduce((obj, plan) => {
      const strikeThroughPrice = plan.isBundle
        ? plan.bundlePrices?.[0].marketing_price
        : plan.price;
      return {
        ...obj,
        [plan.jsxKey]: formatCurrency(strikeThroughPrice, languageCode),
      };
    }, {});
  },
);

const selectRecommendedForYou = createSelector(
  selectPeopleQuantityIntent,
  selectProvinceCode,
  (peopleQuantityIntent, currentProvinceCode) => {
    if (currentProvinceCode === "QC") {
      return NOTARIAL_PLAN;
    }
    let recommendation = "";
    switch (peopleQuantityIntent) {
      case "one_person":
        recommendation = PREMIUM_PLAN;
        break;
      case "two_people":
      case "multi_people":
        recommendation = bundlePlan.jsxKey;
        break;
      default:
        break;
    }
    return recommendation;
  },
);

const selectTableRowsExpandedState = R.pathOr({}, [
  "plans",
  "tableRowsExpanded",
]);

export const replaceTrialDaysValuesOnPlanCard = (
  paramPlansTranslations,
  subscriptionDefaultTrialDays,
) => {
  // Deep clone translations object before modifying, otherwise the "TRIAL_DAYS"
  // value will be replaced in the original object too
  const plansTranslations = JSON.parse(JSON.stringify(paramPlansTranslations));
  const plansNames = ["essentials", "premium", "bundle", "notarial"];
  plansNames.forEach((planName) => {
    if (plansTranslations[planName].details) {
      // details is an array of bullet points
      plansTranslations[planName].details.forEach((bulletPoint, index) => {
        if (bulletPoint.includes("TRIAL_DAYS")) {
          plansTranslations[planName].details[index] = bulletPoint.replace(
            "TRIAL_DAYS",
            subscriptionDefaultTrialDays,
          );
        }
      });
    }
    if (plansTranslations[planName].cardFooterDescription) {
      plansTranslations[planName].cardFooterDescription = plansTranslations[
        planName
      ].cardFooterDescription.replace(
        "TRIAL_DAYS",
        subscriptionDefaultTrialDays,
      );
    }
  });
  return plansTranslations;
};

export const replaceTrialDaysValuesOnFAQ = (
  paramsQuestions,
  subscriptionDefaultTrialDays,
) => {
  if (!paramsQuestions) return paramsQuestions;

  const questions = JSON.parse(JSON.stringify(paramsQuestions));
  questions.forEach((question, index) => {
    if (question.listItemDescription.includes("TRIAL_DAYS")) {
      questions[
        index
      ].listItemDescription = question.listItemDescription.replace(
        /TRIAL_DAYS/g,
        subscriptionDefaultTrialDays,
      );
    }
  });
  return questions;
};

export const selectPayInInstallmentsText = (price) =>
  createSelector(
    selectPlansPageTranslations,
    selectLanguageCode,
    selectIsFrenchSelected,
    (translations, languageCode, isFrenchSelected) => {
      let cleanedPrice = price.replace("$", "");
      if (isFrenchSelected) {
        // french price has a comma for decimals
        cleanedPrice = cleanedPrice.replace(",", ".");
      }
      const dividedPrice = (parseFloat(cleanedPrice) / 4).toFixed(2);
      const formattedPrice = formatCurrency(dividedPrice, languageCode);
      return translations.payInInstallments.replace("PRICE", formattedPrice);
    },
  );

export const selectShoudlShowPayInInstallments = createSelector(
  selectCurrentRoute,
  (currentRoute) => {
    const allowedpages = [
      PLANS_PATH,
      PREMIUM_RECOMMENDED_PATH,
      FAMILY_RECOMMENDED_PATH,
    ];
    const isBNPLFeatureEnabled =
      window.env.FEATURE_BNPL_CHECKOUT_PAGE_EXPERIMENT === "enabled";
    return allowedpages.includes(currentRoute) && isBNPLFeatureEnabled;
  },
);

export const plansPageProps = createStructuredSelector({
  hasLoaded: selectHasLoadedDataById(PRODUCTS_FORM_ID),
  translations: selectPlansPageTranslations,
  shouldDisablePlans: selectHasUserPaid,
  availablePlans: selectAvailablePlans,
  isFrenchSelected: selectIsFrenchSelected,
  isUserUpgrading: selectIsUserUpgrading,
  isQuebecSelected: selectIsCurrentProvinceSelectedByCode("QC"),
  isEssentialsOnlyProvinceSelected: selectIsEssentialsOnlyProvinceSelected,
  isNewBrunswickSelected: selectIsCurrentProvinceSelectedByCode("NB"),
  languageCode: selectLanguageCode,
  showOverviewLink: selectShouldShowOverviewLink,
  overviewLinkPath: selectOverviewLinkPath,
  showExclusiveOffer: selectShowExclusiveOffer,
  prices: selectPricesObjectMap,
  strikeThroughPrices: selectStrikeThroughPricesObjectMap,
  recommendedForYou: selectRecommendedForYou,
  isRowExpanded: selectTableRowsExpandedState,
});
