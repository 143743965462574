import React from "react";
import { Field } from "redux-form";

import TextInput from "./TextInput";

import { useValidateEmailMemo } from "./validations";
import { EMAIL_FIELD_ID } from "../../../constants/forms";

const EmailField = ({
  useTheir,
  translations,
  showHTMLErrorMessage = false,
  onBlur = null,
}) => (
  <Field
    showHTMLErrorMessage={showHTMLErrorMessage}
    component={TextInput}
    name={EMAIL_FIELD_ID}
    label={translations.label.email}
    placeholder={
      useTheir
        ? translations.label.emailTheirPlaceholder
        : translations.label.emailPlaceholder
    }
    type="email"
    validate={useValidateEmailMemo(translations)}
    onBlur={onBlur}
  />
);

export default EmailField;
