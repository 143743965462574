import React from "react";
import { reduxForm } from "redux-form";
import { Box } from "@material-ui/core";
import { PrimaryButton } from "../../ui/Button";
import { ErrorMessage } from "../../ui/Error";
import {
  PROPERTY_ATTORNEY_MODAL_FORM_ID,
  PROPERTY_ATTORNEYS_FIELD_ID,
} from "../../../constants/forms";
import NameFields from "../../ui/inputs/NameFields";

const PropertyAttorneyModal = ({
  error,
  invalid,
  handleSubmit,
  isLoading,
  translations,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Box mt={1}>
        <NameFields
          useTheir
          fieldName={PROPERTY_ATTORNEYS_FIELD_ID}
          translations={translations}
        />
        <PrimaryButton
          fullWidth
          isLoading={isLoading}
          text={translations.button.submit}
          opacity={invalid ? 0.5 : 1}
        />
        <ErrorMessage error={error} />
      </Box>
    </form>
  );
};

export default reduxForm({
  form: PROPERTY_ATTORNEY_MODAL_FORM_ID,
})(PropertyAttorneyModal);
