import { useMemo } from "react";
import {
  FIRST_NAME_FIELD_ID,
  LAST_NAME_FIELD_ID,
  FIRST_PARENT_FIELD_ID,
  SECOND_PARENT_FIELD_ID,
  DOB_FIELD_ID,
  PROVINCE_FIELD_ID,
  EMAIL_FIELD_ID,
  PASSWORD_FIELD_ID,
  TOS_FIELD_ID,
  INHERITANCE_FIELD_ID,
  CEREMONY_FIELD_ID,
  LANGUAGE_FIELD_ID,
  RESTING_FIELD_ID,
  RESIDENCE_FIELD_ID,
  PREDECEASE_FIELD_ID,
  PRIMARY_BENEFICIARY_FIELD_ID,
  GENDER_FIELD_ID,
  MARITAL_STATUS_FIELD_ID,
  NUM_PETS_FIELD_ID,
  ASHES_FIELD_ID,
  BACKUPS_FIELD_ID,
  NUM_CHILDREN_FIELD_ID,
  SPOUSE_IS_EXECUTOR_FIELD_ID,
  SPOUSE_IS_PERSONAL_ATTORNEY_FIELD_ID,
  SPOUSE_IS_PROPERTY_ATTORNEY_FIELD_ID,
  LIFE_PROLONG_FIELD_ID,
  PAIN_MANAGE_FIELD_ID,
  LOCKED_PROVINCE_LEAD_FIELD_ID,
  PEOPLE_QUANTITY_INTENT_FIELD_ID,
  ASSET_TYPE_FIELD_ID,
  SPOUSE_LAST_NAME_FIELD_ID,
  SPOUSE_FIRST_NAME_FIELD_ID,
} from "../../../constants/forms";

import {
  required,
  maxLength,
  minLength,
  minValue,
  maxValue,
  email,
  passwordsMustMatch,
  emailsMustMatch,
  canadianPostalCode,
  nameCapitalization,
  notEmptyArray,
  exactLength,
  isInteger,
  maxDecimals,
  isChecked,
  isDateInFuture,
} from "./root-validations";
import { getTranspiledMessage } from "../../../utilities/helpers";

// beware of infinite loops when creating closures!!!
// https://github.com/erikras/redux-form/issues/2629#issuecomment-423811863
// https://github.com/erikras/redux-form/issues/4148

export const useMemoizedValidate = (translations, validateFn) =>
  useMemo(() => validateFn(translations), [translations, validateFn]);

const getRequiredValidationMessage = (translations, fieldId) => {
  if (fieldId === "password") {
    return getTranspiledMessage(
      "{MIN}",
      8,
      translations.validationErrors.required[fieldId],
    );
  }
  return translations.validationErrors.required[fieldId];
};

export const validateRequired = (translations, fieldId, useDefault = false) => {
  const hasRequiredKey =
    translations.validationErrors.required[fieldId] !== undefined;

  const validationMessage =
    hasRequiredKey && !useDefault
      ? getRequiredValidationMessage(translations, fieldId)
      : translations.validationErrors.required.default;

  return required(
    translations.validationErrors.fieldNames[fieldId],
    validationMessage,
  );
};

export const validateIsChecked = (translations, fieldId) =>
  isChecked(
    translations.validationErrors.fieldNames[fieldId],
    translations.validationErrors.required[fieldId],
  );

const validateStartsWithWord = (value, word) =>
  value && !value.toLowerCase().trim().startsWith(word);

export const validateProvince = (translations) => [
  validateRequired(translations, PROVINCE_FIELD_ID),
];
export const useValidateProvinceMemo = (translations) =>
  useMemoizedValidate(translations, validateProvince);

const validateCity = (translations) => [
  validateRequired(translations, RESIDENCE_FIELD_ID),
  nameCapitalization(translations.validationErrors.cityNameCapitalization),
];
export const useValidateCityMemo = (translations) =>
  useMemoizedValidate(translations, validateCity);

const validateLockedProvince = (translations) => [
  validateRequired(translations, LOCKED_PROVINCE_LEAD_FIELD_ID),
];

export const useValidateLockedProvinceLead = (translations) =>
  useMemoizedValidate(translations, validateLockedProvince);

const validateLanguage = (translations) => [
  validateRequired(translations, LANGUAGE_FIELD_ID),
];
export const useValidateLanguageMemo = (translations) =>
  useMemoizedValidate(translations, validateLanguage);

const validateFirstName = (translations) => [
  validateRequired(translations, FIRST_NAME_FIELD_ID),
  minLength(1, translations.validationErrors.minLength),
  maxLength(30, translations.validationErrors.maxLength),
  nameCapitalization(translations.validationErrors.nameCapitalization),
];
export const useValidateFirstNameMemo = (translations) =>
  useMemoizedValidate(translations, validateFirstName);

const validateMiddleName = (translations) => [
  minLength(1, translations.validationErrors.minLength),
  maxLength(30, translations.validationErrors.maxLength),
  nameCapitalization(translations.validationErrors.nameCapitalization),
];
export const useValidateMiddleNameMemo = (translations) =>
  useMemoizedValidate(translations, validateMiddleName);

const validateLastName = (translations) => [
  validateRequired(translations, LAST_NAME_FIELD_ID),
  minLength(1, translations.validationErrors.minLength),
  maxLength(30, translations.validationErrors.maxLength),
  nameCapitalization(translations.validationErrors.nameCapitalization),
];
export const useValidateLastNameMemo = (translations) =>
  useMemoizedValidate(translations, validateLastName);

const validateSpouseFirstName = (translations) => [
  minLength(1, translations.validationErrors.minLength),
  maxLength(30, translations.validationErrors.maxLength),
  nameCapitalization(translations.validationErrors.nameCapitalization),
];
export const useValidateSpouseFirstNameMemo = (translations) =>
  useMemoizedValidate(translations, validateSpouseFirstName);

const validateSpouseFirstNameRequired = (translations) => [
  validateRequired(translations, SPOUSE_FIRST_NAME_FIELD_ID),
  minLength(1, translations.validationErrors.minLength),
  maxLength(30, translations.validationErrors.maxLength),
  nameCapitalization(translations.validationErrors.nameCapitalization),
];
export const useValidateSpouseFirstNameRequiredMemo = (translations) =>
  useMemoizedValidate(translations, validateSpouseFirstNameRequired);

const validateSpouseLastName = (translations) => [
  minLength(1, translations.validationErrors.minLength),
  maxLength(30, translations.validationErrors.maxLength),
  nameCapitalization(translations.validationErrors.nameCapitalization),
];
export const useValidateSpouseLastNameMemo = (translations) =>
  useMemoizedValidate(translations, validateSpouseLastName);

const validateSpouseLastNameRequired = (translations) => [
  validateRequired(translations, SPOUSE_LAST_NAME_FIELD_ID),
  minLength(1, translations.validationErrors.minLength),
  maxLength(30, translations.validationErrors.maxLength),
  nameCapitalization(translations.validationErrors.nameCapitalization),
];
export const useValidateSpouseLastNameRequiredMemo = (translations) =>
  useMemoizedValidate(translations, validateSpouseLastNameRequired);

const validatePhoneNumber = (translations) => [
  minLength(10, translations.validationErrors.phoneNumber),
  maxLength(17, translations.validationErrors.phoneNumber),
];
export const useValidatePhoneNumberMemo = (translations) =>
  useMemoizedValidate(translations, validatePhoneNumber);

export const validateEmail = (translations) => [
  validateRequired(translations, EMAIL_FIELD_ID),
  email(translations.validationErrors.email),
];
export const useValidateEmailMemo = (translations) =>
  useMemoizedValidate(translations, validateEmail);

const validateDateOfBirth = (translations) => [
  validateRequired(translations, DOB_FIELD_ID),
  isDateInFuture(translations.validationErrors.dateInFuture, DOB_FIELD_ID),
];

export const useValidateDateOfBirthMemo = (translations) =>
  useMemoizedValidate(translations, validateDateOfBirth);

const validatePassword = (translations) => [
  validateRequired(translations, PASSWORD_FIELD_ID),
  minLength(8, translations.validationErrors.minLength),
  maxLength(128, translations.validationErrors.maxLength),
];

export const PASSWORD_STRENGTH_INVALID = "invalid";
export const PASSWORD_STRENGTH_OKAY = "okay";
export const PASSWORD_STRENGTH_GOOD = "good";
export const PASSWORD_STRENGTH_STRONG = "strong";

export const validatePasswordStrength = (password) => {
  // Helper functions for checking conditions
  const hasLetters = /[a-zA-Z]/.test(password);
  const hasNumbers = /\d/.test(password);
  const hasSymbols = /\W/.test(password);
  const isOnlyLetters = /^[a-zA-Z]+$/.test(password);
  const isOnlyNumbers = /^\d+$/.test(password);
  const isOnlySymbols = !/[^!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(password);

  // Check password length and conditions
  if (password.length < 8) {
    return PASSWORD_STRENGTH_INVALID;
  }

  if (password.length >= 12) {
    return PASSWORD_STRENGTH_STRONG;
  }

  if (
    password.length >= 8 &&
    (isOnlyLetters || isOnlyNumbers || isOnlySymbols)
  ) {
    return PASSWORD_STRENGTH_OKAY;
  }

  if (password.length >= 8 && (hasLetters || hasNumbers || hasSymbols)) {
    return PASSWORD_STRENGTH_GOOD;
  }

  return PASSWORD_STRENGTH_INVALID;
};

export const useValidatePasswordMemo = (translations) =>
  useMemoizedValidate(translations, validatePassword);

const validateLegacyPassword = (translations) => [
  validateRequired(translations, PASSWORD_FIELD_ID, true),
  minLength(6, translations.validationErrors.minLength),
  maxLength(128, translations.validationErrors.maxLength),
];
export const useValidateLegacyPasswordMemo = (translations) =>
  useMemoizedValidate(translations, validateLegacyPassword);

const validateOldPassword = (translations) => [
  validateRequired(translations, "oldPassword"),
  minLength(6, translations.validationErrors.minLength),
  maxLength(128, translations.validationErrors.maxLength),
];
export const useValidateOldPasswordMemo = (translations) =>
  useMemoizedValidate(translations, validateOldPassword);

const validateTos = (translations) => [
  validateRequired(translations, TOS_FIELD_ID),
  validateIsChecked(translations, TOS_FIELD_ID),
];
export const useValidateTosMemo = (translations) =>
  useMemoizedValidate(translations, validateTos);

const validateFirstParent = (translations) => [
  validateRequired(translations, FIRST_PARENT_FIELD_ID),
];
export const useValidateFirstParentMemo = (translations) =>
  useMemoizedValidate(translations, validateFirstParent);

const validateSecondParent = (translations) => [
  validateRequired(translations, SECOND_PARENT_FIELD_ID),
];
export const useValidateSecondParentMemo = (translations) =>
  useMemoizedValidate(translations, validateSecondParent);

const validatePostalCode = (translations) => [
  validateRequired(translations, "postalCode"),
  canadianPostalCode(translations.validationErrors.canadianPostalCode),
];
export const useValidatePostalCodeMemo = (translations) =>
  useMemoizedValidate(translations, validatePostalCode);

const validateCardHolder = (translations) => [
  validateRequired(translations, "cardholderName"),
];
export const useValidateCardHolderMemo = (translations) =>
  useMemoizedValidate(translations, validateCardHolder);

const validateConfirmEmail = (translations) => [
  validateRequired(translations, "confirmEmail"),
  email(translations.validationErrors.email),
  emailsMustMatch(translations.validationErrors.emailsMustMatch),
];
export const useValidateConfirmEmailMemo = (translations) =>
  useMemoizedValidate(translations, validateConfirmEmail);

const validateNewPassword = (translations) => [
  validateRequired(translations, "newPassword"),
  minLength(8, translations.validationErrors.minLength),
];
export const useValidateNewPasswordMemo = (translations) =>
  useMemoizedValidate(translations, validateNewPassword);

const validateConfirmPassword = (translations) => [
  validateRequired(translations, "confirmPassword"),
  minLength(8, translations.validationErrors.minLength),
  passwordsMustMatch(translations.validationErrors.passwordMustMatch),
];
export const useValidateConfirmPasswordMemo = (translations) =>
  useMemoizedValidate(translations, validateConfirmPassword);

const validateConfirmCurrentPassword = (translations) => [
  validateRequired(translations, "confirmCurrentPassword"),
  // The minLength of 6 is because we may still have old accounts
  // with a 6 chars password (same reason for: validateLegacyPassword)
  minLength(6, translations.validationErrors.minLength),
];
export const useValidateConfirmCurrentPasswordMemo = (translations) =>
  useMemoizedValidate(translations, validateConfirmCurrentPassword);

const validatePetName = (translations) => [
  validateRequired(translations, "petName"),
  nameCapitalization(translations.validationErrors.nameCapitalization),
];
export const useValidatePetNameMemo = (translations) =>
  useMemoizedValidate(translations, validatePetName);

const validatePetSpecies = (translations) => [
  validateRequired(translations, "species"),
];
export const useValidatePetSpeciesMemo = (translations) =>
  useMemoizedValidate(translations, validatePetSpecies);

const validatePetTrustAmount = (translations) => [
  validateRequired(translations, "petTrust"),
];
export const useValidatePetTrustAmountMemo = (translations) =>
  useMemoizedValidate(translations, validatePetTrustAmount);

const validateGiftsStartsWithWordMy = (message) => (value) =>
  validateStartsWithWord(value, "my ")
    ? undefined
    : message.replace("{USER_INPUT}", value);
const validateGiftDescription = (translations) => [
  validateRequired(translations, "giftDescription"),
  validateGiftsStartsWithWordMy(
    translations.validationErrors.giftStartsWithWordMy,
  ),
];
export const useValidateGiftDescriptionMemo = (translations) =>
  useMemoizedValidate(translations, validateGiftDescription);

const validateGiftDescriptionQC = (translations) => [
  validateRequired(translations, "giftDescription"),
];
export const useValidateGiftDescriptionMemoQC = (translations) =>
  useMemoizedValidate(translations, validateGiftDescriptionQC);

const validateCharityName = (translations) => [
  validateRequired(translations, "charityName"),
];
export const useValidateCharityNameMemo = (translations) =>
  useMemoizedValidate(translations, validateCharityName);

const validateCharity = (translations) => [
  validateRequired(translations, "charityId"),
];
export const useValidateCharityMemo = (translations) =>
  useMemoizedValidate(translations, validateCharity);

const validateCharityAmount = (translations) => [
  validateRequired(translations, "charityAmount"),
];
export const useValidateCharityAmountMemo = (translations) =>
  useMemoizedValidate(translations, validateCharityAmount);

const validateResting = (translations) => [
  validateRequired(translations, RESTING_FIELD_ID),
];
export const useValidateRestingMemo = (translations) =>
  useMemoizedValidate(translations, validateResting);

const validateCeremony = (translations) => [
  validateRequired(translations, CEREMONY_FIELD_ID),
];
export const useValidateCeremonyMemo = (translations) =>
  useMemoizedValidate(translations, validateCeremony);

const validateInheritance = (translations) => [
  validateRequired(translations, INHERITANCE_FIELD_ID),
];
export const useValidateInheritanceMemo = (translations) =>
  useMemoizedValidate(translations, validateInheritance);

const validatePredecease = (translations) => [
  validateRequired(translations, PREDECEASE_FIELD_ID),
];
export const useValidatePredeceaseMemo = (translations) =>
  useMemoizedValidate(translations, validatePredecease);

const validatePrimaryBeneficiary = (translations) => [
  validateRequired(translations, PRIMARY_BENEFICIARY_FIELD_ID),
];
export const useValidatePrimaryBeneficiaryMemo = (translations) =>
  useMemoizedValidate(translations, validatePrimaryBeneficiary);

const validateGender = (translations) => [
  validateRequired(translations, GENDER_FIELD_ID),
];
export const useValidateGenderMemo = (translations) =>
  useMemoizedValidate(translations, validateGender);

const validateMaritalStatus = (translations) => [
  validateRequired(translations, MARITAL_STATUS_FIELD_ID),
];
export const useValidateMaritalStatusMemo = (translations) =>
  useMemoizedValidate(translations, validateMaritalStatus);

const validatePetStatus = (translations) => [
  validateRequired(translations, NUM_PETS_FIELD_ID),
];
export const useValidatePetStatusMemo = (translations) =>
  useMemoizedValidate(translations, validatePetStatus);

const validateAshes = (translations) => [
  validateRequired(translations, ASHES_FIELD_ID),
];
export const useValidateAshesMemo = (translations) =>
  useMemoizedValidate(translations, validateAshes);

const validateAllocationBackups = (translations) => [
  validateRequired(translations, BACKUPS_FIELD_ID),
];
export const useValidateAllocationBackupsMemo = (translations) =>
  useMemoizedValidate(translations, validateAllocationBackups);

const validateChildrenStatus = (translations) => [
  validateRequired(translations, NUM_CHILDREN_FIELD_ID),
];
export const useValidateChildrenStatusMemo = (translations) =>
  useMemoizedValidate(translations, validateChildrenStatus);

const validateSpouseIsExecutor = (translations) => [
  validateRequired(translations, SPOUSE_IS_EXECUTOR_FIELD_ID),
];
export const useValidateSpouseIsExecutorMemo = (translations) =>
  useMemoizedValidate(translations, validateSpouseIsExecutor);

const validateSpouseIsPersonalAttorney = (translations) => [
  validateRequired(translations, SPOUSE_IS_PERSONAL_ATTORNEY_FIELD_ID),
];
export const useValidateSpouseIsPersonalAttorneyMemo = (translations) =>
  useMemoizedValidate(translations, validateSpouseIsPersonalAttorney);

const validateSpouseIsPropertyAttorney = (translations) => [
  validateRequired(translations, SPOUSE_IS_PROPERTY_ATTORNEY_FIELD_ID),
];
export const useValidateSpouseIsPropertyAttorneyMemo = (translations) =>
  useMemoizedValidate(translations, validateSpouseIsPropertyAttorney);

const validateLifeProlong = (translations) => [
  validateRequired(translations, LIFE_PROLONG_FIELD_ID),
];
export const useValidateLifeProlongMemo = (translations) =>
  useMemoizedValidate(translations, validateLifeProlong);

const validatePainManage = (translations) => [
  validateRequired(translations, PAIN_MANAGE_FIELD_ID),
];
export const useValidatePainManageMemo = (translations) =>
  useMemoizedValidate(translations, validatePainManage);

const validateAllocations = (translations) => [
  notEmptyArray(translations.validationErrors.notEmptyArray),
];
export const useValidateAllocationsMemo = (translations) =>
  useMemoizedValidate(translations, validateAllocations);

const validatePeopleQuantityIntent = (translations) => [
  validateRequired(translations, PEOPLE_QUANTITY_INTENT_FIELD_ID),
];
export const useValidatePeopleQuantityIntentMemo = (translations) =>
  useMemoizedValidate(translations, validatePeopleQuantityIntent);

const validateAssetType = (translations) => [
  validateRequired(translations, ASSET_TYPE_FIELD_ID),
];
export const useValidateAssetTypeMemo = (translations) =>
  useMemoizedValidate(translations, validateAssetType);

const validatePropertyDescription = (translations) => [
  validateRequired(translations, "propertyDescription"),
  maxLength(50, translations.validationErrors.maxLength),
];
export const useValidatePropertyDescriptionMemo = (translations) =>
  useMemoizedValidate(translations, validatePropertyDescription);

const validatePropertyAddress = (translations) => [
  validateRequired(translations, "propertyAddress"),
];
export const useValidatePropertyAddressMemo = (translations) =>
  useMemoizedValidate(translations, validatePropertyAddress);

const validatePropertyOwnership = (translations) => [
  validateRequired(translations, "propertyOwnership"),
];
export const useValidatePropertyOwnershipMemo = (translations) =>
  useMemoizedValidate(translations, validatePropertyOwnership);

const validateVehicleDescription = (translations) => [
  validateRequired(translations, "vehicleDescription"),
  maxLength(50, translations.validationErrors.maxLength),
];
export const useValidateVehicleDescriptionMemo = (translations) =>
  useMemoizedValidate(translations, validateVehicleDescription);

const validateVehicleYear = (translations) => [
  exactLength(
    4,
    translations.validationErrors.exactDigitNumber.replace("%X", 4),
  ),
  isInteger(translations.validationErrors.exactDigitNumber.replace("%X", 4)),
];
export const useValidateVehicleYearMemo = (translations) =>
  useMemoizedValidate(translations, validateVehicleYear);

const validateVehicleMake = (translations) => [
  maxLength(150, translations.validationErrors.maxLength),
];
export const useValidateVehicleMakeMemo = (translations) =>
  useMemoizedValidate(translations, validateVehicleMake);

const validateVehicleModel = (translations) => [
  maxLength(150, translations.validationErrors.maxLength),
];
export const useValidateVehicleModelMemo = (translations) =>
  useMemoizedValidate(translations, validateVehicleModel);

const validateCollectibleDescription = (translations) => [
  validateRequired(translations, "collectibleDescription"),
  maxLength(50, translations.validationErrors.maxLength),
];
export const useValidateCollectibleDescriptionMemo = (translations) =>
  useMemoizedValidate(translations, validateCollectibleDescription);

const validateCollectibleType = (translations) => [
  validateRequired(translations, "collectibleType"),
];
export const useValidateCollectibleTypeMemo = (translations) =>
  useMemoizedValidate(translations, validateCollectibleType);

const validateCollectibleLocation = (translations) => [
  maxLength(150, translations.validationErrors.maxLength),
];
export const useValidateCollectibleLocationMemo = (translations) =>
  useMemoizedValidate(translations, validateCollectibleLocation);

const validateAccountDescription = (translations) => [
  validateRequired(translations, "accountDescription"),
  maxLength(50, translations.validationErrors.maxLength),
];
export const useValidateAccountDescriptionMemo = (translations) =>
  useMemoizedValidate(translations, validateAccountDescription);

const validateAccountOwnership = (translations) => [
  validateRequired(translations, "accountOwnership"),
];
export const useValidateAccountOwnershipMemo = (translations) =>
  useMemoizedValidate(translations, validateAccountOwnership);

const validateFinancialInstitution = (translations) => [
  validateRequired(translations, "financialInstitution"),
  maxLength(150, translations.validationErrors.maxLength),
];
export const useValidateFinancialInstitutionMemo = (translations) =>
  useMemoizedValidate(translations, validateFinancialInstitution);

const validateBusinessInterestDescription = (translations) => [
  validateRequired(translations, "businessInterestDescription"),
  maxLength(50, translations.validationErrors.maxLength),
];
export const useValidateBusinessInterestDescriptionMemo = (translations) =>
  useMemoizedValidate(translations, validateBusinessInterestDescription);

const validateIssuingCompany = (translations) => [
  validateRequired(translations, "issuingCompany"),
  maxLength(150, translations.validationErrors.maxLength),
];
export const useValidateIssuingCompanyMemo = (translations) =>
  useMemoizedValidate(translations, validateIssuingCompany);

const validateNumberOfShares = (translations) => [
  isInteger(translations.validationErrors.number),
  maxLength(150, translations.validationErrors.maxLength),
];
export const useValidateNumberOfSharesMemo = (translations) =>
  useMemoizedValidate(translations, validateNumberOfShares);

const validatePensionDescription = (translations) => [
  validateRequired(translations, "pensionDescription"),
  maxLength(50, translations.validationErrors.maxLength),
];
export const useValidatePensionDescriptionMemo = (translations) =>
  useMemoizedValidate(translations, validatePensionDescription);

const validatePensionContact = (translations) => [
  maxLength(150, translations.validationErrors.maxLength),
];
export const useValidatePensionContactMemo = (translations) =>
  useMemoizedValidate(translations, validatePensionContact);

const validateCreditCardType = (translations) => [
  validateRequired(translations, "creditCardType"),
];
export const useValidateCreditCardTypeMemo = (translations) =>
  useMemoizedValidate(translations, validateCreditCardType);

const validateCreditCardDescription = (translations) => [
  validateRequired(translations, "creditCardDescription"),
  maxLength(50, translations.validationErrors.maxLength),
];
export const useValidateCreditCardDescriptionMemo = (translations) =>
  useMemoizedValidate(translations, validateCreditCardDescription);

const validateNameOnCard = (translations) => [
  maxLength(150, translations.validationErrors.maxLength),
];
export const useValidateNameOnCardMemo = (translations) =>
  useMemoizedValidate(translations, validateNameOnCard);

const validateInsuranceDescription = (translations) => [
  validateRequired(translations, "insuranceDescription"),
  maxLength(50, translations.validationErrors.maxLength),
];
export const useValidateInsuranceDescriptionMemo = (translations) =>
  useMemoizedValidate(translations, validateInsuranceDescription);

const validateInsuranceCompany = (translations) => [
  validateRequired(translations, "insuranceCompany"),
  maxLength(150, translations.validationErrors.maxLength),
];
export const useValidateInsuranceCompanyMemo = (translations) =>
  useMemoizedValidate(translations, validateInsuranceCompany);

const validateInsuranceContact = (translations) => [
  maxLength(150, translations.validationErrors.maxLength),
];
export const useValidateInsuranceContactMemo = (translations) =>
  useMemoizedValidate(translations, validateInsuranceContact);

const validateOtherOwnerFirstName = (translations) => [
  minLength(1, translations.validationErrors.minLength),
  maxLength(30, translations.validationErrors.maxLength),
  nameCapitalization(translations.validationErrors.nameCapitalization),
];
export const useValidateOtherOwnerFirstNameMemo = (translations) =>
  useMemoizedValidate(translations, validateOtherOwnerFirstName);

const validateOtherOwnerMiddleName = (translations) => [
  minLength(1, translations.validationErrors.minLength),
  maxLength(30, translations.validationErrors.maxLength),
  nameCapitalization(translations.validationErrors.nameCapitalization),
];
export const useValidateOtherOwnerMiddleNameMemo = (translations) =>
  useMemoizedValidate(translations, validateOtherOwnerMiddleName);

const validateOtherOwnerLastName = (translations) => [
  minLength(1, translations.validationErrors.minLength),
  maxLength(30, translations.validationErrors.maxLength),
  nameCapitalization(translations.validationErrors.nameCapitalization),
];
export const useValidateOtherOwnerLastNameMemo = (translations) =>
  useMemoizedValidate(translations, validateOtherOwnerLastName);

const validateSpecificAge = (translations) => [
  validateRequired(translations, "age"),
  isInteger(translations.validationErrors.exactDigitNumber.replace("%X", 2)),
];
export const useValidateSpecificAgeMemo = (translations) =>
  useMemoizedValidate(translations, validateSpecificAge);

const validateSpecificPercentage = (translations) => [
  validateRequired(translations, "percentage"),
  minLength(1, translations.validationErrors.minLength),
  minValue(1, translations.validationErrors.validPercentage),
  maxValue(100, translations.validationErrors.validPercentage),
  maxDecimals(2, translations.validationErrors.maxDecimals),
];
export const useValidateSpecificPercentageMemo = (translations) =>
  useMemoizedValidate(translations, validateSpecificPercentage);
