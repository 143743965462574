import React from "react";
import { Field } from "redux-form";
import Box from "@material-ui/core/Box";

import TextInput from "./TextInput";
import SelectInput from "./DropdownSelect/index";
import AmountField from "./AmountField";
import {
  CHARITY_ID_FIELD_ID,
  CASH_AMOUNT_FIELD_ID,
  ENTRY_TYPE_FIELD_ID,
  DONATIONS_CUSTOM_CHARITY,
  REGISTERED_NAME_FIELD_ID,
  BUSINESS_NUMBER_FIELD_ID,
} from "../../../constants/forms";
import {
  useValidateCharityNameMemo,
  useValidateCharityMemo,
  useValidateCharityAmountMemo,
} from "./validations";
import BinaryRadioField from "./BinaryRadioField";

const getFieldName = (fieldName, fieldId) =>
  fieldName ? `${fieldName}.${fieldId}` : fieldId;

const DonationFields = ({
  field = {},
  fieldName,
  charitiesOptionList,
  isCustomCharity = false,
  languageCode,
  showAmountField = false,
  translations,
}) => {
  const isCustomInput =
    isCustomCharity || field[ENTRY_TYPE_FIELD_ID] === DONATIONS_CUSTOM_CHARITY;
  return (
    <Box>
      <BinaryRadioField
        name={getFieldName(fieldName, ENTRY_TYPE_FIELD_ID)}
        label={translations.entryTypeLabel}
        translations={translations}
      />
      {isCustomInput && (
        <>
          <Field
            name={getFieldName(fieldName, REGISTERED_NAME_FIELD_ID)}
            label={translations.charityNameLabel}
            component={TextInput}
            validate={useValidateCharityNameMemo(translations)}
          />
          <Field
            name={getFieldName(fieldName, BUSINESS_NUMBER_FIELD_ID)}
            label={translations.charityBusinessNumberLabel}
            component={TextInput}
          />
        </>
      )}
      {!isCustomInput && (
        <Field
          name={getFieldName(fieldName, CHARITY_ID_FIELD_ID)}
          label={translations.selectCharityLabel}
          component={SelectInput}
          validate={useValidateCharityMemo(translations)}
          optionList={charitiesOptionList}
        />
      )}
      {showAmountField && (
        <Box mt={2}>
          <AmountField
            fieldName={getFieldName(fieldName, CASH_AMOUNT_FIELD_ID)}
            label={translations.amountLabel}
            languageCode={languageCode}
            validate={useValidateCharityAmountMemo(translations)}
          />
        </Box>
      )}
    </Box>
  );
};

export default DonationFields;
