import R from "ramda";
import {
  select,
  takeEvery,
  call,
  put,
  takeLatest,
  delay,
} from "redux-saga/effects";
import {
  initialize,
  change,
  untouch,
  getFormValues,
  touch,
  actionTypes as reduxFormActionTypes,
  clearAsyncError,
  stopSubmit,
} from "redux-form";

import {
  FETCH_ALLOCATIONS_TYPE,
  UPDATE_ALLOCATIONS_TYPE,
  REMOVE_ALLOCATION_TYPE,
  REMOVE_BACKUP_TYPE,
  DISTRIBUTE_ALLOCATIONS_EVENLY_TYPE,
  UPDATE_PRIMARY_BENEFICIARY_TYPE,
  UPDATE_PREDECEASE_TYPE,
  UPDATE_INHERITANCE_TYPE,
  FETCH_BACKUP_TYPE,
  UPDATE_BACKUP_TYPE,
  REMOVE_INHERITANCE_AGE_MILESTONE_TYPE,
  ADD_INHERITANCE_AGE_MILESTONE_TYPE,
  updateAgeMilestoneVisibility,
  FETCH_INHERITANCE_TYPE,
  UPDATE_ALLOCATION_CARD_TYPE,
  updateLastAllocationsValidState,
  forcePieChartAnimation,
} from "../actions/allocations";
import { updateCharities } from "../actions/charities";
import { fetchSuccess } from "../actions/requests";
import { submitFormRequest } from "./forms";
import { fetchApiData } from "./requests";
import { selectHasLoaded } from "../selectors/requests";
import { selectHasCharities } from "../selectors/donations";
import {
  selectFormAllocations, // this isn't confusing at all 😓
  selectAllocationsForm,
  selectInheritanceAgeMilestoneVisibilityState,
  selectInheritanceForm,
  selectAllocationsFormTotalPercentage,
  selectAllocationsFormValidValues,
  selectNewPieChartAnimationKey,
  selectAllocationsTranslations,
} from "../selectors/allocations";
import { selectBackupForm, selectFormBackups } from "../selectors/backup";
import {
  getAllocations,
  submitAllocations,
  submitPrimaryBeneficiary,
  submitPredecease,
  submitInheritance,
  submitBackups,
} from "../api/allocations";
import {
  ALLOCATIONS_FORM_ID,
  ALLOCATIONS_FIELD_ID,
  PRIMARY_BENEFICIARY_FORM_ID,
  PREDECEASE_FORM_ID,
  INHERITANCE_FORM_ID,
  BACKUPS_FORM_ID,
  BACKUPS_FIELD_ID,
  CHARITY_ALLOCATION,
  PERSON_ALLOCATION,
  PERSON_ALLOCATION_FORM_ID,
  ADD_PERSON_BENEFICIARY_FIELD_ID,
  CHARITY_FORM_ID,
  CHARITY_ID_FIELD_ID,
  CUSTOM_CHARITY_FORM_ID,
  REGISTERED_NAME_FIELD_ID,
  BUSINESS_NUMBER_FIELD_ID,
} from "../constants/forms";
import {
  distributeAmountsEvenly,
  removeAllocationFromList,
} from "../utilities/allocations";
import {
  MODAL_EDIT_BENEFICIARY_PERSON,
  MODAL_EDIT_BENEFICIARY_CHARITY,
  MODAL_EDIT_BENEFICIARY_CUSTOM_CHARITY,
  MODAL_REMOVE_BENEFICIARY,
} from "../constants/modal";
import { displayModal } from "../actions/modal";
import {
  getAllocationsErrorMessage,
  percentageValidation,
} from "../components/forms/utils/allocations-form-validations";

function* fetchAllocations() {
  const hasLoaded = yield select(selectHasLoaded);
  const hasCharities = yield select(selectHasCharities);

  if (!hasLoaded || !hasCharities) {
    const { charities } = yield call(fetchApiData, {
      apiCall: getAllocations,
      formId: ALLOCATIONS_FORM_ID,
    });
    yield put(updateCharities(charities));
  }

  const allocationsFormData = yield select(selectAllocationsForm);
  const { allocations } = allocationsFormData;
  yield put(initialize(ALLOCATIONS_FORM_ID, allocationsFormData));
  yield put(updateLastAllocationsValidState(allocations));
  yield put(fetchSuccess(ALLOCATIONS_FORM_ID));
}

function* updateAllocations() {
  const totalPercentage = yield select(selectAllocationsFormTotalPercentage);
  if (totalPercentage === 100) {
    // if percentage totals to a 100, remove async validation errors
    yield put(clearAsyncError(ALLOCATIONS_FORM_ID, ALLOCATIONS_FIELD_ID));

    // submit request to the backend
    yield call(submitFormRequest, {
      apiCall: submitAllocations,
      formId: ALLOCATIONS_FORM_ID,
    });
  } else {
    // forcibly show validation errors if percentage is different than 100
    const allocations = yield select(selectAllocationsFormValidValues);
    const percentageLeftover = percentageValidation(allocations);
    yield triggerAllocationsAsyncValidation(percentageLeftover);
  }
}

function* updateAllocationsEvenly({ payload }) {
  const { allocations } = payload;
  if (allocations) {
    const evenAllocations = yield call(distributeAmountsEvenly, allocations);
    yield put(
      change(ALLOCATIONS_FORM_ID, ALLOCATIONS_FIELD_ID, evenAllocations),
    );
  } else {
    const formAllocations = yield select(selectFormAllocations);
    const evenAllocations = yield call(
      distributeAmountsEvenly,
      formAllocations,
    );
    yield put(
      change(ALLOCATIONS_FORM_ID, ALLOCATIONS_FIELD_ID, evenAllocations),
    );
  }
}

function* removeAllocation() {
  yield put(displayModal(MODAL_REMOVE_BENEFICIARY, true));
}

function* removeBackup({ payload }) {
  const { backup } = payload;
  if (!backup) return;
  const formBackups = yield select(selectFormBackups);
  const newBackups = removeAllocationFromList(backup, formBackups);

  yield put(change(BACKUPS_FORM_ID, BACKUPS_FIELD_ID, newBackups));
}

function* updatePrimaryBeneficiary() {
  yield call(submitFormRequest, {
    apiCall: submitPrimaryBeneficiary,
    formId: PRIMARY_BENEFICIARY_FORM_ID,
  });
}

function* updatePredecease() {
  yield call(submitFormRequest, {
    apiCall: submitPredecease,
    formId: PREDECEASE_FORM_ID,
  });
}

function* updateInheritance() {
  const rawFormValues = yield select(getFormValues(INHERITANCE_FORM_ID));
  const {
    ageMilestone,
    age1,
    age2,
    age3,
    percentage1,
    percentage2,
    percentage3,
  } = rawFormValues;

  const inheritanceAges = [];
  if (ageMilestone === "specific_ages") {
    if (age1) {
      inheritanceAges.push({ age: age1, percentage: percentage1 });
    }
    if (age2) {
      inheritanceAges.push({ age: age2, percentage: percentage2 });
    }
    if (age3) {
      inheritanceAges.push({ age: age3, percentage: percentage3 });
    }
  }

  const values = {
    ageMilestone,
    inheritanceAges,
  };

  yield call(submitFormRequest, {
    apiCall: submitInheritance,
    formId: INHERITANCE_FORM_ID,
    values,
  });
}

function* addInheritanceAgeMilestone() {
  const visibilityState = yield select(
    selectInheritanceAgeMilestoneVisibilityState,
  );
  // Only displays milestone3 if milestone2 is already visible
  const hasSeconddMilestone = visibilityState.displayMilestone2;

  const milestonesVisibility = {
    displayMilestone2: true,
    displayMilestone3: hasSeconddMilestone,
  };

  yield put(updateAgeMilestoneVisibility(milestonesVisibility));
}

function* clearInheritanceAgeMilestoneField(formId, ageField, percentageField) {
  yield put(change(formId, ageField, null));
  yield put(change(formId, percentageField, null));
  yield put(untouch(formId, ageField, ""));
  yield put(untouch(formId, percentageField, ""));
}

function* removeInheritanceAgeMilestone({ payload }) {
  const { milestoneIndex } = payload;
  if (!milestoneIndex) return;

  const formId = INHERITANCE_FORM_ID;
  const formValues = yield select(getFormValues(formId));

  const ageField = `age${milestoneIndex}`;
  const percentageField = `percentage${milestoneIndex}`;

  const age3 = R.propOr(null, "age3", formValues);
  const percentage3 = R.propOr(null, "percentage3", formValues);
  const visibilityState = yield select(
    selectInheritanceAgeMilestoneVisibilityState,
  );
  const hasThirdMilestone = visibilityState.displayMilestone3;

  // If a user has 3 milestones and removes the 2nd, the value of the 3rd should replace the 2nd
  if (milestoneIndex === 2 && hasThirdMilestone) {
    yield put(change(formId, ageField, age3));
    yield put(change(formId, percentageField, percentage3));
    yield call(
      clearInheritanceAgeMilestoneField,
      formId,
      "age3",
      "percentage3",
    );
  } else {
    yield call(
      clearInheritanceAgeMilestoneField,
      formId,
      ageField,
      percentageField,
    );
  }

  const milestonesVisibility = {
    displayMilestone2: hasThirdMilestone,
    displayMilestone3: false,
  };

  yield put(updateAgeMilestoneVisibility(milestonesVisibility));
}

function* fetchBackup() {
  const hasLoaded = yield select(selectHasLoaded);
  const hasCharities = yield select(selectHasCharities);

  if (!hasLoaded || !hasCharities) {
    const { charities } = yield call(fetchApiData, {
      apiCall: getAllocations,
      formId: ALLOCATIONS_FORM_ID,
    });

    yield put(updateCharities(charities));
  }
  const backupFormData = yield select(selectBackupForm);
  yield put(initialize(BACKUPS_FORM_ID, backupFormData));
  yield put(fetchSuccess(BACKUPS_FORM_ID));
}

function* updateBackup() {
  yield call(submitFormRequest, {
    apiCall: submitBackups,
    formId: BACKUPS_FORM_ID,
  });
}

function* fetchInheritance() {
  const formId = INHERITANCE_FORM_ID;

  const hasLoaded = yield select(selectHasLoaded);
  if (!hasLoaded) {
    yield call(fetchApiData, {
      apiCall: getAllocations,
      formId,
    });
  }

  const formData = yield select(selectInheritanceForm);
  yield put(initialize(formId, formData));

  const { age2, age3 } = formData;
  const milestonesVisibility = {
    displayMilestone2: age2,
    displayMilestone3: age3,
  };
  yield put(updateAgeMilestoneVisibility(milestonesVisibility));

  yield put(fetchSuccess(formId));

  // this is to guarantee that percentage1 field will show
  // errors if the user removes milestone2 and milestone3
  if (age2 || age3) yield put(touch(formId, "percentage1"));
}

function* triggerAllocationsAsyncValidation(percentageLeftover) {
  const translations = yield select(selectAllocationsTranslations);
  const errorMessage = getAllocationsErrorMessage(
    percentageLeftover,
    translations,
  );
  yield delay(10); // delay will avoid inconsistencies where the form level error is not properly shown
  yield put(stopSubmit(ALLOCATIONS_FORM_ID, { _error: errorMessage }));
}

function* handleAllocationsFormChange({ meta }) {
  const { form, field } = meta;

  // since it is a fieldArray the field can be "allocations" if it is
  // adding or removing allocations, or "allocation[index].amount"
  // if the user is changing the percentage amount
  const isAllocationsFieldArray =
    /allocations\[[0-9]+\].amount/i.test(field) ||
    field === ALLOCATIONS_FIELD_ID;

  if (form === ALLOCATIONS_FORM_ID && isAllocationsFieldArray) {
    const totalPercentage = yield select(selectAllocationsFormTotalPercentage);
    const allocations = yield select(selectAllocationsFormValidValues);
    const percentageLeftover = percentageValidation(allocations);

    // update pie chart data and force its animation if:
    // 1- form has no errors and total percentage === 100
    // 2- form has errors but percentage leftover is < 1
    if (
      totalPercentage === 100 ||
      (percentageLeftover && Math.abs(percentageLeftover) < 1)
    ) {
      yield put(updateLastAllocationsValidState(allocations));

      // after updating the last valid state, we will force the pie chart animation
      const key = yield select(selectNewPieChartAnimationKey);
      yield put(forcePieChartAnimation(key));

      if (totalPercentage !== 100) {
        // forcibly show validation errors if percentage is different than 100
        yield triggerAllocationsAsyncValidation(percentageLeftover);
      }
    }
  }
}

export function* watchFetchAllocations() {
  yield takeEvery(FETCH_ALLOCATIONS_TYPE, fetchAllocations);
}
export function* watchUpdateAllocations() {
  yield takeEvery(UPDATE_ALLOCATIONS_TYPE, updateAllocations);
}
export function* watchDistributeAllocationsEvenly() {
  yield takeEvery(DISTRIBUTE_ALLOCATIONS_EVENLY_TYPE, updateAllocationsEvenly);
}
export function* watchRemoveAllocation() {
  yield takeEvery(REMOVE_ALLOCATION_TYPE, removeAllocation);
}

export function* watchRemoveBackup() {
  yield takeEvery(REMOVE_BACKUP_TYPE, removeBackup);
}

export function* watchUpdatePrimaryBeneficiary() {
  yield takeEvery(UPDATE_PRIMARY_BENEFICIARY_TYPE, updatePrimaryBeneficiary);
}

export function* watchUpdatePredecease() {
  yield takeEvery(UPDATE_PREDECEASE_TYPE, updatePredecease);
}

export function* watchupdateInheritance() {
  yield takeEvery(UPDATE_INHERITANCE_TYPE, updateInheritance);
}
export function* watchFetchInheritance() {
  yield takeEvery(FETCH_INHERITANCE_TYPE, fetchInheritance);
}

export function* watchFetchBackup() {
  yield takeEvery(FETCH_BACKUP_TYPE, fetchBackup);
}
export function* watchUpdateBackup() {
  yield takeEvery(UPDATE_BACKUP_TYPE, updateBackup);
}

export function* watchRemoveInheritanceAgeMilestone() {
  yield takeEvery(
    REMOVE_INHERITANCE_AGE_MILESTONE_TYPE,
    removeInheritanceAgeMilestone,
  );
}

export function* watchAddInheritanceAgeMilestone() {
  yield takeEvery(
    ADD_INHERITANCE_AGE_MILESTONE_TYPE,
    addInheritanceAgeMilestone,
  );
}

export function* watchUpdateAllocationCard() {
  yield takeEvery(UPDATE_ALLOCATION_CARD_TYPE, handleUpdateAllocationCard);
}

function* handleUpdateAllocationCard({ payload }) {
  const { allocationCardIndex } = payload;

  const { allocations } = yield select(getFormValues(ALLOCATIONS_FORM_ID));
  const allocationCardData = allocations[allocationCardIndex];
  const { type } = allocationCardData;

  let modalKey = "";
  if (type === PERSON_ALLOCATION) {
    modalKey = MODAL_EDIT_BENEFICIARY_PERSON;
    yield put(
      change(PERSON_ALLOCATION_FORM_ID, ADD_PERSON_BENEFICIARY_FIELD_ID, {
        ...allocationCardData,
      }),
    );
  }
  if (type === CHARITY_ALLOCATION) {
    const { registeredName } = allocationCardData;
    if (registeredName) {
      modalKey = MODAL_EDIT_BENEFICIARY_CUSTOM_CHARITY;
      yield put(
        change(
          CUSTOM_CHARITY_FORM_ID,
          REGISTERED_NAME_FIELD_ID,
          allocationCardData.registeredName,
        ),
      );
      yield put(
        change(
          CUSTOM_CHARITY_FORM_ID,
          BUSINESS_NUMBER_FIELD_ID,
          allocationCardData.businessNumber,
        ),
      );
    } else {
      modalKey = MODAL_EDIT_BENEFICIARY_CHARITY;
      yield put(
        change(
          CHARITY_FORM_ID,
          CHARITY_ID_FIELD_ID,
          allocationCardData.charityId,
        ),
      );
    }
  }

  yield put(displayModal(modalKey));
}

export function* watchAllocationChange() {
  yield takeLatest(reduxFormActionTypes.CHANGE, handleAllocationsFormChange);
}
