import React, { useEffect } from "react";
import { connect } from "react-redux";

import { personalAttorneysNextPage } from "../../../actions/personal-attorneys";
import { spousePersonalAttorneysPageProps } from "../../../selectors/spouse-personal-attorneys";

import PersonalAttorneysForm from "../../../components/forms/your-arrangements/PersonalAttorneysForm";
import { SPOUSE_PROPERTY_ATTORNEYS_PATH } from "../../../constants/routes";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import { PERSONAL_ATTORNEYS_FORM_ID } from "../../../constants/forms";
import { getFormData } from "../../../actions/forms";

const SpousePersonalAttorneysPage = ({
  getFormData,
  personalAttorneysNextPage,
  hasLoaded,
  ...restOfProps
}) => {
  useEffect(() => {
    getFormData(PERSONAL_ATTORNEYS_FORM_ID);
  }, []);
  if (!hasLoaded) return <LoadingSpinner />;
  return (
    <PersonalAttorneysForm
      backLink={SPOUSE_PROPERTY_ATTORNEYS_PATH}
      onSubmit={() => personalAttorneysNextPage()}
      {...restOfProps}
    />
  );
};

export default connect(spousePersonalAttorneysPageProps, {
  getFormData,
  personalAttorneysNextPage,
})(SpousePersonalAttorneysPage);
