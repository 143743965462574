import styled from "styled-components";
import Box from "@material-ui/core/Box";

const StyledLightbox = styled(Box)`
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  transition: background-color 440ms ease-in-out;
  animation-name: opacity;
  animation-duration: 440ms;
  -webkit-overflow-scrolling: touch;

  @keyframes opacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export default StyledLightbox;
