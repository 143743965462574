import R from "ramda";
import { combineReducers } from "redux";
import { UPDATE_STATUS_TYPE } from "../actions/status";
import {
  ADD_PLANS_TYPE,
  EXPAND_COLLAPSE_TABLE_ROW_TYPE,
} from "../actions/plans";

const DEFAULT_STATE = {};

const activePlan = (state = DEFAULT_STATE, { type, payload }) => {
  switch (type) {
    case UPDATE_STATUS_TYPE:
      return payload.product;
    default:
      return state;
  }
};

const availablePlans = (state = [], { type, payload }) => {
  switch (type) {
    case ADD_PLANS_TYPE:
      return payload.plans;
    default:
      return state;
  }
};

const hasUpgradablePlan = (state = false, { type, payload }) => {
  switch (type) {
    case UPDATE_STATUS_TYPE:
      return payload.hasUpgradablePlan;
    default:
      return state;
  }
};

const hasPaid = (state = false, { type, payload }) => {
  switch (type) {
    case UPDATE_STATUS_TYPE:
      return R.pathOr(false, ["user", "paid"])(payload);
    default:
      return state;
  }
};

const notarizationStatus = (state = null, { type, payload }) => {
  switch (type) {
    case UPDATE_STATUS_TYPE:
      return R.pathOr(null, ["user", "notarizationStatus"])(payload);
    default:
      return state;
  }
};

const DEFAULT_PLANS_TABLE_ROWS_EXPAND = {
  will: false,
  poa: false,
  asset: false,
  notarization: false,
};
const tableRowsExpanded = (
  state = DEFAULT_PLANS_TABLE_ROWS_EXPAND,
  { type, payload },
) => {
  switch (type) {
    case EXPAND_COLLAPSE_TABLE_ROW_TYPE:
      return {
        ...DEFAULT_PLANS_TABLE_ROWS_EXPAND,
        [payload.key]: payload.expanded,
      };
    default:
      return state;
  }
};

export default combineReducers({
  activePlan,
  availablePlans,
  hasPaid,
  hasUpgradablePlan,
  notarizationStatus,
  tableRowsExpanded,
});
