import { combineReducers } from "redux";

import {
  ADD_PERSON_ALLOCATION_TYPE,
  ADD_PERSON_BACKUP_TYPE,
  BENEFICIARY_ADD_CHARITY_TYPE,
  BENEFICIARY_ADD_PERSON_TYPE,
  BENEFICIARY_CANCEL_ADDING_TYPE,
  ADD_CHARITY_ALLOCATION_TYPE,
  ADD_CHARITY_BACKUP_TYPE,
  ALLOCATION_UPDATED_SUCCESSFULLY_TYPE,
  ALLOCATION_REMOVED_SUCCESSFULLY_TYPE,
} from "../actions/beneficiary";
import {
  FORCE_PIE_CHART_ANIMATION_TYPE,
  REMOVE_ALLOCATION_TYPE,
  TOGGLE_INHERITANCE_AGE_MILESTONE_VISIBILITY_TYPE,
  UPDATE_ALLOCATION_CARD_TYPE,
  UPDATE_LAST_ALLOCATIONS_VALID_STATE_TYPE,
} from "../actions/allocations";
import { DISPLAY_ALLOCATION_HELPER_TYPE } from "../actions/allocations-slider";
import {
  NOTIFY_APPOINTEE_TYPE,
  NOTIFIED_APPOINTEE_TYPE,
  TOGGLE_APPOINTEE_CARD_TYPE,
} from "../actions/appointees";
import {
  ADD_FINANCIAL_ASSET_TYPE,
  ADD_INSURANCE_ASSET_TYPE,
  ADD_PHYSICAL_ASSET_TYPE,
  TOGGLE_PHYSICAL_ASSET_CARD_TYPE,
  TOGGLE_FINANCIAL_ASSET_CARD_TYPE,
  TOGGLE_INSURANCE_ASSET_CARD_TYPE,
} from "../actions/asset-list";
import { SET_LOADING_PLAN_TYPE } from "../actions/plans";
import {
  FINANCIAL_ASSETS_FORM_ID,
  INSURANCE_ASSETS_FORM_ID,
  PHYSICAL_ASSETS_FORM_ID,
} from "../constants/forms";
import { CLOSE_TOAST_TYPE } from "../actions/toast";
import { CLOSE_MODAL_TYPE } from "../actions/modal";
import {
  ADD_EXECUTOR_TYPE,
  OPEN_MODAL_ADD_EXECUTORS_TYPE,
  OPEN_MODAL_EDIT_EXECUTORS_TYPE,
  REMOVE_EXECUTORS_TYPE,
} from "../actions/executors";
import {
  ADD_CHILD_TYPE,
  OPEN_MODAL_CHILDREN_FORM_TYPE,
  REMOVE_CHILD_TYPE,
} from "../actions/children";
import { MODAL_ADD_CHILD_FORM } from "../constants/modal";
import {
  OPEN_MODAL_PET_TYPE,
  REMOVE_PET_TYPE,
  UPDATE_PET_SUCCESSFULLY_TYPE,
} from "../actions/pets";
import {
  OPEN_MODAL_CHILDREN_GUARDIANS_TYPE,
  REMOVE_CHILDREN_GUARDIAN_TYPE,
  UPDATE_CHILDREN_GUARDIAN_SUCCESSFULLY_TYPE,
} from "../actions/children-guardians";
import {
  OPEN_MODAL_PET_GUARDIAN_TYPE,
  REMOVE_PET_GUARDIAN_TYPE,
  UPDATE_PET_GUARDIAN_SUCCESSFULLY_TYPE,
} from "../actions/pet-guardians";
import {
  OPEN_MODAL_PROPERTY_ATTORNEY_TYPE,
  REMOVE_PROPERTY_ATTORNEY_TYPE,
  UPDATE_PROPERTY_ATTORNEY_SUCCESSFULLY_TYPE,
} from "../actions/property-attorneys";
import {
  OPEN_MODAL_PERSONAL_ATTORNEY_TYPE,
  REMOVE_PERSONAL_ATTORNEY_TYPE,
  UPDATE_PERSONAL_ATTORNEY_SUCCESSFULLY_TYPE,
} from "../actions/personal-attorneys";

const notifyingAppointee = (state = {}, { type, payload }) => {
  switch (type) {
    case NOTIFY_APPOINTEE_TYPE:
      return {
        ...state,
        [payload.key]: true,
      };
    case NOTIFIED_APPOINTEE_TYPE:
      return {
        ...state,
        [payload.key]: false,
      };
    default:
      return state;
  }
};

const toggleAppointeeCard = (state = {}, { type, payload }) => {
  switch (type) {
    case TOGGLE_APPOINTEE_CARD_TYPE:
      return {
        ...state,
        [payload.key]: payload.shouldDisplay,
      };
    case NOTIFIED_APPOINTEE_TYPE:
      return {
        ...state,
        [payload.key]: false,
      };
    default:
      return state;
  }
};

const lastAddedPerson = (state = {}, { type, payload }) => {
  switch (type) {
    case ADD_PERSON_ALLOCATION_TYPE:
    case ADD_PERSON_BACKUP_TYPE:
      return payload.person;
    default:
      return state;
  }
};

const lastAddedCharity = (state = {}, { type, payload }) => {
  switch (type) {
    case ADD_CHARITY_ALLOCATION_TYPE:
    case ADD_CHARITY_BACKUP_TYPE:
      return payload.charity;
    default:
      return state;
  }
};

const addingPerson = (state = false, { type }) => {
  switch (type) {
    case BENEFICIARY_ADD_PERSON_TYPE:
      return true;
    case BENEFICIARY_CANCEL_ADDING_TYPE:
      return false;
    default:
      return state;
  }
};

const addingCharity = (state = false, { type }) => {
  switch (type) {
    case BENEFICIARY_ADD_CHARITY_TYPE:
      return true;
    case BENEFICIARY_CANCEL_ADDING_TYPE:
      return false;
    default:
      return state;
  }
};

const displayAllocationHelper = (state = false, { type, payload }) => {
  switch (type) {
    case DISPLAY_ALLOCATION_HELPER_TYPE:
      return payload.shouldDisplay;
    default:
      return state;
  }
};

const loadingPlan = (state = {}, { type, payload }) => {
  switch (type) {
    case SET_LOADING_PLAN_TYPE:
      return payload.plan;
    default:
      return state;
  }
};

const DEFAULT_STATE = {
  [PHYSICAL_ASSETS_FORM_ID]: false,
  [FINANCIAL_ASSETS_FORM_ID]: false,
  [INSURANCE_ASSETS_FORM_ID]: false,
};
const assetListCardExpanded = (state = DEFAULT_STATE, { type, payload }) => {
  switch (type) {
    case TOGGLE_PHYSICAL_ASSET_CARD_TYPE:
      return { ...DEFAULT_STATE, [PHYSICAL_ASSETS_FORM_ID]: payload.expanded };
    case TOGGLE_FINANCIAL_ASSET_CARD_TYPE:
      return { ...DEFAULT_STATE, [FINANCIAL_ASSETS_FORM_ID]: payload.expanded };
    case TOGGLE_INSURANCE_ASSET_CARD_TYPE:
      return { ...DEFAULT_STATE, [INSURANCE_ASSETS_FORM_ID]: payload.expanded };
    case ADD_PHYSICAL_ASSET_TYPE:
      return {
        [PHYSICAL_ASSETS_FORM_ID]: true,
        [FINANCIAL_ASSETS_FORM_ID]: false,
        [INSURANCE_ASSETS_FORM_ID]: false,
      };
    case ADD_FINANCIAL_ASSET_TYPE:
      return {
        [PHYSICAL_ASSETS_FORM_ID]: false,
        [FINANCIAL_ASSETS_FORM_ID]: true,
        [INSURANCE_ASSETS_FORM_ID]: false,
      };
    case ADD_INSURANCE_ASSET_TYPE:
      return {
        [PHYSICAL_ASSETS_FORM_ID]: false,
        [FINANCIAL_ASSETS_FORM_ID]: false,
        [INSURANCE_ASSETS_FORM_ID]: true,
      };
    default:
      return state;
  }
};

const toggleInheritanceAgeMilestoneVisibility = (
  state = {},
  { type, payload },
) => {
  switch (type) {
    case TOGGLE_INHERITANCE_AGE_MILESTONE_VISIBILITY_TYPE:
      return payload.milestonesVisibility;
    default:
      return state;
  }
};

const currentAllocationCardIndex = (state = {}, { type, payload }) => {
  switch (type) {
    case UPDATE_ALLOCATION_CARD_TYPE:
    case REMOVE_ALLOCATION_TYPE:
      return payload.allocationCardIndex;
    case CLOSE_MODAL_TYPE:
      return {};
    default:
      return state;
  }
};

const lastAllocationsValidState = (state = {}, { type, payload }) => {
  switch (type) {
    case UPDATE_LAST_ALLOCATIONS_VALID_STATE_TYPE:
      return payload.allocations;
    default:
      return state;
  }
};

const updatedAllocation = (state = {}, { type, payload }) => {
  switch (type) {
    case ALLOCATION_UPDATED_SUCCESSFULLY_TYPE:
      return payload.allocationName;
    case CLOSE_TOAST_TYPE:
      return {};
    default:
      return state;
  }
};

const removedAllocation = (state = {}, { type, payload }) => {
  switch (type) {
    case ALLOCATION_REMOVED_SUCCESSFULLY_TYPE:
      return payload.allocationName;
    case CLOSE_TOAST_TYPE:
      return {};
    default:
      return state;
  }
};

const pieChartAnimationKey = (state = 0, { type, payload }) => {
  switch (type) {
    case FORCE_PIE_CHART_ANIMATION_TYPE:
      return payload.count;
    default:
      return state;
  }
};

const lastSelectedExecutorData = (state = null, { type, payload }) => {
  switch (type) {
    case OPEN_MODAL_ADD_EXECUTORS_TYPE:
    case OPEN_MODAL_EDIT_EXECUTORS_TYPE:
    case REMOVE_EXECUTORS_TYPE:
    case ADD_EXECUTOR_TYPE:
      return payload?.executor;
    default:
      return state;
  }
};

const lastSelectedChildData = (state = null, { type, payload }) => {
  switch (type) {
    case MODAL_ADD_CHILD_FORM:
    case OPEN_MODAL_CHILDREN_FORM_TYPE:
      return payload?.children;
    case REMOVE_CHILD_TYPE:
    case ADD_CHILD_TYPE:
      return payload?.child;
    default:
      return state;
  }
};

const lastSelectedPetData = (state = null, { type, payload }) => {
  switch (type) {
    case OPEN_MODAL_PET_TYPE:
    case REMOVE_PET_TYPE:
    case UPDATE_PET_SUCCESSFULLY_TYPE:
      return payload?.pet;
    default:
      return state;
  }
};

const lastSelectedChildrenGuardianData = (state = null, { type, payload }) => {
  switch (type) {
    case UPDATE_CHILDREN_GUARDIAN_SUCCESSFULLY_TYPE:
    case OPEN_MODAL_CHILDREN_GUARDIANS_TYPE:
    case REMOVE_CHILDREN_GUARDIAN_TYPE: {
      return payload?.guardian;
    }
    default:
      return state;
  }
};

const lastSelectedPetGuardianData = (state = null, { type, payload }) => {
  switch (type) {
    case OPEN_MODAL_PET_GUARDIAN_TYPE:
    case REMOVE_PET_GUARDIAN_TYPE:
    case UPDATE_PET_GUARDIAN_SUCCESSFULLY_TYPE:
      return payload?.petGuardian;
    default:
      return state;
  }
};

const lastSelectedPropertyAttorneyData = (state = null, { type, payload }) => {
  switch (type) {
    case OPEN_MODAL_PROPERTY_ATTORNEY_TYPE:
    case REMOVE_PROPERTY_ATTORNEY_TYPE:
    case UPDATE_PROPERTY_ATTORNEY_SUCCESSFULLY_TYPE:
      return payload?.propertyAttorney;
    default:
      return state;
  }
};

const lastSelectedPersonalAttorneyData = (state = null, { type, payload }) => {
  switch (type) {
    case OPEN_MODAL_PERSONAL_ATTORNEY_TYPE:
    case REMOVE_PERSONAL_ATTORNEY_TYPE:
    case UPDATE_PERSONAL_ATTORNEY_SUCCESSFULLY_TYPE:
      return payload?.personalAttorney;
    default:
      return state;
  }
};

export default combineReducers({
  allocations: combineReducers({
    addingCharity,
    addingPerson,
    displayAllocationHelper,
    lastAddedPerson,
    lastAddedCharity,
    currentAllocationCardIndex,
    updatedAllocation,
    removedAllocation,
  }),
  notifyingAppointee,
  toggleAppointeeCard,
  loadingPlan,
  assetListCardExpanded,
  toggleInheritanceAgeMilestoneVisibility,
  lastAllocationsValidState,
  pieChartAnimationKey,
  lastSelectedExecutorData,
  lastSelectedChildData,
  lastSelectedPetData,
  lastSelectedChildrenGuardianData,
  lastSelectedPetGuardianData,
  lastSelectedPropertyAttorneyData,
  lastSelectedPersonalAttorneyData,
});
