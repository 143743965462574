import React, { useRef, useState } from "react";
import styled from "styled-components";
import Box from "@material-ui/core/Box";

import { animationSpeed, colorPalette, media } from "../../../config/Theme";
import Lightbox from "./Lightbox";
import { PrimaryButton, ButtonLink, ExternalButtonLink } from "../Button";
import { H2, H3, H4, Body } from "../Typography";
import CloseGreyIcon from "../icons/CloseGreyIcon";
import { useBackgroundLock, useKeyPress } from "../../../utilities/hooks";

const StyledModal = styled(Box)`
  z-index: 11;
  position: fixed;
  padding: 32px;
  overflow-y: auto; // allow modal to scroll when max height is hit
  border-radius: 5px;
  border: 1px solid ${colorPalette.willfulGrey2};
  width: 500px;
  background-color: ${colorPalette.white};
  animation-name: animation;
  animation-duration: ${animationSpeed.fast}ms;
  animation-timing-function: ease-out;

  @keyframes animation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  ${media.mobile} {
    top: 72px;
    max-height: calc(100% - 144px);
    width: calc(100% - 32px);
    padding: 16px;
    max-width: 500px;
    @keyframes animation {
      0% {
        top: 104px;
      }
    }
  }
  ${media.tablet} {
    top: 72px;
    max-height: calc(100% - 144px);
    @keyframes animation {
      0% {
        top: 104px;
      }
    }
  }
  ${media.desktop} {
    top: 120px;
    max-height: calc(100% - 240px);
    @keyframes animation {
      0% {
        top: 152px;
      }
    }
  }
`;

const StyledTooltipModal = styled(StyledModal)`
  width: 640px;
  ${media.mobile} {
    width: calc(100% - 20px);
    padding: 24px;
  }
`;

const StyledEmailPreviewModal = styled(StyledModal)`
  ${media.tablet} {
    width: 664px;
  }
`;

const CloseBox = styled(Box)`
  cursor: pointer;
  height: 24px;
`;

const TitleContainer = styled(Box)`
  max-width: 375px;
`;

const CloseButton = ({ onClick, closeRef }) => {
  return (
    <CloseBox
      onClick={onClick}
      role="button"
      aria-label="Close"
      tabIndex="0"
      ref={closeRef}
    >
      <CloseGreyIcon />
    </CloseBox>
  );
};

export const BaseModal = React.forwardRef(
  (
    {
      children,
      closeOnLightboxClick = false,
      onClose = () => {},
      InnerModalComponent = StyledModal,
    },
    ref,
  ) => {
    const lightboxEl = useRef(null);
    const innerModalRef = useRef(null);
    const [isFocus, setFocus] = useState(false);

    if (useKeyPress("Tab") && !isFocus) {
      setFocus(true);
      innerModalRef.current.focus();
    }

    const handleLightboxClick = (e) => {
      if (!closeOnLightboxClick) {
        return;
      }

      // Only close the lightbox if the target of the click was the lightbox itself, and not one of its children
      if (lightboxEl.current === e.target) {
        onClose();
      }
    };

    return (
      <Lightbox onClick={handleLightboxClick} ref={lightboxEl}>
        <InnerModalComponent
          ref={innerModalRef}
          className="modal-content"
          role="dialog"
          aria-label="Dialog"
          tabIndex="0"
        >
          {children}
        </InnerModalComponent>
      </Lightbox>
    );
  },
);

export const GenericModal = ({
  children,
  redirectPath,
  externalLink,
  isPrompt = false,
  translations,
  onClose,
  onSubmit,
  shouldCloseOnLightboxClick = false,
  shouldHideSubmitButton = false,
  shouldHideCloseButton = false,
}) => {
  const modalEl = useRef(null);
  useBackgroundLock(modalEl);

  const closeRef = useRef(null);
  const pressedEnter = useKeyPress("Enter");
  const pressedEscape = useKeyPress("Escape");

  const handleOnClose = () => {
    onClose();
  };

  if (!shouldHideCloseButton) {
    if (pressedEnter && document.activeElement === closeRef.current) {
      handleOnClose();
    }

    if (pressedEscape) {
      handleOnClose();
    }
  }

  const { title, body, secondBody = false } = translations;
  return (
    <BaseModal
      ref={modalEl}
      closeOnLightboxClick={shouldCloseOnLightboxClick}
      onClose={onClose}
    >
      <Box display="flex" justifyContent="space-between">
        <TitleContainer>
          <H2 align="left">{title}</H2>
        </TitleContainer>
        {!shouldHideCloseButton && (
          <CloseButton onClick={handleOnClose} closeRef={closeRef} />
        )}
      </Box>
      {children}
      {!children && (
        <>
          <Box pt={1}>
            <Body>{body}</Body>
          </Box>
          {secondBody && (
            <Box>
              <Body>{secondBody}</Body>
            </Box>
          )}
          <Box pt={shouldHideSubmitButton ? 0 : 1}>
            {externalLink ? (
              <ExternalButtonLink
                onClick={handleOnClose}
                url={externalLink}
                text={translations.button.submit}
                align="center"
                fullWidth
              />
            ) : redirectPath ? (
              <ButtonLink
                onClick={handleOnClose}
                to={redirectPath}
                displayArrowRight
                text={translations.button.submit}
                fullWidth
              />
            ) : !shouldHideSubmitButton ? (
              <PrimaryButton
                onClick={isPrompt ? onSubmit : handleOnClose}
                displayArrowRight={isPrompt}
                text={translations.button.submit}
                fullWidth
              />
            ) : null}
          </Box>
        </>
      )}
    </BaseModal>
  );
};

export const GenericTooltipModal = ({
  children,
  title,
  largeTitle,
  onClose,
}) => {
  const modalEl = useRef(null);
  useBackgroundLock(modalEl);

  const handleOnClose = () => {
    onClose();
  };

  if (useKeyPress("Escape")) {
    handleOnClose();
  }

  const closeRef = useRef(null);

  if (useKeyPress("Enter") && document.activeElement === closeRef.current) {
    handleOnClose();
  }

  return (
    <BaseModal
      ref={modalEl}
      closeOnLightboxClick
      onClose={onClose}
      InnerModalComponent={StyledTooltipModal}
    >
      <Box display="flex" justifyContent="space-between">
        <Box mb={1} width="90%">
          {largeTitle ? (
            <H2 align="left" component="h2">
              {title}
            </H2>
          ) : (
            <H4 align="left" component="h2">
              {title}
            </H4>
          )}
        </Box>
        <CloseButton onClick={handleOnClose} closeRef={closeRef} />
      </Box>
      <Box>{children}</Box>
    </BaseModal>
  );
};

export const GenericEmailPreviewModal = ({ children, title, onClose }) => {
  const modalEl = useRef(null);
  useBackgroundLock(modalEl);

  if (useKeyPress("Escape")) {
    onClose();
  }

  return (
    <BaseModal
      ref={modalEl}
      closeOnLightboxClick
      onClose={onClose}
      InnerModalComponent={StyledEmailPreviewModal}
    >
      <Box display="flex" justifyContent="space-between">
        <TitleContainer>
          <H3 align="left">{title}</H3>
        </TitleContainer>
        <CloseButton onClick={onClose} />
      </Box>
      <Box>{children}</Box>
    </BaseModal>
  );
};
