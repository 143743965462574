import {
  FULL_NAME_PATH,
  DOB_PATH,
  RESIDENCE_PATH,
  GENDER_PATH,
  SIGNUP_PATH,
  MARITAL_STATUS_PATH,
  SPOUSE_PATH,
  CHILDREN_PATH,
  CHILDREN_GUARDIANS_PATH,
  PETS_PATH,
  PET_GUARDIANS_PATH,
  PET_TRUST_PATH,
  DASHBOARD_PATH,
  GIFTS_PATH,
  DONATIONS_PATH,
  PRIMARY_BENEFICIARY_PATH,
  ALLOCATIONS_PATH,
  PREDECEASE_PATH,
  BACKUPS_PATH,
  INHERITANCE_PATH,
  EXECUTORS_PATH,
  WISHES_RESTING_PATH,
  ASHES_PATH,
  WISHES_CEREMONY_PATH,
  PROPERTY_ATTORNEYS_PATH,
  PERSONAL_ATTORNEYS_PATH,
  PAIN_MANAGE_PATH,
  LIFE_PROLONG_PATH,
  SPOUSE_GIFTS_PATH,
  SPOUSE_DONATIONS_PATH,
  SPOUSE_EXECUTORS_PATH,
  SPOUSE_PROPERTY_ATTORNEYS_PATH,
  SPOUSE_PERSONAL_ATTORNEYS_PATH,
  CHECKOUT_PATH,
  DOCUMENTS_PATH,
  SPOUSE_RESTING_PATH,
  SPOUSE_ASHES_PATH,
  SPOUSE_CEREMONY_PATH,
  SPOUSE_PAIN_MANAGE_PATH,
  SPOUSE_LIFE_PROLONG_PATH,
  PLANS_PATH,
  YOUR_ACCOUNT_PATH,
  THANK_YOU_REDIRECT_PATH,
  LOGIN_PATH,
  FORGOT_PASSWORD_PATH,
  RESET_PASSWORD_PATH,
  ACCOUNT_DELETED_PATH,
  RESET_SUCCESS_PATH,
  RECEIPT_PATH,
  REVIEW_YOUR_ARRANGEMENTS_PATH,
  REVIEW_YOUR_ESTATE_PATH,
  REVIEW_YOUR_FAMILY_PATH,
  REVIEW_SPOUSE_ESTATE_PATH,
  REVIEW_SPOUSE_ARRANGEMENTS_PATH,
  CLAIM_INVITE_PATH,
  INVALID_INVITE_PATH,
  REVIEW_ABOUT_YOU_PATH,
  INTENDED_USE_PATH,
  FAMILY_RECOMMENDED_PATH,
  PREMIUM_RECOMMENDED_PATH,
  WELCOME_PATH,
  OVERVIEW_HOW_IT_WORKS_PATH,
  OVERVIEW_SELECT_A_PLAN_PATH,
  OVERVIEW_SPOUSE_ESTATE_PATH,
  OVERVIEW_SPOUSE_ARRANGEMENTS_PATH,
  OVERVIEW_CONGRATULATIONS_PATH,
  APPOINTEES_PATH,
  ASSET_LIST_PATH,
  ASSET_LIST_OVERVIEW_PATH,
  WELCOME_BACK_PATH,
  ALLOCATIONS_GUIDANCE_PATH,
  BACKUPS_GUIDANCE_PATH,
  FAMILY_PLAN_GUIDANCE_PATH,
  ESSENTIALS_PLAN_GUIDANCE_PATH,
  PREMIUM_PLAN_GUIDANCE_PATH,
  IMPORTANT_NOTE_PATH,
  ESSENTIALS_PLAN_DOC_PREVIEW_PATH,
  PREMIUM_PLAN_DOC_PREVIEW_PATH,
} from "./routes";

import {
  FULL_NAME_FORM_ID,
  RESIDENCE_FORM_ID,
  DOB_FORM_ID,
  MARITAL_STATUS_FORM_ID,
  GENDER_FORM_ID,
  SPOUSE_FORM_ID,
  CHILDREN_FORM_ID,
  CHILDREN_GUARDIANS_FORM_ID,
  PETS_FORM_ID,
  PET_GUARDIANS_FORM_ID,
  PET_TRUST_FORM_ID,
  GIFTS_FORM_ID,
  DONATIONS_FORM_ID,
  PRIMARY_BENEFICIARY_FORM_ID,
  ALLOCATIONS_FORM_ID,
  PREDECEASE_FORM_ID,
  INHERITANCE_FORM_ID,
  BACKUPS_FORM_ID,
  EXECUTORS_FORM_ID,
  RESTING_FORM_ID,
  ASHES_FORM_ID,
  CEREMONY_FORM_ID,
  PERSONAL_ATTORNEYS_FORM_ID,
  PROPERTY_ATTORNEYS_FORM_ID,
  PAIN_MANAGE_FORM_ID,
  LIFE_PROLONG_FORM_ID,
  DOB_FIELD_ID,
  FULL_NAME_FIELD_ID,
  GIFTS_FIELD_ID,
  DONATIONS_FIELD_ID,
  RESTING_FIELD_ID,
  ASHES_FIELD_ID,
  CEREMONY_FIELD_ID,
  SPOUSE_IS_PROPERTY_ATTORNEY_FIELD_ID,
  PERSONAL_ATTORNEYS_FIELD_ID,
  PROPERTY_ATTORNEYS_FIELD_ID,
  SPOUSE_IS_PERSONAL_ATTORNEY_FIELD_ID,
  PAIN_MANAGE_FIELD_ID,
  LIFE_PROLONG_FIELD_ID,
  SPOUSE_IS_EXECUTOR_FIELD_ID,
  EXECUTORS_FIELD_ID,
  RESIDENCE_FIELD_ID,
  GENDER_FIELD_ID,
  MARITAL_STATUS_FIELD_ID,
  CHILDREN_FIELD_ID,
  CHILDREN_GUARDIANS_FIELD_ID,
  PET_GUARDIANS_FIELD_ID,
  PETS_FIELD_ID,
  PET_TRUST_AMOUNT_FIELD_ID,
  PRIMARY_BENEFICIARY_FIELD_ID,
  ALLOCATIONS_FIELD_ID,
  PREDECEASE_FIELD_ID,
  BACKUPS_FIELD_ID,
  INHERITANCE_AGES_FIELD_ID,
} from "./forms";

const ABOUT_YOU_KEY = "aboutYou";
export const aboutYouForms = [
  {
    id: RESIDENCE_FORM_ID,
    path: RESIDENCE_PATH,
    nextPath: FULL_NAME_PATH,
    fields: [
      {
        key: RESIDENCE_FIELD_ID,
      },
    ],
  },
  {
    id: FULL_NAME_FORM_ID,
    path: FULL_NAME_PATH,
    nextPath: DOB_PATH,
    fields: [
      {
        key: FULL_NAME_FIELD_ID,
      },
    ],
  },
  {
    id: DOB_FORM_ID,
    path: DOB_PATH,
    nextPath: GENDER_PATH,
    fields: [
      {
        key: DOB_FIELD_ID,
      },
    ],
  },
  {
    id: GENDER_FORM_ID,
    path: GENDER_PATH,
    nextPath: DASHBOARD_PATH,
    fields: [
      {
        key: GENDER_FIELD_ID,
      },
    ],
  },
].map((form, index) => ({
  ...form,
  section: ABOUT_YOU_KEY,
  overviewPath: OVERVIEW_HOW_IT_WORKS_PATH,
  pageNumber: index + 1,
}));

const YOUR_FAMILY_KEY = "yourFamily";
export const yourFamilyForms = [
  {
    id: MARITAL_STATUS_FORM_ID,
    path: MARITAL_STATUS_PATH,
    nextPath: SPOUSE_PATH,
    fields: [
      {
        key: MARITAL_STATUS_FIELD_ID,
      },
    ],
  },
  {
    id: SPOUSE_FORM_ID,
    path: SPOUSE_PATH,
    nextPath: CHILDREN_PATH,
    fields: [
      {
        key: FULL_NAME_FIELD_ID,
      },
    ],
  },
  {
    id: CHILDREN_FORM_ID,
    path: CHILDREN_PATH,
    nextPath: CHILDREN_GUARDIANS_PATH,
    fields: [
      {
        key: CHILDREN_FIELD_ID,
      },
    ],
  },
  {
    id: CHILDREN_GUARDIANS_FORM_ID,
    path: CHILDREN_GUARDIANS_PATH,
    nextPath: PETS_PATH,
    fields: [
      {
        key: CHILDREN_GUARDIANS_FIELD_ID,
      },
    ],
  },
  {
    id: PETS_FORM_ID,
    path: PETS_PATH,
    nextPath: PET_GUARDIANS_PATH,
    fields: [
      {
        key: PETS_FIELD_ID,
      },
    ],
  },
  {
    id: PET_GUARDIANS_FORM_ID,
    path: PET_GUARDIANS_PATH,
    nextPath: PET_TRUST_PATH,
    fields: [
      {
        key: PET_GUARDIANS_FIELD_ID,
      },
    ],
  },
  {
    id: PET_TRUST_FORM_ID,
    path: PET_TRUST_PATH,
    nextPath: DASHBOARD_PATH,
    fields: [
      {
        key: PET_TRUST_AMOUNT_FIELD_ID,
      },
    ],
  },
].map((form, index) => ({
  ...form,
  section: YOUR_FAMILY_KEY,
  overviewPath: MARITAL_STATUS_PATH,
  pageNumber: index + 1,
}));

const YOUR_ESTATE_KEY = "yourEstate";
export const yourEstateForms = [
  {
    id: GIFTS_FORM_ID,
    path: GIFTS_PATH,
    nextPath: DONATIONS_PATH,
    fields: [
      {
        key: GIFTS_FIELD_ID,
      },
    ],
  },
  {
    id: DONATIONS_FORM_ID,
    path: DONATIONS_PATH,
    nextPath: ALLOCATIONS_GUIDANCE_PATH,
    fields: [
      {
        key: DONATIONS_FIELD_ID,
      },
    ],
  },
  {
    id: PRIMARY_BENEFICIARY_FORM_ID,
    path: ALLOCATIONS_GUIDANCE_PATH,
    nextPath: ALLOCATIONS_PATH,
    fields: [
      {
        key: PRIMARY_BENEFICIARY_FIELD_ID,
      },
    ],
  },
  {
    id: ALLOCATIONS_FORM_ID,
    path: ALLOCATIONS_PATH,
    nextPath: PREDECEASE_PATH,
    fields: [
      {
        key: ALLOCATIONS_FIELD_ID,
      },
    ],
  },
  {
    id: PREDECEASE_FORM_ID,
    path: PREDECEASE_PATH,
    nextPath: BACKUPS_PATH,
    fields: [
      {
        key: PREDECEASE_FIELD_ID,
      },
    ],
  },
  {
    id: BACKUPS_FORM_ID,
    path: BACKUPS_PATH,
    nextPath: INHERITANCE_PATH,
    fields: [
      {
        key: BACKUPS_FIELD_ID,
      },
    ],
  },
  {
    id: INHERITANCE_FORM_ID,
    path: INHERITANCE_PATH,
    nextPath: DASHBOARD_PATH,
    fields: [
      {
        key: INHERITANCE_AGES_FIELD_ID,
      },
    ],
  },
].map((form, index) => ({
  ...form,
  section: YOUR_ESTATE_KEY,
  overviewPath: GIFTS_PATH,
  pageNumber: index + 1,
}));

const YOUR_ARRANGEMENTS_KEY = "yourArrangements";
export const yourArrangementsForms = [
  {
    id: EXECUTORS_FORM_ID,
    path: EXECUTORS_PATH,
    nextPath: WISHES_RESTING_PATH,
    fields: [
      {
        key: SPOUSE_IS_EXECUTOR_FIELD_ID,
      },
      {
        key: EXECUTORS_FIELD_ID,
      },
    ],
  },
  {
    id: RESTING_FORM_ID,
    path: WISHES_RESTING_PATH,
    nextPath: ASHES_PATH,
    fields: [
      {
        key: RESTING_FIELD_ID,
      },
    ],
  },
  {
    id: ASHES_FORM_ID,
    path: ASHES_PATH,
    nextPath: WISHES_CEREMONY_PATH,
    fields: [
      {
        key: ASHES_FIELD_ID,
      },
    ],
  },
  {
    id: CEREMONY_FORM_ID,
    path: WISHES_CEREMONY_PATH,
    nextPath: PROPERTY_ATTORNEYS_PATH,
    fields: [
      {
        key: CEREMONY_FIELD_ID,
      },
    ],
  },
  {
    id: PROPERTY_ATTORNEYS_FORM_ID,
    path: PROPERTY_ATTORNEYS_PATH,
    nextPath: PERSONAL_ATTORNEYS_PATH,
    fields: [
      {
        key: SPOUSE_IS_PROPERTY_ATTORNEY_FIELD_ID,
      },
      {
        key: PROPERTY_ATTORNEYS_FIELD_ID,
      },
    ],
  },
  {
    id: PERSONAL_ATTORNEYS_FORM_ID,
    path: PERSONAL_ATTORNEYS_PATH,
    nextPath: PAIN_MANAGE_PATH,
    fields: [
      {
        key: SPOUSE_IS_PERSONAL_ATTORNEY_FIELD_ID,
      },
      {
        key: PERSONAL_ATTORNEYS_FIELD_ID,
      },
    ],
  },
  {
    id: PAIN_MANAGE_FORM_ID,
    path: PAIN_MANAGE_PATH,
    nextPath: LIFE_PROLONG_PATH,
    fields: [
      {
        key: PAIN_MANAGE_FIELD_ID,
      },
    ],
  },
  {
    id: LIFE_PROLONG_FORM_ID,
    path: LIFE_PROLONG_PATH,
    nextPath: DASHBOARD_PATH,
    fields: [
      {
        key: LIFE_PROLONG_FIELD_ID,
      },
    ],
  },
].map((form, index) => ({
  ...form,
  section: YOUR_ARRANGEMENTS_KEY,
  overviewPath: EXECUTORS_PATH,
  pageNumber: index + 1,
}));

const SPOUSE_ESTATE_KEY = "spousesEstate";
export const spouseEstateForms = [
  {
    id: GIFTS_FORM_ID,
    path: SPOUSE_GIFTS_PATH,
    nextPath: SPOUSE_DONATIONS_PATH,
    fields: [{ key: GIFTS_FIELD_ID }],
  },
  {
    id: DONATIONS_FORM_ID,
    path: SPOUSE_DONATIONS_PATH,
    nextPath: DASHBOARD_PATH,
    fields: [
      {
        key: DONATIONS_FIELD_ID,
      },
    ],
  },
].map((form, index) => ({
  ...form,
  section: SPOUSE_ESTATE_KEY,
  overviewPath: OVERVIEW_SPOUSE_ESTATE_PATH,
  pageNumber: index + 1,
}));

const SPOUSE_ARRANGEMENTS_KEY = "spousesArrangements";

export const spouseArrangementsForms = [
  {
    id: EXECUTORS_FORM_ID,
    path: SPOUSE_EXECUTORS_PATH,
    nextPath: SPOUSE_RESTING_PATH,
    fields: [
      {
        key: SPOUSE_IS_EXECUTOR_FIELD_ID,
      },
      {
        key: EXECUTORS_FIELD_ID,
      },
    ],
  },
  {
    id: RESTING_FORM_ID,
    path: SPOUSE_RESTING_PATH,
    nextPath: SPOUSE_ASHES_PATH,
    fields: [
      {
        key: RESTING_FIELD_ID,
      },
    ],
  },
  {
    id: ASHES_FORM_ID,
    path: SPOUSE_ASHES_PATH,
    nextPath: SPOUSE_CEREMONY_PATH,
    fields: [
      {
        key: ASHES_FIELD_ID,
      },
    ],
  },
  {
    id: CEREMONY_FORM_ID,
    path: SPOUSE_CEREMONY_PATH,
    nextPath: SPOUSE_PROPERTY_ATTORNEYS_PATH,
    fields: [
      {
        key: CEREMONY_FIELD_ID,
      },
    ],
  },
  {
    id: PROPERTY_ATTORNEYS_FORM_ID,
    path: SPOUSE_PROPERTY_ATTORNEYS_PATH,
    nextPath: SPOUSE_PERSONAL_ATTORNEYS_PATH,
    fields: [
      {
        key: SPOUSE_IS_PROPERTY_ATTORNEY_FIELD_ID,
      },
      {
        key: PROPERTY_ATTORNEYS_FIELD_ID,
      },
    ],
  },
  {
    id: PERSONAL_ATTORNEYS_FORM_ID,
    path: SPOUSE_PERSONAL_ATTORNEYS_PATH,
    nextPath: SPOUSE_LIFE_PROLONG_PATH,
    fields: [
      {
        key: SPOUSE_IS_PERSONAL_ATTORNEY_FIELD_ID,
      },
      {
        key: PERSONAL_ATTORNEYS_FIELD_ID,
      },
    ],
  },
  {
    id: LIFE_PROLONG_FORM_ID,
    path: SPOUSE_LIFE_PROLONG_PATH,
    nextPath: SPOUSE_PAIN_MANAGE_PATH,
    fields: [
      {
        key: LIFE_PROLONG_FIELD_ID,
      },
    ],
  },
  {
    id: PAIN_MANAGE_FORM_ID,
    path: SPOUSE_PAIN_MANAGE_PATH,
    nextPath: DASHBOARD_PATH,
    fields: [
      {
        key: PAIN_MANAGE_FIELD_ID,
      },
    ],
  },
].map((form, index) => ({
  ...form,
  section: SPOUSE_ARRANGEMENTS_KEY,
  overviewPath: OVERVIEW_SPOUSE_ARRANGEMENTS_PATH,
  pageNumber: index + 1,
}));

const servicePages = [
  {
    id: "payment",
    path: CHECKOUT_PATH,
    overviewPath: OVERVIEW_CONGRATULATIONS_PATH,
  },
  {
    id: "receipt",
    path: RECEIPT_PATH,
  },
  {
    id: "documents",
    path: DOCUMENTS_PATH,
    overviewPath: OVERVIEW_CONGRATULATIONS_PATH,
  },
  {
    id: "products",
    path: PLANS_PATH,
    overviewPath: OVERVIEW_SELECT_A_PLAN_PATH,
  },
  {
    id: "peopleQuantityIntent",
    path: INTENDED_USE_PATH,
    overviewPath: OVERVIEW_SELECT_A_PLAN_PATH,
  },
];

// TODO: rename _SECTIONS into _PATHS
export const ABOUT_YOU_SECTIONS = [
  RESIDENCE_PATH,
  FULL_NAME_PATH,
  DOB_PATH,
  GENDER_PATH,
  REVIEW_ABOUT_YOU_PATH,
  INTENDED_USE_PATH,
  FAMILY_RECOMMENDED_PATH,
  PREMIUM_RECOMMENDED_PATH,
];
export const YOUR_FAMILY_SECTIONS = [
  MARITAL_STATUS_PATH,
  SPOUSE_PATH,
  CHILDREN_PATH,
  CHILDREN_GUARDIANS_PATH,
  PETS_PATH,
  PET_GUARDIANS_PATH,
  PET_TRUST_PATH,
  REVIEW_YOUR_FAMILY_PATH,
];
export const YOUR_ESTATE_SECTIONS = [
  GIFTS_PATH,
  DONATIONS_PATH,
  PRIMARY_BENEFICIARY_PATH,
  ALLOCATIONS_GUIDANCE_PATH,
  ALLOCATIONS_PATH,
  PREDECEASE_PATH,
  BACKUPS_PATH,
  BACKUPS_GUIDANCE_PATH,
  INHERITANCE_PATH,
  REVIEW_YOUR_ESTATE_PATH,
];
export const YOUR_ARRANGEMENTS_SECTIONS = [
  EXECUTORS_PATH,
  WISHES_RESTING_PATH,
  ASHES_PATH,
  WISHES_CEREMONY_PATH,
  PROPERTY_ATTORNEYS_PATH,
  PERSONAL_ATTORNEYS_PATH,
  PAIN_MANAGE_PATH,
  LIFE_PROLONG_PATH,
  REVIEW_YOUR_ARRANGEMENTS_PATH,
];
export const SPOUSE_ESTATE_SECTIONS = [
  SPOUSE_GIFTS_PATH,
  SPOUSE_DONATIONS_PATH,
  REVIEW_SPOUSE_ESTATE_PATH,
];
export const SPOUSE_ARRANGEMENTS_SECTIONS = [
  SPOUSE_EXECUTORS_PATH,
  SPOUSE_PROPERTY_ATTORNEYS_PATH,
  SPOUSE_PERSONAL_ATTORNEYS_PATH,
  SPOUSE_RESTING_PATH,
  SPOUSE_ASHES_PATH,
  SPOUSE_CEREMONY_PATH,
  SPOUSE_PAIN_MANAGE_PATH,
  SPOUSE_LIFE_PROLONG_PATH,
  REVIEW_SPOUSE_ARRANGEMENTS_PATH,
];

export const spousePaths = [
  SPOUSE_GIFTS_PATH,
  SPOUSE_DONATIONS_PATH,
  SPOUSE_EXECUTORS_PATH,
  SPOUSE_RESTING_PATH,
  SPOUSE_ASHES_PATH,
  SPOUSE_CEREMONY_PATH,
  SPOUSE_PROPERTY_ATTORNEYS_PATH,
  SPOUSE_PERSONAL_ATTORNEYS_PATH,
  SPOUSE_PAIN_MANAGE_PATH,
  SPOUSE_LIFE_PROLONG_PATH,
];

export const overviewPaths = [
  OVERVIEW_HOW_IT_WORKS_PATH,
  OVERVIEW_SELECT_A_PLAN_PATH,
  OVERVIEW_SPOUSE_ESTATE_PATH,
  OVERVIEW_SPOUSE_ARRANGEMENTS_PATH,
  OVERVIEW_CONGRATULATIONS_PATH,
  ASSET_LIST_OVERVIEW_PATH,
];

const alignmentMap = [
  {
    id: "residualBeneficiaries",
    path: ALLOCATIONS_PATH,
    section: YOUR_ESTATE_KEY,
    overviewPath: EXECUTORS_PATH,
  },
  {
    id: "wishes",
    path: WISHES_RESTING_PATH,
    section: YOUR_ARRANGEMENTS_KEY,
    overviewPath: EXECUTORS_PATH,
  },
  {
    id: "healthcare",
    path: LIFE_PROLONG_PATH,
    section: YOUR_ARRANGEMENTS_KEY,
    overviewPath: EXECUTORS_PATH,
  },
  {
    id: "spouseGifts",
    path: SPOUSE_GIFTS_PATH,
    section: SPOUSE_ESTATE_KEY,
    overviewPath: OVERVIEW_SPOUSE_ESTATE_PATH,
  },
  {
    id: "spouseDonations",
    path: SPOUSE_DONATIONS_PATH,
    section: SPOUSE_ESTATE_KEY,
    overviewPath: OVERVIEW_SPOUSE_ARRANGEMENTS_PATH,
  },
  {
    id: "spouseExecutors",
    path: SPOUSE_EXECUTORS_PATH,
    section: SPOUSE_ARRANGEMENTS_KEY,
    overviewPath: OVERVIEW_SPOUSE_ARRANGEMENTS_PATH,
  },
  {
    id: "spouseWishes",
    path: SPOUSE_RESTING_PATH,
    section: SPOUSE_ARRANGEMENTS_KEY,
    overviewPath: OVERVIEW_SPOUSE_ARRANGEMENTS_PATH,
  },
  {
    id: "spousePropertyAttorneys",
    path: SPOUSE_PROPERTY_ATTORNEYS_PATH,
    section: SPOUSE_ARRANGEMENTS_KEY,
    overviewPath: OVERVIEW_SPOUSE_ARRANGEMENTS_PATH,
  },
  {
    id: "spousePersonalAttorneys",
    path: SPOUSE_PERSONAL_ATTORNEYS_PATH,
    section: SPOUSE_ARRANGEMENTS_KEY,
    overviewPath: OVERVIEW_SPOUSE_ARRANGEMENTS_PATH,
  },
  {
    id: "spouseHealthcare",
    path: SPOUSE_LIFE_PROLONG_PATH,
    section: SPOUSE_ARRANGEMENTS_KEY,
    overviewPath: OVERVIEW_SPOUSE_ARRANGEMENTS_PATH,
  },
];

export const navigationMap = [
  ...aboutYouForms,
  ...yourFamilyForms,
  ...yourEstateForms,
  ...yourArrangementsForms,
  ...spouseEstateForms,
  ...spouseArrangementsForms,
  ...servicePages,
  ...alignmentMap,
];

export const premiumForms = [
  PERSONAL_ATTORNEYS_FORM_ID,
  PROPERTY_ATTORNEYS_FORM_ID,
  PAIN_MANAGE_FORM_ID,
  LIFE_PROLONG_FORM_ID,
];

export const hasSpouseFields = [
  SPOUSE_IS_EXECUTOR_FIELD_ID,
  SPOUSE_IS_PERSONAL_ATTORNEY_FIELD_ID,
  SPOUSE_IS_PROPERTY_ATTORNEY_FIELD_ID,
];

export const footerRoutes = [
  PLANS_PATH,
  SIGNUP_PATH,
  CLAIM_INVITE_PATH,
  INVALID_INVITE_PATH,
  CHECKOUT_PATH,
  DOCUMENTS_PATH,
  YOUR_ACCOUNT_PATH,
  DASHBOARD_PATH,
  THANK_YOU_REDIRECT_PATH,
  LOGIN_PATH,
  SIGNUP_PATH,
  FORGOT_PASSWORD_PATH,
  RESET_PASSWORD_PATH,
  RESET_SUCCESS_PATH,
  ACCOUNT_DELETED_PATH,
  RECEIPT_PATH,
  WELCOME_PATH,
  APPOINTEES_PATH,
  ASSET_LIST_PATH,
  ASSET_LIST_OVERVIEW_PATH,
  WELCOME_BACK_PATH,
  IMPORTANT_NOTE_PATH,
  INTENDED_USE_PATH,
  PREMIUM_RECOMMENDED_PATH,
  FAMILY_RECOMMENDED_PATH,
  ...overviewPaths,
];

export const pageWrapperRoutes = [
  SIGNUP_PATH,
  CLAIM_INVITE_PATH,
  LOGIN_PATH,
  WELCOME_PATH,
  WELCOME_BACK_PATH,
  ALLOCATIONS_GUIDANCE_PATH,
  BACKUPS_GUIDANCE_PATH,
  FAMILY_PLAN_GUIDANCE_PATH,
  ESSENTIALS_PLAN_GUIDANCE_PATH,
  PREMIUM_PLAN_GUIDANCE_PATH,
  IMPORTANT_NOTE_PATH,
  ESSENTIALS_PLAN_DOC_PREVIEW_PATH,
  PREMIUM_PLAN_DOC_PREVIEW_PATH,
  ...overviewPaths,
];

export const hideHeaderRoutes = [CHECKOUT_PATH];

export const SPA_SAFE_LINK_CLASS = "spa-safe-link";
