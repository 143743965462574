import R from "ramda";
import { select, takeEvery, call, put } from "redux-saga/effects";
import {
  initialize,
  change,
  getFormValues,
  startSubmit,
  stopSubmit,
} from "redux-form";

import {
  FETCH_PROPERTY_ATTORNEYS_TYPE,
  OPEN_MODAL_PROPERTY_ATTORNEY_TYPE,
  propertyAttorneyUpdatedSuccessfully,
  REMOVE_PROPERTY_ATTORNEY_TYPE,
  UPDATE_PROPERTY_ATTORNEYS_TYPE,
} from "../actions/property-attorneys";
import { submitFormRequest } from "./forms";
import { fetchApiData } from "./requests";
import { fetchSuccess } from "../actions/requests";
import {
  getPropertyAttorneys,
  submitPropertyAttorneys,
} from "../api/property-attorneys";
import { selectHasSpouse } from "../selectors/spouse";
import {
  FIRST_NAME_FIELD_ID,
  LAST_NAME_FIELD_ID,
  MIDDLE_NAME_FIELD_ID,
  PROPERTY_ATTORNEY_MODAL_FORM_ID,
  PROPERTY_ATTORNEYS_FIELD_ID,
  PROPERTY_ATTORNEYS_FORM_ID,
} from "../constants/forms";
import { closeToast, displayToast } from "../actions/toast";
import {
  ADD_PROPERTY_ATTORNEY_SUCCESS,
  EDIT_PROPERTY_ATTORNEY_SUCCESS,
  REMOVE_PROPERTY_ATTORNEY_SUCCESS,
} from "../constants/toasts";
import { closeModal, displayModal } from "../actions/modal";
import {
  MODAL_ADD_PROPERTY_ATTORNEY,
  MODAL_EDIT_PROPERTY_ATTORNEY,
} from "../constants/modal";
import { selectLastSelectedPropertyAttorneyData } from "../selectors/property-attorneys";

function* fetchPropertyAttorneys() {
  const { propertyAttorneys, spouseIsPropertyAttorney } = yield call(
    fetchApiData,
    {
      apiCall: getPropertyAttorneys,
      formId: PROPERTY_ATTORNEYS_FORM_ID,
    },
  );

  const hasSpouse = yield select(selectHasSpouse);
  const defaultSpouseIsPropertyAttorneyValue =
    hasSpouse && spouseIsPropertyAttorney === null
      ? true
      : spouseIsPropertyAttorney;

  yield put(
    initialize(PROPERTY_ATTORNEYS_FORM_ID, {
      propertyAttorneys,
      spouseIsPropertyAttorney: defaultSpouseIsPropertyAttorneyValue,
    }),
  );
  yield put(fetchSuccess(PROPERTY_ATTORNEYS_FORM_ID));
}

function* updatePropertyAttorneys() {
  yield call(submitFormRequest, {
    apiCall: submitPropertyAttorneys,
    formId: PROPERTY_ATTORNEYS_FORM_ID,
  });
}

function* handleOpenModalPropertyAttorney({ payload }) {
  yield put(closeToast());
  const propertyAttorney = R.propOr(null, "propertyAttorney")(payload);
  if (propertyAttorney) {
    yield put(
      change(
        PROPERTY_ATTORNEY_MODAL_FORM_ID,
        PROPERTY_ATTORNEYS_FIELD_ID,
        propertyAttorney,
      ),
    );
    return yield put(displayModal(MODAL_EDIT_PROPERTY_ATTORNEY));
  }
  return yield put(displayModal(MODAL_ADD_PROPERTY_ATTORNEY));
}

export function* handleAddOrEditPropertyAttorney(isEditing = false) {
  yield put(startSubmit(PROPERTY_ATTORNEY_MODAL_FORM_ID));
  try {
    const formData = yield select(
      getFormValues(PROPERTY_ATTORNEY_MODAL_FORM_ID),
    );
    const { propertyAttorneys } = formData;
    const newPropertyAttorney = {
      [FIRST_NAME_FIELD_ID]: propertyAttorneys.firstName || null,
      [MIDDLE_NAME_FIELD_ID]: propertyAttorneys.middleName || null,
      [LAST_NAME_FIELD_ID]: propertyAttorneys.lastName || null,
    };
    const updatedpropertyAttorneys = isEditing
      ? yield call(editPropertyAttorney, newPropertyAttorney)
      : yield call(addPropertyAttorney, newPropertyAttorney);
    yield put(
      change(
        PROPERTY_ATTORNEYS_FORM_ID,
        PROPERTY_ATTORNEYS_FIELD_ID,
        updatedpropertyAttorneys,
      ),
    );
    yield put(propertyAttorneyUpdatedSuccessfully(newPropertyAttorney));
    yield put(closeModal());
    yield put(
      displayToast(
        isEditing
          ? EDIT_PROPERTY_ATTORNEY_SUCCESS
          : ADD_PROPERTY_ATTORNEY_SUCCESS,
      ),
    );
  } catch (error) {
    yield put(stopSubmit(PROPERTY_ATTORNEY_MODAL_FORM_ID, error.formErrors));
  }
}

function* addPropertyAttorney(newPropertyAttorney) {
  const formData = yield select(getFormValues(PROPERTY_ATTORNEYS_FORM_ID));
  const { propertyAttorneys } = formData;
  return [...propertyAttorneys, newPropertyAttorney];
}

function* editPropertyAttorney(newPropertyAttorney) {
  const lastSelectedPropertyAttorney = yield select(
    selectLastSelectedPropertyAttorneyData,
  );
  const formData = yield select(getFormValues(PROPERTY_ATTORNEYS_FORM_ID));
  const { propertyAttorneys } = formData;
  return propertyAttorneys.map((propertyAttorney, index) => {
    if (index === lastSelectedPropertyAttorney.index) {
      return {
        ...newPropertyAttorney,
        id: propertyAttorney.id,
      };
    }
    return propertyAttorney;
  });
}

export function* handleRemovePropertyAttorney() {
  yield put(closeToast());
  yield put(displayToast(REMOVE_PROPERTY_ATTORNEY_SUCCESS));
}

export function* watchFetchPropertyAttorneys() {
  yield takeEvery(FETCH_PROPERTY_ATTORNEYS_TYPE, fetchPropertyAttorneys);
}

export function* watchUpdatePropertyAttorneys() {
  yield takeEvery(UPDATE_PROPERTY_ATTORNEYS_TYPE, updatePropertyAttorneys);
}

export function* watchOpenModalPropertyAttorney() {
  yield takeEvery(
    OPEN_MODAL_PROPERTY_ATTORNEY_TYPE,
    handleOpenModalPropertyAttorney,
  );
}

export function* watchRemovePropertyAttorney() {
  yield takeEvery(REMOVE_PROPERTY_ATTORNEY_TYPE, handleRemovePropertyAttorney);
}
