import R from "ramda";
import { createStructuredSelector, createSelector } from "reselect";
import { getLocation } from "connected-react-router";
import { selectIsLoadingById } from "./requests";

import { selectIsFrenchSelected } from "./language";
import { selectTranslations } from "./translations";
import { selectIsAboutYouComplete } from "./about-you";
import {
  SIGNUP_FORM_ID,
  LOGIN_FORM_ID,
  FORGOT_PASSWORD_FORM_ID,
  RESET_PASSWORD_FORM_ID,
} from "../constants/forms";
import {
  UTM_MEDIUM_AFFILIATE,
  UTM_MEDIUM_AWARENESS,
  UTM_MEDIUM_CPC,
  UTM_MEDIUM_DISPLAY,
  UTM_MEDIUM_EMAIL,
  UTM_MEDIUM_PARTNER,
  UTM_MEDIUM_REFERRAL,
  UTM_MEDIUM_SOCIAL,
} from "../constants/utmMedium";
import { getTranspiledMessage } from "../utilities/helpers";

const selectIsLoggedIn = R.pathOr(false, ["auth", "loggedIn"]);
const selectRedirectTo = R.pathOr(null, ["auth", "redirectTo"]);

export const selectAuthHeaders = createSelector(
  R.pathOr("", ["auth", "accessToken"]),
  R.pathOr("", ["auth", "client"]),
  R.pathOr("", ["auth", "uid"]),
  (accessToken, client, uid) => ({ "access-token": accessToken, client, uid }),
);
export const selectInviteToken = R.pathOr("", ["auth", "inviteToken"]);

export const selectUserId = R.pathOr("", ["auth", "uid"]);

export const selectUserEmail = R.pathOr("", ["auth", "uid"]);

export const selectRailsUserId = R.pathOr("", ["auth", "id"]);

export const selectTokenUpdatedAt = R.pathOr("", ["auth", "tokenUpdatedAt"]);

export const selectAnalyticsSessionId = R.pathOr("", [
  "auth",
  "analyticsSessionId",
]);

export const selectUserAuthMethod = R.pathOr("", ["auth", "authMethod"]);

export const selectHasUserAgreedToTos = R.pathOr(false, [
  "auth",
  "agreedToTos",
]);

export const selectIsGoogleSso = R.pathOr(false, ["auth", "isGoogleSso"]);
export const selectIsPasswordSet = R.pathOr(false, ["auth", "isPasswordSet"]);

export const selectEmailFromQueryParams = createSelector(
  getLocation,
  ({ query }) => {
    const { email } = query;
    return email || "";
  },
);

export const selectUtmParamsMatch = (medium, source) =>
  createSelector(getLocation, ({ query }) => {
    const { utm_medium, utm_source } = query;

    if (!utm_medium || !utm_source) {
      return false;
    }

    return (
      utm_medium.toLowerCase() === medium && utm_source.toLowerCase() === source
    );
  });

const selectIsFacebookReferral = createSelector(
  getLocation,
  selectIsFrenchSelected,
  ({ query }, isFrenchSelected) => {
    // Quick escape if the user has selected French,
    // This campaign is only for English users.
    if (isFrenchSelected) {
      return false;
    }

    const { utm_medium, utm_source } = query;

    if (!utm_medium || !utm_source) {
      return false;
    }

    return (
      utm_medium.toLowerCase() === "cpc" &&
      utm_source.toLowerCase() === "facebook"
    );
  },
);

export const selectMercerPartnerReferral = createSelector(
  getLocation,
  ({ query }) => {
    const { utm_medium, utm_source } = query;

    if (!utm_medium || !utm_source) {
      return false;
    }

    return utm_medium.toLowerCase() === "partner" &&
      utm_source.toLowerCase().startsWith("mercer-")
      ? utm_source.toLowerCase()
      : false;
  },
);

export const selectIsSunlifeReferral = selectUtmParamsMatch(
  "partner",
  "sunlife",
);

export const selectIsKraftHeinzReferral = selectUtmParamsMatch(
  "partner",
  "kraftheinz",
);

export const selectIsScotiaBankClientsReferral = selectUtmParamsMatch(
  "partner",
  "scotiabank-clients",
);

export const selectIsScotiaBankEmployeesReferral = selectUtmParamsMatch(
  "partner",
  "scotiabank-emp",
);

export const selectIsCIBCEmpReferral = selectUtmParamsMatch(
  "partner",
  "cibc-emp",
);

export const selectIsCIBCClientsReferral = selectUtmParamsMatch(
  "partner",
  "cibc-clients",
);

export const selectIsArborReferral = selectUtmParamsMatch(
  "partner-volume",
  "arbor",
);

export const selectIsAllstateEmpReferral = selectUtmParamsMatch(
  "partner",
  "allstate-emp",
);

export const selectIsAllstateClientsReferral = selectUtmParamsMatch(
  "partner",
  "allstate-clients",
);

export const selectIsArborMemorialReferral = selectUtmParamsMatch(
  "partner",
  "arbor-retail",
);

export const selectIsMercerCascadesPartnerReferral = selectUtmParamsMatch(
  "partner",
  "mercer-cascades",
);

export const selectIsMercerCRHCanadaGroupIncPartnerReferral = selectUtmParamsMatch(
  "partner",
  "mercer-crhcanadagroupinc",
);

export const selectIsMercerMeridianReferral = selectUtmParamsMatch(
  "partner",
  "mercer-meridian",
);

export const selectIsMercerBDCanadaReferral = selectUtmParamsMatch(
  "partner",
  "mercer-bdcanada",
);

export const selectIsMercerTundraReferral = selectUtmParamsMatch(
  "partner",
  "mercer-tundra",
);

export const selectIsNextdoorReferral = selectUtmParamsMatch(
  "partner",
  "mawm-nextdoor",
);
export const selectIsParentLifeNetworkReferral = selectUtmParamsMatch(
  "cpc",
  "mawm-pln",
);

export const selectIsInnovationCreditUnionReferral = selectUtmParamsMatch(
  "partner",
  "ifcu",
);

export const selectIsInnovationCreditUnionClientsReferral = selectUtmParamsMatch(
  "partner",
  "innovationfcu",
);

export const selectIsMercerHLFoodsReferral = selectUtmParamsMatch(
  "partner",
  "mercer-hlfoods",
);

export const selectIsMercerIQVIAReferral = selectUtmParamsMatch(
  "partner",
  "mercer-iqvia",
);

export const selectIsMercerLoccitaneCanadaReferral = selectUtmParamsMatch(
  "partner",
  "loccitane-canada",
);

export const selectIsGrowSurfReferral = selectUtmParamsMatch(
  "referral",
  "growsurf",
);

export const selectIsAmazonBenefitsReferral = selectUtmParamsMatch(
  "partner",
  "mercer-amazon",
);

export const selectIsEmbarkReferral = selectUtmParamsMatch("partner", "embark");

const mawmPartnerListTemplateOne = {
  [UTM_MEDIUM_REFERRAL]: ["mawm-willful-post"],
};
const mawmPartnerListTemplateTwo = {
  [UTM_MEDIUM_PARTNER]: ["mawm-community"],
  [UTM_MEDIUM_SOCIAL]: ["mawm-tiktok"],
  [UTM_MEDIUM_DISPLAY]: ["mawm-youtube"],
  [UTM_MEDIUM_REFERRAL]: ["mawm-willful-share", "mawm-press"],
  [UTM_MEDIUM_AWARENESS]: ["mawm-tv-linear", "mawm-tv-streaming"],
  [UTM_MEDIUM_CPC]: ["mawm-tiktok-cpc", "mawm-google", "mawm-facebook"],
  [UTM_MEDIUM_EMAIL]: [
    "mawm-crm-signup",
    "mawm-willful-cs",
    "mawm-willful-crm",
  ],
};
const mawmPartnerListTemplateThree = {
  [UTM_MEDIUM_AFFILIATE]: [
    { utmSource: "mawm-money", partnerName: "Money.ca", useLimit: 1000 },
  ],
  [UTM_MEDIUM_CPC]: [
    { utmSource: "mawm-reddit", partnerName: "Reddit", useLimit: 2500 },
    {
      utmSource: "mawm-pln",
      partnerName: "Parent Life Network",
      useLimit: 1500,
    },
  ],
  [UTM_MEDIUM_DISPLAY]: [
    { utmSource: "mawm-thepeak", partnerName: "The Peak", useLimit: 1500 },
    {
      utmSource: "mawm-parentscanada",
      partnerName: "Parents Canada",
      useLimit: 1000,
    },
    {
      utmSource: "mawm-babycenter_ca",
      partnerName: "Babycenter.ca",
      useLimit: 1000,
    },
    {
      utmSource: "mawm-notable-life",
      partnerName: "Notable Life",
      useLimit: 1000,
    },
  ],
  [UTM_MEDIUM_PARTNER]: [
    { utmSource: "mawm-nextdoor", partnerName: "Nextdoor", useLimit: 2500 },
    {
      utmSource: "mawm-mfm",
      partnerName: "Movies for Mommies",
      useLimit: 1000,
    },
    {
      utmSource: "mawm-torontomidwives",
      partnerName: "Toronto Midwives of Don Mills",
      useLimit: 200,
    },
    {
      utmSource: "mawm-eastendchildwranglers",
      partnerName: "East End Child Wranglers",
      useLimit: 1000,
    },
    {
      utmSource: "mawm-torontoriverdaleparents",
      partnerName: "Toronto Riverdale Parents",
      useLimit: 1000,
    },
    {
      utmSource: "mawm-etymvillage",
      partnerName: "ETYM Village",
      useLimit: 2500,
    },
    { utmSource: "mawm-mightykids", partnerName: "Mighty Kids", useLimit: 100 },
    {
      utmSource: "mawm-equinoxholisticalternativeschool",
      partnerName: "Equinox Holistic Alternative School",
      useLimit: 200,
    },
    {
      utmSource: "mawm-singlesupermoms",
      partnerName: "Single Super-Moms",
      useLimit: 1500,
    },
    {
      utmSource: "mawm-yongelawrenceparents",
      partnerName: "Yonge and Lawrence Parents",
      useLimit: 1500,
    },
    {
      utmSource: "mawm-lgbtqparentsgta",
      partnerName: "LGBTQ+ Parents GTA Facebook",
      useLimit: 100,
    },
    {
      utmSource: "mawm-bumpbaby",
      partnerName: "Bump Baby Toddler",
      useLimit: 100,
    },
    {
      utmSource: "mawm-choiceparents",
      partnerName: "Choice Parents Toronto",
      useLimit: 250,
    },
    {
      utmSource: "mawm-bloorwest",
      partnerName: "Bloor West, High Park, Junction",
      useLimit: 2500,
    },
    {
      utmSource: "mawm-earlyon",
      partnerName: "EarlyON Child and Family Centres",
      useLimit: 2500,
    },
    { utmSource: "mawm-weewatch", partnerName: "Wee Watch", useLimit: 2500 },
    {
      utmSource: "mawm-leslieville",
      partnerName: "Leslieville School of Dance",
      useLimit: 800,
    },
    {
      utmSource: "mawm-gtaschoolcouncils",
      partnerName: "GTA School Councils",
      useLimit: 1500,
    },
    {
      utmSource: "mawm-etobicokemoms",
      partnerName: "Etobicoke Moms",
      useLimit: 1500,
    },
    {
      utmSource: "mawm-slumbershine",
      partnerName: "Slumber & Shine",
      useLimit: 1500,
    },
    {
      utmSource: "mawm-tpl",
      partnerName: "Toronto Public Library",
      useLimit: 2000,
    },
    {
      utmSource: "mawm-nonsensicalsociety",
      partnerName: "Nonsensical Society for Kids",
      useLimit: 50,
    },
    {
      utmSource: "mawm-macaulaycentre",
      partnerName: "Macaulay Daycare Centre",
      useLimit: 1000,
    },
    { utmSource: "mawm-oona", partnerName: "Oona", useLimit: 1000 },
    { utmSource: "mawm-bgc", partnerName: "BGC Canada", useLimit: 1000 },
    {
      utmSource: "mawm-torontonatureschool",
      partnerName: "Toronto Nature School",
      useLimit: 500,
    },
    { utmSource: "mawm-eqbank", partnerName: "EQ Bank", useLimit: 1000 },
  ],
};

export const selectIsMawmPartnerTemplateOne = createSelector(
  getLocation,
  ({ query }) => {
    const { utm_medium, utm_source } = query;

    if (!utm_medium || !utm_source) {
      return false;
    }

    const utmMedium = utm_medium.toLowerCase();
    const utmSource = utm_source.toLowerCase();

    if (!mawmPartnerListTemplateOne[utmMedium]) {
      return false;
    }

    return mawmPartnerListTemplateOne[utmMedium].includes(utmSource) || false;
  },
);

export const selectIsMawmPartnerTemplateTwo = createSelector(
  getLocation,
  ({ query }) => {
    const { utm_medium, utm_source } = query;

    if (!utm_medium || !utm_source) {
      return false;
    }

    const utmMedium = utm_medium.toLowerCase();
    const utmSource = utm_source.toLowerCase();

    if (!mawmPartnerListTemplateTwo[utmMedium]) {
      return false;
    }

    return mawmPartnerListTemplateTwo[utmMedium].includes(utmSource) || false;
  },
);

export const selectIsMawmPartnerTemplateThree = createSelector(
  getLocation,
  ({ query }) => {
    const { utm_medium, utm_source } = query;

    if (!utm_medium || !utm_source) {
      return false;
    }

    const utmMedium = utm_medium.toLowerCase();
    const utmSource = utm_source.toLowerCase();

    if (!mawmPartnerListTemplateThree[utmMedium]) {
      return false;
    }

    const foundPartnerObj = mawmPartnerListTemplateThree[utmMedium].find(
      (p) => p.utmSource === utmSource,
    );

    // Returns the whole object to be used on translations to replace
    // {USER_LIMIT} and {PARTNER_NAME} with the correct values
    return foundPartnerObj || false;
  },
);

export const selectUtmSource = R.pathOr(null, ["auth", "utmSource"]);

export const selectInitialUtmSource = R.pathOr(null, [
  "auth",
  "initialUtmSource",
]);

export const selectSignupPageTranslations = createSelector(
  selectTranslations(["global", "signup", "trustIcon"]),
  selectIsFrenchSelected,
  selectIsFacebookReferral,
  selectIsSunlifeReferral,
  selectIsCIBCEmpReferral,
  selectIsCIBCClientsReferral,
  selectIsArborReferral,
  selectIsAllstateEmpReferral,
  selectIsAllstateClientsReferral,
  selectMercerPartnerReferral,
  selectIsKraftHeinzReferral,
  selectIsArborMemorialReferral,
  selectIsScotiaBankClientsReferral,
  selectIsScotiaBankEmployeesReferral,
  selectIsMercerCascadesPartnerReferral,
  selectIsMercerCRHCanadaGroupIncPartnerReferral,
  selectIsMercerMeridianReferral,
  selectIsMercerBDCanadaReferral,
  selectIsMercerTundraReferral,
  selectIsMercerHLFoodsReferral,
  selectIsMercerIQVIAReferral,
  selectIsMercerLoccitaneCanadaReferral,
  selectIsGrowSurfReferral,
  selectIsInnovationCreditUnionReferral,
  selectIsInnovationCreditUnionClientsReferral,
  selectIsMawmPartnerTemplateOne,
  selectIsMawmPartnerTemplateTwo,
  selectIsMawmPartnerTemplateThree,
  selectIsAmazonBenefitsReferral,
  selectIsEmbarkReferral,
  selectUserAuthMethod,
  (
    translations,
    isFrenchSelected,
    isFacebookReferral,
    isSunlifeReferral,
    isCIBCEmpReferral,
    isCIBCClientsReferral,
    isArborReferral,
    isAllstateEmpReferral,
    isAllstateClientsReferral,
    mercerPartnerReferral,
    isKraftHeinzReferral,
    isArborMemorialReferral,
    isScotiaBankClientsReferral,
    isScotiaBankEmployeesReferral,
    isMercerCascadesPartnerReferral,
    isMercerCRHCanadaGroupIncPartnerReferral,
    isMercerMeridianReferral,
    isMercerBDCanadaReferral,
    isMercerTundraReferral,
    isMercerHLFoodsReferral,
    isMercerIQVIAReferral,
    isMercerLoccitaneCanadaReferral,
    isGrowSurfReferral,
    isInnovationCreditUnionReferral,
    isInnovationCreditUnionClientsReferral,
    isMawmPartnerTemplateOne,
    isMawmPartnerTemplateTwo,
    isMawmPartnerTemplateThree,
    isAmazonBenefitsReferral,
    isEmbarkReferral,
    userAuthAttempt,
  ) => {
    if (isFacebookReferral) {
      const facebookLandingTranslations = translations.facebookLanding;
      return {
        ...translations,
        ...facebookLandingTranslations,
      };
    }

    if (isSunlifeReferral) {
      const sunlifeLandingTranslations = translations.sunlifeLanding;
      return {
        ...translations,
        ...sunlifeLandingTranslations,
      };
    }

    if (isCIBCEmpReferral) {
      const cibcEmpLandingTranslations = translations.cibcEmpLanding;
      return {
        ...translations,
        ...cibcEmpLandingTranslations,
      };
    }

    if (isCIBCClientsReferral) {
      const cibcClientsLandingTranslations = translations.cibcClientsLanding;
      return {
        ...translations,
        ...cibcClientsLandingTranslations,
      };
    }

    if (isArborReferral) {
      const arborLandingTranslations = translations.arborLanding;
      return {
        ...translations,
        ...arborLandingTranslations,
      };
    }

    if (isAllstateEmpReferral) {
      const allstateEmpLandingTranslations = translations.allstateEmpLanding;
      return {
        ...translations,
        ...allstateEmpLandingTranslations,
      };
    }

    if (isAllstateClientsReferral) {
      const allstateClientsLandingTranslations =
        translations.allstateClientsLanding;
      return {
        ...translations,
        ...allstateClientsLandingTranslations,
      };
    }

    if (isKraftHeinzReferral) {
      const kraftHeinzPartnerLandingTranslations =
        translations.kraftHeinzPartnerLanding;
      return {
        ...translations,
        ...kraftHeinzPartnerLandingTranslations,
      };
    }

    if (isScotiaBankClientsReferral) {
      const scotiaBankTranslations =
        translations.scotiaBankClientsPartnerLanding;
      return {
        ...translations,
        ...scotiaBankTranslations,
      };
    }

    if (isScotiaBankEmployeesReferral) {
      const scotiaBankTranslations =
        translations.scotiaBankEmployeesPartnerLanding;
      return {
        ...translations,
        ...scotiaBankTranslations,
      };
    }

    if (isArborMemorialReferral) {
      const arborMemorialPartnerLandingTranslations =
        translations.arborMemorialPartnerLanding;
      return {
        ...translations,
        ...arborMemorialPartnerLandingTranslations,
      };
    }

    if (isMercerCascadesPartnerReferral) {
      const mercerCascadesPartnerLandingTranslations =
        translations.mercerCascadesPartnerLanding;
      return {
        ...translations,
        ...mercerCascadesPartnerLandingTranslations,
      };
    }

    if (isMercerCRHCanadaGroupIncPartnerReferral) {
      const mercerCRHCanadaGroupIncPartnerLandingTranslations =
        translations.mercerCRHCanadaGroupIncPartnerLanding;
      return {
        ...translations,
        ...mercerCRHCanadaGroupIncPartnerLandingTranslations,
      };
    }

    if (isMercerMeridianReferral) {
      const mercerMeridianReferralPartnerLandingTranslations =
        translations.mercerMeridianPartnerLanding;
      return {
        ...translations,
        ...mercerMeridianReferralPartnerLandingTranslations,
      };
    }

    if (isMercerBDCanadaReferral) {
      const mercerBDCanadaPartnerLandingTranslations =
        translations.mercerBDCanadaPartnerLanding;
      return {
        ...translations,
        ...mercerBDCanadaPartnerLandingTranslations,
      };
    }

    if (isMercerTundraReferral) {
      const mercerTundraLandingTranslations =
        translations.mercerTundraPartnerLanding;
      return {
        ...translations,
        ...mercerTundraLandingTranslations,
      };
    }

    if (isMercerHLFoodsReferral) {
      const mercerHLFoodsLandingTranslations =
        translations.mercerHLFoodsPartnerLanding;
      return {
        ...translations,
        ...mercerHLFoodsLandingTranslations,
      };
    }

    if (isMercerIQVIAReferral) {
      const mercerIQVALandingTranslations =
        translations.mercerIQVIAPartnerLanding;
      return {
        ...translations,
        ...mercerIQVALandingTranslations,
      };
    }

    if (isMercerLoccitaneCanadaReferral) {
      const mercerLoccitaneCanadaLandingTranslations =
        translations.mercerLoccitaneCanadaPartnerLanding;
      return {
        ...translations,
        ...mercerLoccitaneCanadaLandingTranslations,
      };
    }

    if (isGrowSurfReferral) {
      const growSurfLandingTranslations = translations.growSurfPartnerLanding;
      return {
        ...translations,
        ...growSurfLandingTranslations,
      };
    }

    if (isInnovationCreditUnionReferral) {
      const innovationCreditUnionPartnerLandingTranslations =
        translations.innovationCreditUnionPartnerLanding;
      return {
        ...translations,
        ...innovationCreditUnionPartnerLandingTranslations,
      };
    }

    if (isInnovationCreditUnionClientsReferral) {
      const innovationCreditUnionClientsPartnerLandingTranslations =
        translations.innovationCreditUnionClientsPartnerLanding;
      return {
        ...translations,
        ...innovationCreditUnionClientsPartnerLandingTranslations,
      };
    }

    if (isMawmPartnerTemplateOne) {
      const mawmTemplateOneTranslations = translations.mawmTemplateOne;
      return {
        ...translations,
        ...mawmTemplateOneTranslations,
      };
    }

    if (isMawmPartnerTemplateTwo) {
      const mawmTemplateTwoTranslations = translations.mawmTemplateTwo;
      return {
        ...translations,
        ...mawmTemplateTwoTranslations,
      };
    }

    if (isMawmPartnerTemplateThree) {
      const mawmTemplateThreeTranslations = {
        ...translations.mawmTemplateThree,
      };

      const { partnerName, useLimit } = isMawmPartnerTemplateThree;
      const localizedUseLimit = useLimit.toLocaleString(
        isFrenchSelected ? "fr-CA" : "en-US",
      );
      mawmTemplateThreeTranslations.bulletList[1] = mawmTemplateThreeTranslations.bulletList[1].replace(
        "{USE_LIMIT}",
        localizedUseLimit,
      );
      mawmTemplateThreeTranslations.bulletList[1] = mawmTemplateThreeTranslations.bulletList[1].replace(
        "{PARTNER_NAME}",
        partnerName,
      );

      return {
        ...translations,
        ...mawmTemplateThreeTranslations,
      };
    }

    if (isAmazonBenefitsReferral) {
      const amazonBenefitsPartnerLandingTranslations =
        translations.amazonBenefitsPartnerLanding;
      return {
        ...translations,
        ...amazonBenefitsPartnerLandingTranslations,
      };
    }

    if (isEmbarkReferral) {
      const embarkPartnerLandingTranslations =
        translations.embarkPartnerLanding;
      return {
        ...translations,
        ...embarkPartnerLandingTranslations,
      };
    }

    if (mercerPartnerReferral) {
      const MERCER_PARTNER_LOOKUP = {
        "mercer-alithya": "Alithya Canada",
        "mercer-amazon": "Amazon",
        "mercer-aptos": "Aptos",
        "mercer-brookfield": "Brookfield Properties Development",
        "mercer-bitumar": "Bitumar",
        "mercer-cima": "CIMA+",
        "mercer-cleanharbors": "Clean Harbors",
        "mercer-couchetard": "Couche-Tard",
        "mercer-crhcanadagroupinc": "CRH Canada Group Inc.",
        "mercer-elanco": "Elanco",
        "mercer-ep-canada": "EP Canada",
        "mercer-esteelauder": "Estee Lauder",
        "mercer-evlogistics": "EV Logistics",
        "mercer-griffithfoods": "Griffith Foods",
        "mercer-meridian": "Meridian Credit Union",
        "mercer-munichre": "Munich RE",
        "mercer-marchmclennan": "Marsh & McLennan Companies",
        "mercer-pfizer": "Pfizer Canada",
        "mercer-quadrachemicals": "Quadra Chemicals",
        "mercer-quadient": "Quadient Canada",
        "mercer-shaw": "Shaw",
        "mercer-srdc": "SRDC",
        "mercer-westrock": "WestRock",
        "mercer-cmhc-qc": "CMHC",
        "mercer-ccsa": "CCSA",
        "mercer-proslide": "Proslide Technologies",
        "mercer-mercer365": "Mercer 365",
        "mercer-nordstrom": "Nordstrom",
        "mercer-schlutersystems": "Schluter Systems",
        "mercer-kerry-canada": "Kerry Canada",
        "mercer-vecima": "Vecima",
        "mercer-versacom": "Versacom",
        "mercer-transmountain": "Trans Mountain",
        "mercer-wellnesscp23": "Wellness CP",
        "mercer-cambridgefleetcortechnology": "Cambridge Fleetcor Technology",
        "mercer-admarebioinnovations": "AdMare BioInnovations",
        "mercer-cbcmbe23": "CBC / Radio Canada",
        "mercer-acciona": "Acciona",
      };
      const mercerPartnerReferralTranslations =
        translations.mercerPartnerLanding;
      mercerPartnerReferralTranslations.bulletList[1] = mercerPartnerReferralTranslations.bulletList[1].replace(
        "MERCER_PARTNER",
        MERCER_PARTNER_LOOKUP[mercerPartnerReferral],
      );
      return {
        ...translations,
        ...mercerPartnerReferralTranslations,
      };
    }

    if (userAuthAttempt.mustAuthViaPassword) {
      return {
        ...translations,
        userMustAuthViaPassword: getTranspiledMessage(
          "{EMAIL}",
          userAuthAttempt.email,
          translations.userMustAuthViaPassword,
        ),
      };
    }

    return translations;
  },
);

const selectIsMawmReferral = createSelector(
  selectIsMawmPartnerTemplateOne,
  selectIsMawmPartnerTemplateTwo,
  selectIsMawmPartnerTemplateThree,
  (
    isMawmPartnerTemplateOne,
    isMawmPartnerTemplateTwo,
    isMawmPartnerTemplateThree,
  ) => {
    return (
      isMawmPartnerTemplateOne ||
      isMawmPartnerTemplateTwo ||
      isMawmPartnerTemplateThree
    );
  },
);

export const signupPageProps = createStructuredSelector({
  isLoading: selectIsLoadingById(SIGNUP_FORM_ID),
  isFrenchSelected: selectIsFrenchSelected,
  translations: selectSignupPageTranslations,
  isAuthenticated: selectIsLoggedIn,
  isFacebookReferral: selectIsFacebookReferral,
  isSunlifeReferral: selectIsSunlifeReferral,
  isKraftHeinz: selectIsKraftHeinzReferral,
  isScotiaBankClientsReferral: selectIsScotiaBankClientsReferral,
  isScotiaBankEmployeesReferral: selectIsScotiaBankEmployeesReferral,
  isCIBCEmpReferral: selectIsCIBCEmpReferral,
  isCIBCClientsReferral: selectIsCIBCClientsReferral,
  isArborReferral: selectIsArborReferral,
  isAllstateEmpReferral: selectIsAllstateEmpReferral,
  isAllstateClientsReferral: selectIsAllstateClientsReferral,
  mercerPartnerReferral: selectMercerPartnerReferral,
  isArborMemorialReferral: selectIsArborMemorialReferral,
  isNextdoorReferral: selectIsNextdoorReferral,
  isParentLifeNetworkReferral: selectIsParentLifeNetworkReferral,
  isInnovationCreditUnionReferral: selectIsInnovationCreditUnionReferral,
  isInnovationCreditUnionClientsReferral: selectIsInnovationCreditUnionClientsReferral,
  isAmazonBenefitsReferral: selectIsAmazonBenefitsReferral,
  isEmbarkReferral: selectIsEmbarkReferral,
  isMawmReferral: selectIsMawmReferral,
});

export const loginPageProps = createStructuredSelector({
  isLoading: selectIsLoadingById(LOGIN_FORM_ID),
  isAuthenticated: selectIsLoggedIn,
  translations: selectTranslations(["global", "login"]),
});

export const forgotPasswordPageProps = createStructuredSelector({
  isLoading: selectIsLoadingById(FORGOT_PASSWORD_FORM_ID),
  translations: selectTranslations(["global", "forgotPassword"]),
});

export const resetPasswordPageProps = createStructuredSelector({
  isLoading: selectIsLoadingById(RESET_PASSWORD_FORM_ID),
  translations: selectTranslations(["global", "resetPassword"]),
});

export const PrivateRouteProps = createStructuredSelector({
  isAuthenticated: selectIsLoggedIn,
  redirectTo: selectRedirectTo,
});

export const SignUpRouteProps = createStructuredSelector({
  isAboutYouComplete: selectIsAboutYouComplete,
});

export const resetSuccessfulPageProps = createStructuredSelector({
  translations: selectTranslations(["global", "resetSuccessful"]),
});
