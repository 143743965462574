import React, { useEffect } from "react";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { connect } from "react-redux";

import AllocationsForm from "../../../components/forms/your-estate/AllocationsForm";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import { allocationsPageProps } from "../../../selectors/allocations";

import { PLANS_PATH } from "../../../constants/routes";
import {
  updateAllocations,
  fetchAllocations,
  distributeAllocationsEvenly,
  removeAllocation,
  updateAllocationCard,
} from "../../../actions/allocations";
import {
  updateRangePosition,
  updateSliderValue,
} from "../../../actions/allocations-slider";
import {
  addBeneficiary,
  addCharityBeneficiary,
  addPersonBeneficiary,
  cancelAddingBeneficiary,
} from "../../../actions/beneficiary";
import { FormWrapper } from "../../../components/ui/Form";
import { displayModal } from "../../../actions/modal";
import { MODAL_ADD_BENEFICIARY_PERSON } from "../../../constants/modal";

const AllocationsPage = ({
  fetchAllocations,
  updateAllocations,
  addBeneficiary,
  hasLoaded,
  isAddingBeneficiary,
  addBeneficiaryForm,
  allocationsForm,
  translations,
  updateAllocationCard,
  allocations,
  hasAllocations,
  chartAllocations,
  isPrimaryBeneficiarySpouse,
  ...otherProps
}) => {
  useEffect(() => {
    fetchAllocations();
  }, [fetchAllocations]);
  const theme = useTheme();
  const isTabletDown = useMediaQuery(theme.breakpoints.down("md"));
  if (!hasLoaded) return <LoadingSpinner />;
  return (
    <FormWrapper
      translations={translations}
      includePadding
      customGrid={hasAllocations}
      hideInfoBox={hasAllocations}
      suppress
      reverseWrap={isTabletDown}
    >
      <AllocationsForm
        onSubmit={() => updateAllocations(allocations)}
        plansLink={PLANS_PATH}
        modalKey={MODAL_ADD_BENEFICIARY_PERSON}
        onSubmitModal={addBeneficiary}
        addBeneficiaryForm={addBeneficiaryForm}
        updateAllocationCard={updateAllocationCard}
        allocations={allocations}
        hasAllocations={hasAllocations}
        chartAllocations={chartAllocations}
        isPrimaryBeneficiarySpouse={isPrimaryBeneficiarySpouse}
        {...allocationsForm}
        {...otherProps}
      />
    </FormWrapper>
  );
};

export default connect(allocationsPageProps, {
  updateAllocations,
  fetchAllocations,
  addBeneficiary,
  addCharityBeneficiary,
  addPersonBeneficiary,
  displayModal,
  cancelAddingBeneficiary,
  updateRangePosition,
  updateSliderValue,
  distributeAllocationsEvenly,
  removeAllocation,
  updateAllocationCard,
})(AllocationsPage);
