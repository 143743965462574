import R from "ramda";
import { takeEvery, call, select, put } from "redux-saga/effects";
import { change, startSubmit, stopSubmit, getFormValues } from "redux-form";

import {
  CHILDREN_GUARDIANS_NEXT_PAGE_TYPE,
  OPEN_MODAL_CHILDREN_GUARDIANS_TYPE,
  REMOVE_CHILDREN_GUARDIAN_TYPE,
  childrenGuardianUpdatedSuccesfully,
} from "../actions/children-guardians";
import { submitFormRequest } from "./forms";
import {
  getChildrenGuardiansNextPage,
  removeChildrenGuardiansEndpoint,
  submitChildrenGuardians,
} from "../api/children-guardians";
import {
  CHILDREN_GUARDIANS_FIELD_ID,
  CHILDREN_GUARDIANS_FORM_ID,
  CHILDREN_GUARDIANS_MODAL_FORM_ID,
} from "../constants/forms";
import { closeToast, displayToast } from "../actions/toast";
import { closeModal, displayModal } from "../actions/modal";
import { MODAL_ADD_GUARDIAN, MODAL_EDIT_GUARDIAN } from "../constants/modal";
import { selectLastSelectedChildrenGuardianData } from "../selectors/children-guardians";
import {
  ADD_CHILDREN_GUARDIAN_AND_SPOUSE_SUCCESS,
  ADD_CHILDREN_GUARDIAN_SUCCESS,
  EDIT_CHILDREN_GUARDIAN_AND_SPOUSE_SUCCESS,
  EDIT_CHILDREN_GUARDIAN_SUCCESS,
  REMOVE_CHILDREN_GUARDIAN_AND_SPOUSE_SUCCESS,
  REMOVE_CHILDREN_GUARDIAN_SUCCESS,
} from "../constants/toasts";

const guardianHasSpouse = (field) => R.propOr(false, "spouseFirstName")(field);

function* updateChildrenGuardians(guardianToUpdate) {
  const { status, guardian } = yield call(submitFormRequest, {
    apiCall: submitChildrenGuardians,
    formId: CHILDREN_GUARDIANS_FORM_ID,
    values: {
      ...R.mapObjIndexed(R.defaultTo(null), guardianToUpdate),
    },
  });
  if (status !== 200 || !guardian) {
    yield put(stopSubmit(CHILDREN_GUARDIANS_FORM_ID));
    return null;
  }
  return guardian.id;
}

function* handleOpenModalChildrenGuardians({ payload }) {
  yield put(closeToast());
  const guardian = R.propOr(null, "guardian")(payload);

  if (guardian) {
    yield put(
      change(
        CHILDREN_GUARDIANS_MODAL_FORM_ID,
        CHILDREN_GUARDIANS_FIELD_ID,
        guardian,
      ),
    );
    return yield put(displayModal(MODAL_EDIT_GUARDIAN));
  }
  return yield put(displayModal(MODAL_ADD_GUARDIAN));
}

export function* handleAddOrEditChildrenGuardian(isEditing = false) {
  yield put(startSubmit(CHILDREN_GUARDIANS_MODAL_FORM_ID));
  try {
    const formData = yield select(
      getFormValues(CHILDREN_GUARDIANS_MODAL_FORM_ID),
    );
    const {
      guardians: {
        firstName,
        middleName,
        lastName,
        spouseFirstName,
        spouseMiddleName,
        spouseLastName,
      },
    } = formData;
    const newChildrenGuardian = {
      firstName,
      middleName,
      lastName,
      spouseFirstName,
      spouseMiddleName,
      spouseLastName,
    };
    const updatedChildrenGuardian = isEditing
      ? yield call(editChildrenGuardian, newChildrenGuardian)
      : yield call(addChildrenGuardian, newChildrenGuardian);

    yield put(closeModal());
    yield put(
      change(
        CHILDREN_GUARDIANS_FORM_ID,
        CHILDREN_GUARDIANS_FIELD_ID,
        updatedChildrenGuardian,
      ),
    );
    yield put(childrenGuardianUpdatedSuccesfully(newChildrenGuardian));
    const selectedChildrenGuardian = yield select(
      selectLastSelectedChildrenGuardianData,
    );
    if (isEditing) {
      yield put(
        displayToast(
          guardianHasSpouse(selectedChildrenGuardian)
            ? EDIT_CHILDREN_GUARDIAN_AND_SPOUSE_SUCCESS
            : EDIT_CHILDREN_GUARDIAN_SUCCESS,
        ),
      );
    } else {
      yield put(
        displayToast(
          guardianHasSpouse(selectedChildrenGuardian)
            ? ADD_CHILDREN_GUARDIAN_AND_SPOUSE_SUCCESS
            : ADD_CHILDREN_GUARDIAN_SUCCESS,
        ),
      );
    }
  } catch (error) {
    yield put(stopSubmit(CHILDREN_GUARDIANS_FORM_ID, error.formErrors));
  }
}

function* addChildrenGuardian(newChildrenGuardian) {
  const childrenGuardiansList = yield select(
    getFormValues(CHILDREN_GUARDIANS_FORM_ID),
  );
  const id = yield call(updateChildrenGuardians, newChildrenGuardian);
  const updatedChildrenGuardian = { id, ...newChildrenGuardian };
  return [...childrenGuardiansList.guardians, updatedChildrenGuardian];
}

function* editChildrenGuardian(newChildrenGuardian) {
  const selectedChildrenGuardian = yield select(
    selectLastSelectedChildrenGuardianData,
  );
  const { guardians } = yield select(getFormValues(CHILDREN_GUARDIANS_FORM_ID));
  let updatedChildrenGuardian = {};
  const newChildrenGuardiansList = guardians.map((guardian, index) => {
    if (index === selectedChildrenGuardian.index) {
      updatedChildrenGuardian = {
        ...newChildrenGuardian,
        id: guardian.id,
      };

      return updatedChildrenGuardian;
    }
    return guardian;
  });
  yield call(updateChildrenGuardians, updatedChildrenGuardian);
  return newChildrenGuardiansList;
}

export function* handleRemoveChildrenGuardian({ payload }) {
  const guardian = R.propOr(null, "guardian")(payload);
  yield put(closeToast());
  yield call(submitFormRequest, {
    apiCall: removeChildrenGuardiansEndpoint,
    values: {
      ...guardian,
    },
  });
  yield put(
    displayToast(
      guardianHasSpouse(guardian)
        ? REMOVE_CHILDREN_GUARDIAN_AND_SPOUSE_SUCCESS
        : REMOVE_CHILDREN_GUARDIAN_SUCCESS,
    ),
  );
}

function* handleChildrenGuardianNextPage() {
  return yield call(submitFormRequest, {
    apiCall: getChildrenGuardiansNextPage,
  });
}

export function* watchOpenModalChildrenGuardians() {
  yield takeEvery(
    OPEN_MODAL_CHILDREN_GUARDIANS_TYPE,
    handleOpenModalChildrenGuardians,
  );
}

export function* watchHandleRemoveChildrenGuardian() {
  yield takeEvery(REMOVE_CHILDREN_GUARDIAN_TYPE, handleRemoveChildrenGuardian);
}

export function* watchChildrenGuardiansnNextPage() {
  yield takeEvery(
    CHILDREN_GUARDIANS_NEXT_PAGE_TYPE,
    handleChildrenGuardianNextPage,
  );
}
