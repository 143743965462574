import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";

import { initializeApp } from "../actions/app-lifecycle";

import {
  DASHBOARD_PATH,
  DOCUMENTS_PATH,
  PLANS_PATH,
  CHILDREN_PATH,
  SPOUSE_PATH,
  PETS_PATH,
  GIFTS_PATH,
  DONATIONS_PATH,
  EXECUTORS_PATH,
  PROPERTY_ATTORNEYS_PATH,
  PERSONAL_ATTORNEYS_PATH,
  THANK_YOU_REDIRECT_PATH,
  SORRY_PATH,
  CHILDREN_GUARDIANS_PATH,
  PET_GUARDIANS_PATH,
  PET_TRUST_PATH,
  CHECKOUT_PATH,
  RECEIPT_PATH,
  LIFE_PROLONG_PATH,
  PAIN_MANAGE_PATH,
  WISHES_RESTING_PATH,
  ASHES_PATH,
  WISHES_CEREMONY_PATH,
  PRIMARY_BENEFICIARY_PATH,
  ALLOCATIONS_PATH,
  INHERITANCE_PATH,
  BACKUPS_PATH,
  PREDECEASE_PATH,
  RESIDENCE_PATH,
  GENDER_PATH,
  SIGNUP_PATH,
  LOGIN_PATH,
  FORGOT_PASSWORD_PATH,
  RESET_PASSWORD_PATH,
  RESET_SUCCESS_PATH,
  FULL_NAME_PATH,
  DOB_PATH,
  MARITAL_STATUS_PATH,
  SPOUSE_DONATIONS_PATH,
  SPOUSE_GIFTS_PATH,
  SPOUSE_EXECUTORS_PATH,
  SPOUSE_PROPERTY_ATTORNEYS_PATH,
  SPOUSE_PERSONAL_ATTORNEYS_PATH,
  SPOUSE_RESTING_PATH,
  SPOUSE_ASHES_PATH,
  SPOUSE_CEREMONY_PATH,
  SPOUSE_PAIN_MANAGE_PATH,
  SPOUSE_LIFE_PROLONG_PATH,
  YOUR_ACCOUNT_PATH,
  ACCOUNT_DELETED_PATH,
  CLAIM_INVITE_PATH,
  INVALID_INVITE_PATH,
  REVIEW_YOUR_ARRANGEMENTS_PATH,
  REVIEW_YOUR_ESTATE_PATH,
  REVIEW_YOUR_FAMILY_PATH,
  REVIEW_SPOUSE_ESTATE_PATH,
  REVIEW_SPOUSE_ARRANGEMENTS_PATH,
  REVIEW_ABOUT_YOU_PATH,
  INTENDED_USE_PATH,
  FAMILY_RECOMMENDED_PATH,
  PREMIUM_RECOMMENDED_PATH,
  WELCOME_PATH,
  OVERVIEW_HOW_IT_WORKS_PATH,
  OVERVIEW_SPOUSE_ESTATE_PATH,
  OVERVIEW_SPOUSE_ARRANGEMENTS_PATH,
  OVERVIEW_CONGRATULATIONS_PATH,
  APPOINTEES_PATH,
  ASSET_LIST_PATH,
  ASSET_LIST_OVERVIEW_PATH,
  WELCOME_BACK_PATH,
  BACKUPS_GUIDANCE_PATH,
  ALLOCATIONS_GUIDANCE_PATH,
  FAMILY_PLAN_GUIDANCE_PATH,
  IMPORTANT_NOTE_PATH,
  PREMIUM_PLAN_GUIDANCE_PATH,
  ESSENTIALS_PLAN_GUIDANCE_PATH,
  ESSENTIALS_PLAN_DOC_PREVIEW_PATH,
  PREMIUM_PLAN_DOC_PREVIEW_PATH,
  EXTERNAL_LOGIN_PATH,
} from "../constants/routes";

import YourAccountPage from "../containers/pages/YourAccountPage";

import ThankYouPage from "../containers/pages/ThankYouPage";
import SorryPage from "../containers/pages/SorryPage";
import FullNamePage from "../containers/pages/about-you/FullNamePage";
import DateOfBirthPage from "../containers/pages/about-you/DateOfBirthPage";
import ResidencePage from "../containers/pages/about-you/ResidencePage";
import GenderPage from "../containers/pages/about-you/GenderPage";
import FamilyRecommendationPage from "../containers/pages/about-you/FamilyRecommendationPage";
import PremiumRecommendationPage from "../containers/pages/about-you/PremiumRecommendationPage";

import MaritalStatusPage from "../containers/pages/your-family/MaritalStatusPage";
import SpousePage from "../containers/pages/your-family/SpousePage";
import ChildrenPage from "../containers/pages/your-family/ChildrenPage";
import ChildrenGuardiansPage from "../containers/pages/your-family/ChildrenGuardiansPage";
import PetsPage from "../containers/pages/your-family/PetsPage";
import PetGuardiansPage from "../containers/pages/your-family/PetGuardiansPage";
import PetTrustPage from "../containers/pages/your-family/PetTrustPage";

import GiftsPage from "../containers/pages/your-estate/GiftsPage";
import DonationsPage from "../containers/pages/your-estate/DonationsPage";
import PrimaryBeneficiaryPage from "../containers/pages/your-estate/PrimaryBeneficiaryPage";
import AllocationsPage from "../containers/pages/your-estate/AllocationsPage";
import PredeceasePage from "../containers/pages/your-estate/PredeceasePage";
import BackupPage from "../containers/pages/your-estate/BackupPage";
import InheritancePage from "../containers/pages/your-estate/InheritancePage";

import ExecutorsPage from "../containers/pages/your-arrangements/ExecutorsPage";
import ExecutorsPageDeprecated from "../containers/pages/your-arrangements/ExecutorsPageDeprecated";
import PersonalAttorneysPage from "../containers/pages/your-arrangements/PersonalAttorneysPage";
import PropertyAttorneysPage from "../containers/pages/your-arrangements/PropertyAttorneysPage";
import LifeProlongPage from "../containers/pages/your-arrangements/LifeProlongPage";
import PainManagePage from "../containers/pages/your-arrangements/PainManagePage";
import RestingPage from "../containers/pages/your-arrangements/RestingPage";
import AshesPage from "../containers/pages/your-arrangements/AshesPage";
import CeremonyPage from "../containers/pages/your-arrangements/CeremonyPage";

import SpouseGiftsPage from "../containers/pages/spouse-estate/SpouseGiftsPage";
import SpouseDonationsPage from "../containers/pages/spouse-estate/SpouseDonationsPage";
import SpouseExecutorsPage from "../containers/pages/spouse-arrangements/SpouseExecutorsPage";
import SpouseRestingPage from "../containers/pages/spouse-arrangements/SpouseRestingPage";
import SpouseAshesPage from "../containers/pages/spouse-arrangements/SpouseAshesPage";
import SpouseCeremonyPage from "../containers/pages/spouse-arrangements/SpouseCeremonyPage";
import SpousePersonalAttorneysPage from "../containers/pages/spouse-arrangements/SpousePersonalAttorneysPage";
import SpousePropertyAttorneysPage from "../containers/pages/spouse-arrangements/SpousePropertyAttorneysPage";
import SpouseLifeProlongPage from "../containers/pages/spouse-arrangements/SpouseLifeProlongPage";
import SpousePainManagePage from "../containers/pages/spouse-arrangements/SpousePainManagePage";

import Layout from "../containers/Layout";
import DashboardPage from "../containers/pages/DashboardPage";
import PlansPage from "../containers/pages/PlansPage";
import CheckoutPage from "../containers/pages/checkout/CheckoutPage";
import CheckoutPage_BNPL from "../containers/pages/checkout/CheckoutPage_BNPL";
import ReceiptPage from "../containers/pages/checkout/ReceiptPage";
import DocumentsPage from "../containers/pages/DocumentsPage";
import SignupPage from "../containers/pages/SignupPage";
import LoginPage from "../containers/pages/LoginPage";
import ForgotPasswordPage from "../containers/pages/ForgotPasswordPage";
import ResetPasswordPage from "../containers/pages/ResetPasswordPage";
import ResetSuccessfulPage from "../containers/pages/ResetSuccessfulPage";
import ClaimInvitePage from "../containers/pages/ClaimInvitePage";
import InvalidInvitePage from "../containers/pages/InvalidInvitePage";
import EndOfSectionReviewPage from "../containers/pages/EndOfSectionReviewPage";
import IntendedUsePage from "../containers/pages/about-you/IntendedUsePage";
import WelcomePage from "../containers/pages/WelcomePage";
import OverviewPage from "../containers/pages/OverviewPage";

import AccountDeletedPage from "../containers/pages/AccountDeletedPage";
import AuthenticatedRoute from "../containers/AuthenticatedRoute";
import UnauthenticatedRoute from "../containers/UnauthenticatedRoute";
import AppointeesPage from "../containers/pages/AppointeesPage";
import AssetListPage from "../containers/pages/AssetListPage";
import AssetListOverviewPage from "../containers/pages/AssetListOverviewPage";
import WelcomeBackPage from "../containers/pages/WelcomeBackPage";
import BackupsGuidancePage from "../containers/pages/BackupsGuidancePage";
import AllocationsGuidancePage from "../containers/pages/AllocationsGuidancePage";
import FamilyPlanGuidancePage from "../containers/pages/FamilyPlanGuidancePage";
import ImportantNotePage from "../containers/pages/ImportantNotePage";
import EssentialsPlanGuidancePage from "../containers/pages/EssentialsPlanGuidancePage";
import PremiumPlanGuidancePage from "../containers/pages/PremiumPlanGuidancePage";
import EssentialsPlanDocPreviewPage from "../containers/pages/EssentialsPlanDocPreviewPage";
import PremiumPlanDocPreviewPage from "../containers/pages/PremiumPlanDocPreviewPage";
import ExternalLoginPage from "../containers/pages/ExternalLoginPage";
import { routesProps } from "../selectors/routes";

const Routes = ({ initializeApp, shouldDisplayRecommendationPage }) => {
  useEffect(() => {
    initializeApp();
  }, [initializeApp]);
  return (
    <Layout>
      <Switch>
        <Route
          exact
          path={ACCOUNT_DELETED_PATH}
          component={AccountDeletedPage}
        />

        <Route exact path={THANK_YOU_REDIRECT_PATH} component={ThankYouPage} />
        <AuthenticatedRoute exact path={SORRY_PATH} component={SorryPage} />
        <UnauthenticatedRoute exact path={SIGNUP_PATH} component={SignupPage} />
        <UnauthenticatedRoute exact path={LOGIN_PATH} component={LoginPage} />
        <UnauthenticatedRoute
          exact
          path={EXTERNAL_LOGIN_PATH}
          component={ExternalLoginPage}
        />
        <UnauthenticatedRoute
          exact
          path={FORGOT_PASSWORD_PATH}
          component={ForgotPasswordPage}
        />
        <UnauthenticatedRoute
          exact
          path={RESET_PASSWORD_PATH}
          component={ResetPasswordPage}
        />
        <UnauthenticatedRoute
          exact
          path={RESET_SUCCESS_PATH}
          component={ResetSuccessfulPage}
        />
        <UnauthenticatedRoute
          exact
          path={CLAIM_INVITE_PATH}
          component={ClaimInvitePage}
        />
        <Route exact path={INVALID_INVITE_PATH} component={InvalidInvitePage} />
        <AuthenticatedRoute exact path={PLANS_PATH} component={PlansPage} />
        <AuthenticatedRoute
          exact
          path={DASHBOARD_PATH}
          component={DashboardPage}
        />
        <AuthenticatedRoute
          exect
          path={YOUR_ACCOUNT_PATH}
          component={YourAccountPage}
        />
        {/* ABOUT YOU PATHS */}
        <AuthenticatedRoute
          exact
          path={RESIDENCE_PATH}
          component={ResidencePage}
        />
        {shouldDisplayRecommendationPage && (
          <AuthenticatedRoute
            exact
            path={FAMILY_RECOMMENDED_PATH}
            component={FamilyRecommendationPage}
          />
        )}
        {shouldDisplayRecommendationPage && (
          <AuthenticatedRoute
            exact
            path={PREMIUM_RECOMMENDED_PATH}
            component={PremiumRecommendationPage}
          />
        )}
        <AuthenticatedRoute
          exact
          path={ESSENTIALS_PLAN_GUIDANCE_PATH}
          component={EssentialsPlanGuidancePage}
        />
        <AuthenticatedRoute
          exact
          path={PREMIUM_PLAN_GUIDANCE_PATH}
          component={PremiumPlanGuidancePage}
        />
        {/* DOC PREVIEW */}
        {window.env.FEATURE_ESS_PREM_DOC_PAGE === "enabled" && (
          <AuthenticatedRoute
            exact
            path={ESSENTIALS_PLAN_DOC_PREVIEW_PATH}
            component={EssentialsPlanDocPreviewPage}
          />
        )}
        {window.env.FEATURE_ESS_PREM_DOC_PAGE === "enabled" && (
          <AuthenticatedRoute
            exact
            path={PREMIUM_PLAN_DOC_PREVIEW_PATH}
            component={PremiumPlanDocPreviewPage}
          />
        )}
        <AuthenticatedRoute
          exact
          path={FULL_NAME_PATH}
          component={FullNamePage}
        />
        <AuthenticatedRoute exact path={DOB_PATH} component={DateOfBirthPage} />
        <AuthenticatedRoute exact path={GENDER_PATH} component={GenderPage} />
        <AuthenticatedRoute
          exact
          path={INTENDED_USE_PATH}
          component={IntendedUsePage}
        />
        {/* YOUR FAMILY PATHS */}
        <AuthenticatedRoute
          exact
          path={MARITAL_STATUS_PATH}
          component={MaritalStatusPage}
        />
        <AuthenticatedRoute exact path={SPOUSE_PATH} component={SpousePage} />
        <AuthenticatedRoute
          exact
          path={CHILDREN_PATH}
          component={ChildrenPage}
        />
        <AuthenticatedRoute
          exact
          path={CHILDREN_GUARDIANS_PATH}
          component={ChildrenGuardiansPage}
        />
        <AuthenticatedRoute exact path={PETS_PATH} component={PetsPage} />
        <AuthenticatedRoute
          exact
          path={PET_TRUST_PATH}
          component={PetTrustPage}
        />
        <AuthenticatedRoute
          exact
          path={PET_GUARDIANS_PATH}
          component={PetGuardiansPage}
        />
        {/* YOUR ESTATE PATHS */}
        <AuthenticatedRoute exact path={GIFTS_PATH} component={GiftsPage} />
        <AuthenticatedRoute
          exact
          path={DONATIONS_PATH}
          component={DonationsPage}
        />
        <AuthenticatedRoute
          exact
          path={PRIMARY_BENEFICIARY_PATH}
          component={PrimaryBeneficiaryPage}
        />
        <AuthenticatedRoute
          exact
          path={ALLOCATIONS_PATH}
          component={AllocationsPage}
        />
        <AuthenticatedRoute
          exact
          path={PREDECEASE_PATH}
          component={PredeceasePage}
        />
        <AuthenticatedRoute exact path={BACKUPS_PATH} component={BackupPage} />
        <AuthenticatedRoute
          exact
          path={INHERITANCE_PATH}
          component={InheritancePage}
        />
        {/* YOUR ARRANGEMENTS PATHS */}
        <AuthenticatedRoute
          exact
          path={EXECUTORS_PATH}
          component={
            window.env.FEATURE_CO_EXECUTORS === "enabled"
              ? ExecutorsPage
              : ExecutorsPageDeprecated
          }
        />
        <AuthenticatedRoute
          exact
          path={PROPERTY_ATTORNEYS_PATH}
          component={PropertyAttorneysPage}
        />
        <AuthenticatedRoute
          exact
          path={PERSONAL_ATTORNEYS_PATH}
          component={PersonalAttorneysPage}
        />
        <AuthenticatedRoute
          exact
          path={WISHES_RESTING_PATH}
          component={RestingPage}
        />
        <AuthenticatedRoute exact path={ASHES_PATH} component={AshesPage} />
        <AuthenticatedRoute
          exact
          path={WISHES_CEREMONY_PATH}
          component={CeremonyPage}
        />
        <AuthenticatedRoute
          exact
          path={LIFE_PROLONG_PATH}
          component={LifeProlongPage}
        />
        <AuthenticatedRoute
          exact
          path={PAIN_MANAGE_PATH}
          component={PainManagePage}
        />
        {/* SPOUSE ESTATE PATHS */}
        <AuthenticatedRoute
          exact
          path={SPOUSE_GIFTS_PATH}
          component={SpouseGiftsPage}
        />
        <AuthenticatedRoute
          exact
          path={SPOUSE_DONATIONS_PATH}
          component={SpouseDonationsPage}
        />
        {/* SPOUSE ARRANGEMENTS PATHS */}
        <AuthenticatedRoute
          exact
          path={SPOUSE_EXECUTORS_PATH}
          component={SpouseExecutorsPage}
        />
        <AuthenticatedRoute
          exact
          path={SPOUSE_RESTING_PATH}
          component={SpouseRestingPage}
        />
        <AuthenticatedRoute
          exact
          path={SPOUSE_ASHES_PATH}
          component={SpouseAshesPage}
        />
        <AuthenticatedRoute
          exact
          path={SPOUSE_CEREMONY_PATH}
          component={SpouseCeremonyPage}
        />
        <AuthenticatedRoute
          exact
          path={SPOUSE_PROPERTY_ATTORNEYS_PATH}
          component={SpousePropertyAttorneysPage}
        />
        <AuthenticatedRoute
          exact
          path={SPOUSE_PERSONAL_ATTORNEYS_PATH}
          component={SpousePersonalAttorneysPage}
        />
        <AuthenticatedRoute
          exact
          path={SPOUSE_PAIN_MANAGE_PATH}
          component={SpousePainManagePage}
        />
        <AuthenticatedRoute
          exact
          path={SPOUSE_LIFE_PROLONG_PATH}
          component={SpouseLifeProlongPage}
        />
        <AuthenticatedRoute
          exact
          path={CHECKOUT_PATH}
          component={
            window.env.FEATURE_BNPL_CHECKOUT_PAGE_EXPERIMENT === "enabled"
              ? CheckoutPage_BNPL
              : CheckoutPage
          }
        />
        <AuthenticatedRoute exact path={RECEIPT_PATH} component={ReceiptPage} />
        <AuthenticatedRoute
          exact
          path={DOCUMENTS_PATH}
          component={DocumentsPage}
        />
        <AuthenticatedRoute
          exact
          path={REVIEW_ABOUT_YOU_PATH}
          component={EndOfSectionReviewPage}
        />
        <AuthenticatedRoute
          exact
          path={REVIEW_YOUR_ARRANGEMENTS_PATH}
          component={EndOfSectionReviewPage}
        />
        <AuthenticatedRoute
          exact
          path={REVIEW_YOUR_ESTATE_PATH}
          component={EndOfSectionReviewPage}
        />
        <AuthenticatedRoute
          exact
          path={REVIEW_YOUR_FAMILY_PATH}
          component={EndOfSectionReviewPage}
        />
        <AuthenticatedRoute
          exact
          path={REVIEW_SPOUSE_ESTATE_PATH}
          component={EndOfSectionReviewPage}
        />
        <AuthenticatedRoute
          exact
          path={REVIEW_SPOUSE_ARRANGEMENTS_PATH}
          component={EndOfSectionReviewPage}
        />
        <AuthenticatedRoute exact path={WELCOME_PATH} component={WelcomePage} />

        <AuthenticatedRoute
          exact
          path={ASSET_LIST_OVERVIEW_PATH}
          component={AssetListOverviewPage}
        />

        <AuthenticatedRoute
          exact
          path={WELCOME_BACK_PATH}
          component={WelcomeBackPage}
        />
        <AuthenticatedRoute
          exact
          path={BACKUPS_GUIDANCE_PATH}
          component={BackupsGuidancePage}
        />
        <AuthenticatedRoute
          exact
          path={IMPORTANT_NOTE_PATH}
          component={ImportantNotePage}
        />
        <AuthenticatedRoute
          exact
          path={OVERVIEW_HOW_IT_WORKS_PATH}
          component={WelcomePage}
        />
        <AuthenticatedRoute
          exact
          path={OVERVIEW_SPOUSE_ESTATE_PATH}
          component={OverviewPage}
        />
        <AuthenticatedRoute
          exact
          path={OVERVIEW_SPOUSE_ARRANGEMENTS_PATH}
          component={OverviewPage}
        />
        <AuthenticatedRoute
          exact
          path={OVERVIEW_CONGRATULATIONS_PATH}
          component={OverviewPage}
        />
        <AuthenticatedRoute
          exact
          path={APPOINTEES_PATH}
          component={AppointeesPage}
        />

        <AuthenticatedRoute
          exact
          path={ASSET_LIST_PATH}
          component={AssetListPage}
        />

        <AuthenticatedRoute
          exact
          path={ALLOCATIONS_GUIDANCE_PATH}
          component={AllocationsGuidancePage}
        />
        <AuthenticatedRoute
          exact
          path={FAMILY_PLAN_GUIDANCE_PATH}
          component={FamilyPlanGuidancePage}
        />

        {/* // NO MATCH DEFAULT TO DASHBOARD */}
        <AuthenticatedRoute component={DashboardPage} />
      </Switch>
    </Layout>
  );
};

export default connect(routesProps, {
  initializeApp,
})(Routes);
