import React, { useRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Box, useMediaQuery } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/styles";
import styled from "styled-components";

import { SectionTitle, H2, TextLink, Body } from "../../ui/Typography";
import OverviewProgress from "../../ui/OverviewProgress";
import { ButtonLink } from "../../ui/Button";
import OverviewSectionIcon from "./OverviewSectionIcon";
import OverallProgressIndicator from "../../ui/OverallProgressIndicator";
import Confetti from "../../ui/animations/Confetti";
import {
  analyticsClickExternalLink,
  analyticsOpenedChat,
  analyticsClickedCheckoutNow,
} from "../../../actions/analytics";
import CheckoutNowCard from "../../ui/CheckoutNowCard";

const OverviewH2 = styled(H2)`
  ${(props) => props.$showCongratulations && "background-color: #E2F6FF;"};
`;

const ProgressAndIcon = ({
  progress = 0,
  currentSectionKey,
  translations,
  isFrenchSelected,
}) => (
  <Box>
    <OverallProgressIndicator
      progress={progress}
      translations={translations}
      isFrenchSelected={isFrenchSelected}
    />
    <OverviewSectionIcon currentSectionKey={currentSectionKey} />
  </Box>
);

const OverviewForm = ({
  translations,
  overviewSectionProps,
  currentSectionKey,
  isFrenchSelected,
  showCongratulations,
  hasUserPaid,
  overallProgress,
  nextSectionPath,
  previousSectionPath,
  showCheckoutNowCard,
  analyticsClickedCheckoutNow,
}) => {
  const overviewFormContainerRef = useRef(null);
  const [confettiHeight, setConfettiHeight] = useState(1);
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    // Get the height of the overview content to scale the height of the confetti properly
    const containerHeight = overviewFormContainerRef.current.offsetHeight;
    setConfettiHeight(containerHeight);
  }, []);

  const isFirstStep = currentSectionKey === "aboutYou";
  const backgroundColor = showCongratulations
    ? "willfulxxxLightBlue.main"
    : "none";

  return (
    <Box
      bgcolor={backgroundColor}
      width="100%"
      display="flex"
      justifyContent="center"
      flexGrow="1"
      position="relative"
      ref={overviewFormContainerRef}
    >
      {showCongratulations && (
        <Box
          position="absolute"
          top={0}
          zIndex={0}
          width="100%"
          maxWidth={1504}
          height={confettiHeight}
        >
          <Confetti height={confettiHeight} />
        </Box>
      )}
      <Box
        flex="1"
        width="100%"
        maxWidth={1504}
        display="flex"
        flexDirection="column"
        alignItems="center"
        py={isMobileOrTablet ? 4.5 : 7.5}
        px={2}
        position="relative"
        zIndex={1}
      >
        {isFirstStep && (
          <Box mb={1}>
            <SectionTitle>{translations.howItWorks}</SectionTitle>
          </Box>
        )}
        <Box mb={4.5}>
          <OverviewH2 $showCongratulations={showCongratulations}>
            {translations.titles[currentSectionKey]}
          </OverviewH2>
          {showCongratulations && (
            <Box mt={1}>
              <Body>
                {hasUserPaid && translations.congratulationsSubheadPaid}
                {!hasUserPaid && translations.congratulationsSubheadUnpaid}
              </Body>
            </Box>
          )}
        </Box>
        {isMobileOrTablet && !isFirstStep && (
          <Grid container justifyContent="center">
            <Grid item md={6} sm={12}>
              <ProgressAndIcon
                progress={overallProgress}
                currentSectionKey={currentSectionKey}
                translations={translations}
                isFrenchSelected={isFrenchSelected}
              />
            </Grid>
          </Grid>
        )}
        <Grid container justifyContent="center">
          <Grid item xl={isFirstStep ? 5 : 4} lg={5} md={10} sm={12}>
            <OverviewProgress
              translations={translations}
              sectionStatuses={overviewSectionProps}
              nextSectionPath={nextSectionPath}
              showCongratulations={showCongratulations}
            />
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mt={2}
              mb={isMobileOrTablet ? 3 : 0}
            >
              {!showCongratulations && (
                <TextLink
                  to={previousSectionPath}
                  displayArrowLeft
                  text={translations.button.previous}
                />
              )}
              {showCongratulations && (
                <ButtonLink
                  to={nextSectionPath}
                  className="qa-continue"
                  displayArrowRight
                  text={
                    showCongratulations && !hasUserPaid
                      ? translations.button.unlockDocuments
                      : translations.button.viewDocuments
                  }
                />
              )}
            </Box>
          </Grid>
          {!isFirstStep && (
            <>
              <Grid item xl={1} lg={1} md={10} sm={12} />
              <Grid item xl={4} lg={5} md={10} sm={12}>
                <Box>
                  {!isMobileOrTablet && (
                    <ProgressAndIcon
                      progress={overallProgress}
                      currentSectionKey={currentSectionKey}
                      translations={translations}
                      isFrenchSelected={isFrenchSelected}
                    />
                  )}
                  {showCheckoutNowCard && (
                    <CheckoutNowCard
                      translations={translations.checkoutNowCard}
                      analyticsClickedCheckoutNow={analyticsClickedCheckoutNow}
                    />
                  )}
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default connect(null, {
  analyticsClickExternalLink,
  analyticsOpenedChat,
  analyticsClickedCheckoutNow,
})(OverviewForm);
