import React, { useState } from "react";
import styled from "styled-components";

import Box from "@material-ui/core/Box";
import { SmallBody } from "../Typography";
import { ShowHideIcon } from "../icons/ShowHideIcon";
import {
  OLD_PASSWORD_FIELD_ID,
  PASSWORD_FIELD_ID,
  PASSWORD_CONF_FIELD_ID,
} from "../../../constants/forms";
import { ErrorMessage } from "../Error";
import { media } from "../../../config/Theme";
import TooltipTrigger from "../TooltipTrigger";

export const displayColor = (color) => (props) =>
  props.hasError ? "#FF2222" : color;
export const fontColor = (color, placeholderColor) => (props) => {
  if (props.hasError) return "#FF2222";
  if (props.hasValue) return color;
  return placeholderColor;
};

export const Input = styled.input`
  height: ${(props) => (props.height !== undefined ? props.height : "unset")};
  margin-top: ${(props) =>
    props.marginTop !== undefined ? props.marginTop : 8}px;
  padding: 16px;
  padding-right: ${(props) => (props.percentage !== undefined ? 32 : 16)}px;
  font-family: "TT Norms";
  border: 1px solid ${displayColor("#cacdd4")};
  color: ${fontColor("#4a4a4a", "#74747e")};
  transition: all 0.2s ease-in;
  font-weight: ${(props) => (props.hasValue ? "700" : "normal")};

  /* this component should match styles of Body
   TODO: contain these styles in a constant that
   we can keep aligned between both components
  */
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.01em;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: ${(props) => (props.type === "number" ? "none" : "")};
    margin: ${(props) => (props.type === "number" ? "0" : "")};
  }
  -moz-appearance: ${(props) => (props.type === "number" ? "textfield" : "")};

  ${media.desktop} {
    font-size: 20px;
  }

  &:hover {
    border: 1px solid ${displayColor("#0064dc")};
  }
  &:focus {
    font-weight: 700;
    color: ${displayColor("#4a4a4a")};
    border: 2px solid ${displayColor("#0064dc")};
    outline: none;
  }
`;

const TextInput = ({
  input,
  meta: { touched, error, dirty, active },
  autoFocus,
  disabled,
  noMargin,
  label,
  type = "text",
  optionalLabel,
  placeholder,
  step,
  min,
  max,
  percentage,
  marginTop,
  height,
  hideFieldLevelError = false,
  highLightFieldOnFormLevelError = false,
  suppress = false,
  fieldHasToolTip = false,
  translations,
  displayTooltipModal,
  tooltipKey,
  showHTMLErrorMessage = false,
}) => {
  const { name, value } = input;
  const shouldDisplayError = error && touched;
  const hasValue = value !== "";
  const shouldDisplayShowHideIcon =
    (name === PASSWORD_FIELD_ID && dirty) ||
    (name === OLD_PASSWORD_FIELD_ID && dirty) ||
    (name === PASSWORD_CONF_FIELD_ID && dirty);
  const [fieldType, setFieldType] = useState("password");
  const handleShowPassword = () => {
    return fieldType === "password"
      ? setFieldType("text")
      : setFieldType("password");
  };
  const hasLabel = label && label.length > 0;
  return (
    <Box display="flex" flexDirection="column" mb={noMargin ? 0 : 2}>
      {hasLabel > 0 && (
        <label htmlFor={name}>
          <SmallBody>
            {`${label} `}
            {optionalLabel}
            {fieldHasToolTip && (
              <TooltipTrigger
                translations={translations}
                onClick={() => displayTooltipModal(tooltipKey)}
              />
            )}
          </SmallBody>
        </label>
      )}
      <Input
        {...input}
        {...(suppress && { "data-hj-suppress": true })}
        hasError={shouldDisplayError || highLightFieldOnFormLevelError}
        hasValue={hasValue}
        autoFocus={autoFocus}
        disabled={disabled}
        placeholder={placeholder}
        type={shouldDisplayShowHideIcon ? fieldType : type}
        id={name}
        step={step}
        min={min}
        max={max}
        percentage={percentage}
        marginTop={marginTop}
        height={height}
      />
      {shouldDisplayShowHideIcon && (
        <Box display="flex">
          <Box width={1} />
          <ShowHideIcon
            onClick={handleShowPassword}
            active={active}
            error={shouldDisplayError}
            type={fieldType === "password" ? "show" : "hide"}
          />
        </Box>
      )}
      {!hideFieldLevelError && shouldDisplayError && (
        <ErrorMessage
          showHTMLErrorMessage={showHTMLErrorMessage}
          error={error}
        />
      )}
    </Box>
  );
};
export default TextInput;
