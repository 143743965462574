import { createStructuredSelector, createSelector } from "reselect";
import { selectTranslations } from "./translations";
import { selectCurrentRoute } from "./location";
import {
  DASHBOARD_PATH,
  DOCUMENTS_PATH,
  LOGIN_PATH,
  SIGNUP_PATH,
} from "../constants/routes";
import { selectFirstName } from "./about-you";
import { selectIsFrenchAvailableInSelectedProvince } from "./provinces";
import {
  selectActivePlanSymbol,
  selectHasActivePlan,
  selectHasUserPaid,
  selectIsActivePlanPremium,
} from "./plans";
import { cartProps } from "./cart";
import { selectHasAppointee } from "./appointees";
import { COUPLES_PLAN, PREMIUM_PLAN } from "../constants/plans";
import { selectAssetListPageUrl } from "./asset-list";
import { selectIsFrenchSelected } from "./language";
import {
  selectIsSunlifeReferral,
  selectIsCIBCEmpReferral,
  selectIsCIBCClientsReferral,
  selectIsArborReferral,
  selectIsAllstateEmpReferral,
  selectIsAllstateClientsReferral,
  selectIsKraftHeinzReferral,
  selectIsArborMemorialReferral,
  selectIsScotiaBankClientsReferral,
  selectIsScotiaBankEmployeesReferral,
  selectIsNextdoorReferral,
  selectIsParentLifeNetworkReferral,
  selectIsInnovationCreditUnionReferral,
  selectIsAmazonBenefitsReferral,
  selectIsInnovationCreditUnionClientsReferral,
  selectIsEmbarkReferral,
  selectIsMercerLoccitaneCanadaReferral,
} from "./auth";

const selectIsCurrentRoute = (path) =>
  createSelector(selectCurrentRoute, (currentRoute) => currentRoute === path);

const selectHeaderTranslations = createSelector(
  selectTranslations(["global", "header"]),
  selectFirstName,
  selectActivePlanSymbol,
  (translations, firstName, planSymbol) => {
    const yourDocuments =
      planSymbol === PREMIUM_PLAN || planSymbol === COUPLES_PLAN
        ? translations.willPoa
        : translations.willOnly;
    return {
      ...translations,
      menuLabel: translations.menu,
      yourDocuments,
    };
  },
);

const selectProductSwitcherTranslations = createSelector(
  selectTranslations(["global", "header"]),
  selectActivePlanSymbol,
  (translations, planSymbol) => {
    const yourDocuments =
      planSymbol === PREMIUM_PLAN || planSymbol === COUPLES_PLAN
        ? translations.willPoa
        : translations.willOnly;
    return {
      ...translations,
      menuLabel: translations.yourPlan,
      yourDocuments,
    };
  },
);

const selectLogoLink = () => {
  return DASHBOARD_PATH;
};

const selectIsScotiaBankReferral = createSelector(
  selectIsScotiaBankClientsReferral,
  selectIsScotiaBankEmployeesReferral,
  (isScotiaBankClient, isScotiaBankEmployee) =>
    isScotiaBankClient || isScotiaBankEmployee,
);

export const selectHeaderAuthModeProps = createSelector(
  selectIsCurrentRoute(SIGNUP_PATH),
  selectHeaderTranslations,
  (currentRouteIsSignup, translations) =>
    currentRouteIsSignup
      ? {
          path: LOGIN_PATH,
          label: translations.login,
        }
      : {
          path: SIGNUP_PATH,
          label: translations.signup,
        },
);

export const headerProps = createStructuredSelector({
  isOnDocuments: selectIsCurrentRoute(DOCUMENTS_PATH),
  isFrenchAvailable: selectIsFrenchAvailableInSelectedProvince,
  hasSelectedPlan: selectHasActivePlan,
  cartProps,
  translations: selectHeaderTranslations,
  yourAccountMenuProps: createStructuredSelector({
    translations: selectHeaderTranslations,
    isOnDocuments: selectIsCurrentRoute(DOCUMENTS_PATH),
    isPremium: selectIsActivePlanPremium,
    assetListLink: selectAssetListPageUrl,
    hasAppointees: selectHasAppointee,
    hasSelectedPlan: selectHasActivePlan,
    hasUserPaid: selectHasUserPaid,
  }),
  productSwitcherMenuProps: createStructuredSelector({
    translations: selectProductSwitcherTranslations,
    isPremium: selectIsActivePlanPremium,
    assetListLink: selectAssetListPageUrl,
    hasAppointees: selectHasAppointee,
  }),
  logoLink: selectLogoLink,
  isFrenchSelected: selectIsFrenchSelected,
  isKraftHeinzReferral: selectIsKraftHeinzReferral,
  isScotiaBankReferral: selectIsScotiaBankReferral,
  isSunlifeReferral: selectIsSunlifeReferral,
  isCIBCEmpReferral: selectIsCIBCEmpReferral,
  isCIBCClientsReferral: selectIsCIBCClientsReferral,
  isAllstateEmpReferral: selectIsAllstateEmpReferral,
  isAllstateClientsReferral: selectIsAllstateClientsReferral,
  isArborReferral: selectIsArborReferral,
  isArborMemorialReferral: selectIsArborMemorialReferral,
  isNextdoorReferral: selectIsNextdoorReferral,
  isParentLifeNetworkReferral: selectIsParentLifeNetworkReferral,
  isInnovationCreditUnionReferral: selectIsInnovationCreditUnionReferral,
  isInnovationCreditUnionClientsReferral: selectIsInnovationCreditUnionClientsReferral,
  isAmazonBenefitsReferral: selectIsAmazonBenefitsReferral,
  isEmbarkReferral: selectIsEmbarkReferral,
  isMercerLoccitaneCanadaReferral: selectIsMercerLoccitaneCanadaReferral,
});
