import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme, withTheme } from "@material-ui/styles";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { Hidden } from "@material-ui/core";
import { PageLayout } from "../../components/ui/PageLayout";
import { PageWrapper } from "../Layout";
import { welcomeBackProps } from "../../selectors/welcome-back";
import { H1, LargeBody } from "../../components/ui/Typography";
import WelcomeIcon from "../../components/ui/icons/WelcomeIcon";
import AppointeesCard from "../../components/ui/get-started-cards/AppointeesCard";
import AssetListCard from "../../components/ui/get-started-cards/AssetListCard";
import PremiumProductCard from "../../components/ui/get-started-cards/PremiumProductCard";
import EssentialsProductCard from "../../components/ui/get-started-cards/EssentialsProductCard";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import { ESSENTIALS_PLAN, PREMIUM_PLAN } from "../../constants/plans";
import { fetchWelcomeBack } from "../../actions/welcome-back";

const WelcomeBackPage = ({
  translations,
  hasAssets,
  activePlan,
  completionPercentage,
  hasAppointee,
  nextOverviewPath,
  hasLoaded,
  hasPaid,
  assetListPageUrl,
  fetchWelcomeBack,
}) => {
  useEffect(() => {
    if (!hasLoaded) {
      fetchWelcomeBack();
    }
  }, [fetchWelcomeBack, hasLoaded]);

  const theme = useTheme();
  const isLargeDesktop = useMediaQuery(theme.breakpoints.only("xl"));
  const isDesktop = useMediaQuery(theme.breakpoints.only("lg"));
  const welcomeIconWidth = isLargeDesktop ? 280 : isDesktop ? 214 : 115;
  const welcomeIconHeight = isLargeDesktop ? 243 : isDesktop ? 186 : 100;
  const isEssentialsPlan = activePlan.symbol === ESSENTIALS_PLAN;
  const isPremiumPlan = activePlan.symbol === PREMIUM_PLAN;
  const isPlanComplete = completionPercentage === 100;
  if (!hasLoaded)
    return (
      <Box flexGrow="1">
        <LoadingSpinner />
      </Box>
    );

  return (
    <Box
      width="100%"
      height="100%"
      bgcolor="willfulxxxLightBlue.main"
      flex="1"
      display="flex"
      flexDirection="column"
      alignItems="center"
      flexGrow={1}
    >
      <PageWrapper flexGrow={1}>
        <PageLayout>
          <Grid container>
            <Grid item xl={1} lg={1} md={1} />
            <Grid item xl={10} lg={10} md={10} xs={12}>
              <Grid item container xl={12} lg={12} md={12} xs={12}>
                <Grid item xl={6} lg={6} md={12} xs={12}>
                  <Box mb={isLargeDesktop || isDesktop ? 4.5 : 3}>
                    <Box mb={1.5}>
                      <H1 align="left" marginBottom="0" data-hj-suppress>
                        {translations.title}
                      </H1>
                    </Box>
                    <Box mb={1.5}>
                      <LargeBody align="left">{translations.body}</LargeBody>
                    </Box>
                    <Hidden lgUp>
                      <Box align="right">
                        <WelcomeIcon
                          width={welcomeIconWidth}
                          height={welcomeIconHeight}
                        />
                      </Box>
                    </Hidden>
                  </Box>
                  <Box>
                    {isPremiumPlan && (
                      <Box mb={1.5}>
                        <PremiumProductCard
                          translations={translations.premiumProductCard}
                          isPlanComplete={isPlanComplete}
                          nextOverviewPath={nextOverviewPath}
                          hasPaid={hasPaid}
                        />
                      </Box>
                    )}
                    {isEssentialsPlan && (
                      <Box mb={1.5}>
                        <EssentialsProductCard
                          translations={translations.essentialsProductCard}
                          isPlanComplete={isPlanComplete}
                          nextOverviewPath={nextOverviewPath}
                          hasPaid={hasPaid}
                        />
                      </Box>
                    )}
                    {hasAppointee && (
                      <Box mb={1.5}>
                        <AppointeesCard
                          translations={translations.appointeesCard}
                        />
                      </Box>
                    )}
                    {isPremiumPlan && (
                      <Box mb={1.5}>
                        <AssetListCard
                          translations={translations.assetListCard}
                          hasAssets={hasAssets}
                          assetListPageUrl={assetListPageUrl}
                        />
                      </Box>
                    )}
                  </Box>
                </Grid>
                <Hidden mdDown>
                  <Grid item xl={6} lg={6}>
                    <Box align="right">
                      <WelcomeIcon
                        width={welcomeIconWidth}
                        height={welcomeIconHeight}
                      />
                    </Box>
                  </Grid>
                </Hidden>
              </Grid>
            </Grid>
            <Grid item xl={1} lg={1} md={1} />
          </Grid>
        </PageLayout>
      </PageWrapper>
    </Box>
  );
};

export default connect(welcomeBackProps, {
  fetchWelcomeBack,
})(withTheme(WelcomeBackPage));
