import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import styled from "styled-components";
import Box from "@material-ui/core/Box";

import { logout } from "../actions/auth";
import { closeToast } from "../actions/toast";
import { fetchCart } from "../actions/cart";
import { layoutProps } from "../selectors/layout";
import { analyticsOpenedCartWidget } from "../actions/analytics";
import Modal from "./Modal";
import TooltipModal from "./TooltipModal";
import EmailPreviewModal from "./EmailPreviewModal";
import BottomNav from "./NavigationBar";
import Header from "../components/navigation/Header";
import Footer from "../components/ui/Footer";
import MobileNavContent from "../components/navigation/MobileNavContent";
import Toast from "../components/ui/Toast";
import PartnerDiscountBanner from "../components/banners/PartnerDiscountBanner";
import { togglePartnerBanner } from "../actions/banners";

const Main = styled.main`
  flex-grow: 1;
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

// wrap page in max width 1440px + (32px * 2) (width padding on each side)
export const PageWrapper = ({ children, ...rest }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Box
      px={isMobile || isTablet ? 1.5 : 2}
      maxWidth={1504}
      width="100%"
      {...rest}
    >
      {children}
    </Box>
  );
};

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  overflow-y: ${(props) => (props.disableScroll ? "hidden" : "visible")};
`;

const Layout = ({
  isAuthenticated,
  hasPartnerDiscount,
  partnerDiscountBannerTranslations,
  logout,
  fetchCart,
  children,
  shouldDisplayFooter,
  shouldUsePageWrapper,
  currentPath,
  headerProps,
  titleName,
  hasSelectedPlan,
  closeToast,
  toastProps,
  togglePartnerBanner,
  shouldDisplayHeader,
  languageCode,
  analyticsOpenedCartWidget,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPath]);
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.only("md"));
  const shouldDisplayMobileNav = isMobile && isMobileNavOpen;
  const {
    yourAccountMenuProps: { hasUserPaid },
  } = headerProps;
  return (
    <LayoutContainer disableScroll={shouldDisplayMobileNav}>
      <Helmet>
        <title>{titleName}</title>
        <html lang={languageCode} />
      </Helmet>
      {shouldDisplayHeader && (
        <Header
          isAuthenticated={isAuthenticated}
          logout={logout}
          isMobileNavOpen={isMobileNavOpen}
          setIsMobileNavOpen={setIsMobileNavOpen}
          fetchCart={fetchCart}
          analyticsOpenedCartWidget={analyticsOpenedCartWidget}
          {...headerProps}
        />
      )}
      <Modal />
      <TooltipModal />
      {shouldDisplayMobileNav && (
        <MobileNavContent
          onLogout={logout}
          hasSelectedPlan={hasSelectedPlan}
          closeMenu={() => setIsMobileNavOpen(false)}
          currentPath={currentPath}
          hasUserPaid={hasUserPaid}
          isAuthenticated={isAuthenticated}
          {...headerProps}
        />
      )}
      <Main id="content">
        <PartnerDiscountBanner
          isVisible={hasPartnerDiscount}
          toggle={togglePartnerBanner}
          translations={partnerDiscountBannerTranslations}
        />
        {shouldUsePageWrapper ? (
          <PageWrapper flexGrow={1} isMobile={isMobile} isTablet={isTablet}>
            {children}
          </PageWrapper>
        ) : (
          children
        )}

        {shouldDisplayFooter ? (
          <Footer isDesktop={isDesktop} />
        ) : (
          <BottomNav disabled={!isAuthenticated} />
        )}
      </Main>
      <EmailPreviewModal />
      <Toast onClose={closeToast} {...toastProps} />
    </LayoutContainer>
  );
};

export default connect(layoutProps, {
  logout,
  closeToast,
  fetchCart,
  togglePartnerBanner,
  analyticsOpenedCartWidget,
})(Layout);
