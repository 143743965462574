import R from "ramda";
import { createStructuredSelector, createSelector } from "reselect";
import { getLocation } from "connected-react-router";

import { selectAboutYouStatus } from "./about-you";
import { selectYourFamilyStatus } from "./your-family";
import { selectYourEstateStatus } from "./your-estate";
import { selectArrangementsStatus } from "./your-arrangements";
import { selectSpouseEstateStatus } from "./spouse-estate";
import { selectSpouseArrangementsStatus } from "./spouse-arrangements";

import {
  ABOUT_YOU_SECTIONS,
  YOUR_FAMILY_SECTIONS,
  YOUR_ESTATE_SECTIONS,
  YOUR_ARRANGEMENTS_SECTIONS,
  SPOUSE_ESTATE_SECTIONS,
  SPOUSE_ARRANGEMENTS_SECTIONS,
} from "../constants/navigation";
import {
  FAMILY_RECOMMENDED_PATH,
  MARITAL_STATUS_PATH,
  PREMIUM_RECOMMENDED_PATH,
} from "../constants/routes";

const PATHS_TO_DISPLAY_NAV = [
  ...ABOUT_YOU_SECTIONS,
  ...YOUR_FAMILY_SECTIONS,
  ...YOUR_ESTATE_SECTIONS,
  ...YOUR_ARRANGEMENTS_SECTIONS,
  ...SPOUSE_ESTATE_SECTIONS,
  ...SPOUSE_ARRANGEMENTS_SECTIONS,
  FAMILY_RECOMMENDED_PATH,
  PREMIUM_RECOMMENDED_PATH,
];

const selectShouldDisplayNav = createSelector(getLocation, ({ pathname }) => {
  return R.contains(pathname, PATHS_TO_DISPLAY_NAV);
});

const selectCurrentSection = createSelector(getLocation, ({ pathname }) => {
  const isAboutYou = R.contains(pathname, ABOUT_YOU_SECTIONS);
  const isYourFamily = R.contains(pathname, YOUR_FAMILY_SECTIONS);
  const isYourEstate = R.contains(pathname, YOUR_ESTATE_SECTIONS);
  const isYourArrangements = R.contains(pathname, YOUR_ARRANGEMENTS_SECTIONS);
  const isSpouseEstate = R.contains(pathname, SPOUSE_ESTATE_SECTIONS);
  const isSpouseArrangements = R.contains(
    pathname,
    SPOUSE_ARRANGEMENTS_SECTIONS,
  );
  if (isAboutYou) return "aboutYou";
  if (isYourFamily) return "yourFamily";
  if (isYourEstate) return "yourEstate";
  if (isYourArrangements) return "yourArrangements";
  if (isSpouseEstate) return "spouseEstate";
  if (isSpouseArrangements) return "spouseArrangements";
  return "default";
});

const selectSectionProps = createSelector(
  selectCurrentSection,
  selectAboutYouStatus,
  selectYourFamilyStatus,
  selectYourEstateStatus,
  selectArrangementsStatus,
  selectSpouseEstateStatus,
  selectSpouseArrangementsStatus,
  (
    currentSection,
    aboutYouSectionProps,
    yourFamilySectionProps,
    yourEstateSectionProps,
    yourArrangementsSectionProps,
    spouseEstateSectionProps,
    spouseArrangementsSectionProps,
  ) => {
    switch (currentSection) {
      case "aboutYou":
        return aboutYouSectionProps;
      case "yourFamily":
        return yourFamilySectionProps;
      case "yourEstate":
        return yourEstateSectionProps;
      case "yourArrangements":
        return yourArrangementsSectionProps;
      case "spouseEstate":
        return spouseEstateSectionProps;
      case "spouseArrangements":
        return spouseArrangementsSectionProps;
      default:
        return {};
    }
  },
);

const selectProgress = createSelector(
  selectSectionProps,
  R.propOr(0, "progress"),
);

const selectNavigationTranslations = createSelector(
  selectSectionProps,
  R.prop("translations"),
);

export const selectNavigationHistory = R.pathOr({}, [
  "navigation",
  "navigationHistory",
]);

export const selectNextOverviewPath = R.pathOr("", [
  "navigation",
  "nextOverviewPath",
]);

export const selectNextPathAfterPlansGuidancePages = () => MARITAL_STATUS_PATH;

export const navigationProps = createStructuredSelector({
  shouldDisplayNav: selectShouldDisplayNav,
  progress: selectProgress,
  translations: selectNavigationTranslations,
});
