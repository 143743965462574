import React from "react";
import { reduxForm, FieldArray } from "redux-form";
import { useMediaQuery, useTheme } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import { Form } from "../../ui/Form";
import BinaryRadioField from "../../ui/inputs/BinaryRadioField";
import NameFields from "../../ui/inputs/NameFields";
import { ErrorMessage } from "../../ui/Error";
import { FieldArrayRenderer } from "../../ui/inputs/FieldArrayRenderer";
import { useValidateSpouseIsExecutorMemo } from "../../ui/inputs/validations";
import { Body, SmallBody, SmallExternalLink } from "../../ui/Typography";

import {
  EXECUTORS_FORM_ID,
  EXECUTORS_FIELD_ID,
  SPOUSE_IS_EXECUTOR_FIELD_ID,
} from "../../../constants/forms";
import { displayTooltipModal } from "../../../actions/tooltip-modal";

const Executor = ({ id, shouldAutoFocus, translations }) => {
  return (
    <NameFields
      useTheir
      fieldName={id}
      autoFocus={shouldAutoFocus}
      translations={translations}
    />
  );
};

const ProvincialWarning = ({ text, link, addInfo }) => {
  return (
    <Grid container justify="center" spacing={2}>
      <Grid xs={12} md={8} lg={6}>
        <Box mt={1.5} textAlign="center">
          <SmallBody margin="16px" align="center">
            {text}
          </SmallBody>
          {link && (
            <SmallExternalLink target="_blank" href={link} text={addInfo} />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

const ExecutorsForm = ({
  error,
  handleSubmit,
  backLink,
  change,
  hasSpouse,
  appointeeLevelIndexs,
  isSpouseExecutor,
  isLoading,
  translations,
  tooltipKey,
  shouldShowHelperText,
}) => {
  const {
    provinceSpecificInfo,
    provinceSpecificLink,
    userSpouseExecutorQuestion,
    subQuestionSpouse,
    addInfo,
  } = translations;
  const theme = useTheme();
  const isTabletDown = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Form
      onSubmit={handleSubmit}
      backLink={backLink}
      translations={translations}
      isLoading={isLoading}
      HeaderComponent={provinceSpecificInfo && ProvincialWarning}
      text={provinceSpecificInfo}
      link={provinceSpecificLink}
      addInfo={addInfo}
      tooltipKey={tooltipKey}
      reverseWrap={isTabletDown}
    >
      {hasSpouse && (
        <>
          <Body data-hj-suppress>{userSpouseExecutorQuestion}</Body>
          <BinaryRadioField
            name={SPOUSE_IS_EXECUTOR_FIELD_ID}
            translations={translations}
            validate={useValidateSpouseIsExecutorMemo(translations)}
            optionList={translations.optionList}
          />
        </>
      )}
      <FieldArray
        handleChange={change}
        name={EXECUTORS_FIELD_ID}
        rerenderOnEveryChange
        component={FieldArrayRenderer}
        helperText={shouldShowHelperText && subQuestionSpouse}
        RenderedComponent={Executor}
        hasSpouse={hasSpouse}
        spouseIsPrimary={isSpouseExecutor}
        appointeeLevelIndexs={appointeeLevelIndexs}
        translations={translations}
      />
      <Box mb={2}>
        <ErrorMessage error={error} />
      </Box>
    </Form>
  );
};

export default reduxForm({
  form: EXECUTORS_FORM_ID,
})(ExecutorsForm);
