import React from "react";
import SvgIcon from "./SvgIcon";

const PlusIcon = ({ title, width = 24, height = 24 }) => {
  return (
    <SvgIcon
      title={title}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z"
        fill="#E2F6FF"
      />
      <path
        d="M12 7.83331V16.1666"
        stroke="#0064DC"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M7.83331 12H16.1666"
        stroke="#0064DC"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};

export default PlusIcon;
