export const percentageValidation = (allocations) => {
  if (!allocations) return 0;

  const totalPercentage = allocations.reduce(
    (acc, cur) => acc + Number(cur.amount || 0),
    0,
  );

  const percentageLeftover = (100 - totalPercentage).toFixed(2);
  if (Math.abs(percentageLeftover) === 0.0) {
    return false;
  }

  return percentageLeftover;
};

export const getAllocationsErrorMessage = (
  percentageLeftover,
  translations,
) => {
  const errorTranslation =
    percentageLeftover < 0
      ? translations.errors.overPercentage
      : translations.errors.underPercentage;

  const errorMessage = errorTranslation.replace(
    "X",
    Math.abs(percentageLeftover),
  );

  return errorMessage;
};

const getInvalidPercentageError = (values, props) => {
  const { allocations } = values;
  const { translations } = props;

  const percentageLeftover = percentageValidation(allocations);

  if (percentageLeftover) {
    // async validation on redux form only works if you "throw" the error
    // if we use "new Error" it will not understand, that is why lint is disabled
    // eslint-disable-next-line no-throw-literal
    throw {
      // avoid showing a form level error if the form is already in an invalid state
      // this will prevent duplicate error messages but keep the fields highlighted
      _error: getAllocationsErrorMessage(percentageLeftover, translations),
    };
  }
};

// fakePromise is just to satisfy redux-form async validation requirements
const fakePromise = () => new Promise((resolve) => setTimeout(resolve, 1));

export const asyncValidateAllocations = (values, _, props) => {
  return fakePromise().then(() => getInvalidPercentageError(values, props));
};
