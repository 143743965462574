import R from "ramda";
import { createStructuredSelector, createSelector } from "reselect";
import { getFormValues } from "redux-form";
import { selectHasLoadedDataById, selectIsLoadingById } from "./requests";
import { selectTranslations } from "./translations";

import { selectIsCouplesPlan } from "./plans";
import {
  selectIsCurrentProvinceSelectedByCode,
  selectAgeOfMajority,
} from "./provinces";
import { selectCharitiesOptionList, selectCharities } from "./donations";
import {
  selectIsAddingCharity,
  selectIsAddingPerson,
  selectIsAddingBeneficiary,
  selectShouldDisplayAllocationHelper,
  selectIsCustomCharity,
  selectCurrentAllocationCardIndex,
} from "./beneficiary";

import {
  PRIMARY_BENEFICIARY_PATH,
  ALLOCATIONS_GUIDANCE_PATH,
} from "../constants/routes";

import {
  PRIMARY_BENEFICIARY_FORM_ID,
  PRIMARY_BENEFICIARY_FIELD_ID,
  ALLOCATIONS_FORM_ID,
  INHERITANCE_FORM_ID,
  PREDECEASE_FORM_ID,
  PRIMARY_BENE_TO_SPOUSE,
  PREDECEASE_FIELD_ID,
  AGE_MILESTONE_FIELD_ID,
  INHERITANCE_AGES_FIELD_ID,
  ALLOCATIONS_FIELD_ID,
  DONATIONS_FORM_ID,
} from "../constants/forms";
import {
  selectSpouseFullName,
  selectHasSpouse,
  selectMaritalStatus,
} from "./spouse";
import {
  replaceSpouseSubtextOptions,
  replaceSpouseName,
} from "../utilities/name";
import {
  buildAllocationsChartData,
  getAllocationName,
} from "../utilities/allocations";
import { MODAL_REMOVE_BENEFICIARY } from "../constants/modal";

const selectYourEstateData = R.pathOr({}, ["yourEstate"]);

export const selectPrimaryBeneficiary = createSelector(
  selectYourEstateData,
  R.prop(PRIMARY_BENEFICIARY_FIELD_ID),
);
export const selectAllocations = createSelector(
  selectYourEstateData,
  R.prop(ALLOCATIONS_FIELD_ID),
);
export const selectPredecease = createSelector(
  selectYourEstateData,
  R.prop(PREDECEASE_FIELD_ID),
);

export const selectAgeMilestone = createSelector(
  selectYourEstateData,
  R.prop(AGE_MILESTONE_FIELD_ID),
);

export const selectInheritanceAges = createSelector(
  selectYourEstateData,
  R.prop(INHERITANCE_AGES_FIELD_ID),
);

export const selectPrimaryBeneficiaryForm = createSelector(
  selectPrimaryBeneficiary,
  (primaryBeneficiary) => ({
    [PRIMARY_BENEFICIARY_FIELD_ID]: primaryBeneficiary,
  }),
);
export const selectAllocationsForm = createSelector(
  selectAllocations,
  (allocations) => ({ [ALLOCATIONS_FIELD_ID]: allocations }),
);
export const selectPredeceaseForm = createSelector(
  selectPredecease,
  (predecease) => ({ [PREDECEASE_FIELD_ID]: predecease }),
);

export const selectInheritanceForm = createSelector(
  selectAgeMilestone,
  selectInheritanceAges,
  (ageMilestone, inheritanceAges) => {
    return {
      [AGE_MILESTONE_FIELD_ID]: ageMilestone,
      age1: inheritanceAges[0]?.age,
      age2: inheritanceAges[1]?.age,
      age3: inheritanceAges[2]?.age,
      percentage1: inheritanceAges[0]?.percentage,
      percentage2: inheritanceAges[1]?.percentage,
      percentage3: inheritanceAges[2]?.percentage,
    };
  },
);

const selectIsPrimaryBeneficiarySpouse = createSelector(
  selectPrimaryBeneficiary,
  (primaryBeneficiary) => primaryBeneficiary === PRIMARY_BENE_TO_SPOUSE,
);

export const selectIsPrimaryBeneficiaryCompleted = createSelector(
  selectPrimaryBeneficiary,
  selectHasSpouse,
  (primaryBeneficiary, hasSpouse) => {
    return (hasSpouse && !primaryBeneficiary) || !hasSpouse;
  },
);

const selectHasMultipleAllocations = createSelector(
  selectAllocations,
  (allocations) => allocations.length > 1,
);

const selectBackLink = createSelector(selectHasSpouse, (hasSpouse) =>
  hasSpouse ? PRIMARY_BENEFICIARY_PATH : ALLOCATIONS_GUIDANCE_PATH,
);

// NOTE: this is not select from server state, only local redux form
export const selectFormAllocations = createSelector(
  getFormValues(ALLOCATIONS_FORM_ID),
  R.propOr([], ALLOCATIONS_FIELD_ID),
);

export const selectAllocationsTranslations = createSelector(
  selectTranslations([
    "global",
    "navigation",
    DONATIONS_FORM_ID,
    ALLOCATIONS_FORM_ID,
  ]),
  selectHasSpouse,
  selectSpouseFullName,
  selectIsCouplesPlan,
  selectIsPrimaryBeneficiarySpouse,
  (
    baseTranslations,
    hasSpouse,
    spouseFullName,
    isCouplesPlan,
    isPrimaryBeneficiarySpouse,
  ) => {
    const {
      pageTitleSpouseCouplesToSpouse,
      pageTitleSpouseCouplesToSecondary,
      pageTitleSpouseNonCouplesToSpouse,
      pageTitleSpouseNonCouplesToSecondary,
      pageTitleDefault,
    } = baseTranslations;

    let pageTitle = pageTitleDefault;

    if (hasSpouse) {
      if (isCouplesPlan) {
        pageTitle = isPrimaryBeneficiarySpouse
          ? pageTitleSpouseCouplesToSpouse
          : pageTitleSpouseCouplesToSecondary;
      } else {
        pageTitle = isPrimaryBeneficiarySpouse
          ? pageTitleSpouseNonCouplesToSpouse.replace(
              "SPOUSE_NAME",
              spouseFullName,
            )
          : pageTitleSpouseNonCouplesToSecondary;
      }
    }

    return {
      ...baseTranslations,
      pageTitle,
    };
  },
);

export const selectRemoveAllocationsTranslations = createSelector(
  selectTranslations(["notice"]),
  selectCurrentAllocationCardIndex,
  getFormValues(ALLOCATIONS_FORM_ID),
  selectCharities,
  (baseTranslations, index, formValues, charitiesList) => {
    const selectRemoveTranslations = R.path([MODAL_REMOVE_BENEFICIARY])(
      baseTranslations,
    );
    if (formValues && typeof index === "number") {
      const { allocations } = formValues;
      const allocationCard = allocations[index];
      const beneficiaryName = getAllocationName(allocationCard, charitiesList);
      const paragraph1 = selectRemoveTranslations.paragraph1.replace(
        "BENEFICIARY_NAME",
        beneficiaryName,
      );
      return {
        ...selectRemoveTranslations,
        paragraph1,
      };
    }
    return {};
  },
);

const selectAllocationsPrimaryTranslations = createSelector(
  selectTranslations(["global", PRIMARY_BENEFICIARY_FORM_ID]),
  selectIsCouplesPlan,
  selectSpouseFullName,
  (translations, isCouplesPlan, spouseName) => {
    const optionList = isCouplesPlan
      ? translations.radioOptionsCouples
      : replaceSpouseSubtextOptions(
          translations.radioOptionsPrimary,
          spouseName,
        );

    const pageTitle = isCouplesPlan
      ? replaceSpouseName(translations.pageTitleCouples, spouseName)
      : replaceSpouseName(translations.pageTitle, spouseName);

    const pageDescription = isCouplesPlan
      ? translations.pageDescriptionCouples
      : "";

    const infoBox = isCouplesPlan
      ? translations.infoBox.couples
      : translations.infoBox.nonCouples;

    return {
      ...translations,
      optionList,
      pageTitle,
      pageDescription,
      infoBox,
    };
  },
);

const selectAllocationsPredeceaseTranslations = createSelector(
  selectTranslations(["global", PREDECEASE_FORM_ID]),
  selectHasMultipleAllocations,
  (translations, hasMultipleAllocations) => {
    const { optionChildren, optionBeneficiaries } = translations;
    const optionList = hasMultipleAllocations
      ? [optionChildren, optionBeneficiaries]
      : [optionChildren];
    return {
      ...translations,
      optionList,
    };
  },
);

const selectIsFormInheritanceSpecificAges = createSelector(
  getFormValues(INHERITANCE_FORM_ID),
  (formValues) => {
    const ageMilestone = R.propOr("", AGE_MILESTONE_FIELD_ID, formValues);
    return ageMilestone === "specific_ages";
  },
);

const selectFormInheritanceTranslations = createSelector(
  selectTranslations(["global", "provinces", INHERITANCE_FORM_ID]),
  (translations) => {
    const [infoBox] = translations.infoBox;
    return {
      ...translations,
      infoBox: [
        {
          ...infoBox,
        },
      ],
    };
  },
);

export const selectFormInheritanceTotalPercentage = createSelector(
  getFormValues(INHERITANCE_FORM_ID),
  (formValues) => {
    const percentage1 = R.propOr(0, "percentage1", formValues);
    const percentage2 = R.propOr(0, "percentage2", formValues);
    const percentage3 = R.propOr(0, "percentage3", formValues);
    const total =
      Number(percentage1) + Number(percentage2) + Number(percentage3);
    if (!total || total < 0) return 0;
    return Number.isInteger(total) ? total : Number(total.toFixed(2));
  },
);

export const selectAllocationsFormTotalPercentage = createSelector(
  getFormValues(ALLOCATIONS_FORM_ID),
  (formValues) => {
    if (!formValues) {
      return 0;
    }
    const { allocations } = formValues;
    // allocations that are removed will have the "._destroy" flag
    // and thus will not count to the total sum
    const total = allocations
      .filter((a) => a._destroy === undefined)
      .reduce((acc, cur) => acc + Number(cur.amount), 0);
    if (!total || total < 0) return 0;
    return Number.isInteger(total) ? total : Number(total.toFixed(2));
  },
);

export const selectAllocationsFormValidValues = createSelector(
  getFormValues(ALLOCATIONS_FORM_ID),
  (formValues) => {
    if (formValues) {
      const { allocations } = formValues;
      return allocations.filter((a) => a._destroy === undefined);
    }
    return [];
  },
);

const selectHasValidAllocations = createSelector(
  selectAllocationsFormValidValues,
  (allocations) => allocations && allocations.length > 0,
);

export const selectInheritanceAgeMilestoneVisibilityState = R.pathOr({}, [
  "formUi",
  "toggleInheritanceAgeMilestoneVisibility",
]);

const selectLastValidAllocationsState = R.pathOr({}, [
  "formUi",
  "lastAllocationsValidState",
]);

export const selectNewPieChartAnimationKey = createSelector(
  R.pathOr(0, ["formUi", "pieChartAnimationKey"]),
  (count) => count + 1,
);

const selectChartData = createSelector(
  selectLastValidAllocationsState,
  selectCharities,
  (lastValidAllocationsState, charities) => {
    if (lastValidAllocationsState && lastValidAllocationsState.length) {
      return buildAllocationsChartData(lastValidAllocationsState, charities);
    }
    return [];
  },
);

const selectRequiresFamilyPatrimonyInfo = createSelector(
  selectMaritalStatus,
  selectIsCurrentProvinceSelectedByCode("QC"),
  (maritalStatus, isQuebec) => {
    return maritalStatus === "married" && isQuebec;
  },
);

export const selectAddBeneficiaryFormProps = createStructuredSelector({
  isAddingCharity: selectIsAddingCharity,
  isAddingPerson: selectIsAddingPerson,
  isAddingBeneficiary: selectIsAddingBeneficiary,
  isCustomCharity: selectIsCustomCharity,
  charitiesOptionList: selectCharitiesOptionList,
  isCouplesPlan: selectIsCouplesPlan,
  translations: selectAllocationsTranslations,
});

export const selectRemoveBeneficiaryProps = createStructuredSelector({
  translations: selectRemoveAllocationsTranslations,
});

export const allocationsPageProps = createStructuredSelector({
  hasLoaded: selectHasLoadedDataById(ALLOCATIONS_FORM_ID),
  isLoading: selectIsLoadingById(ALLOCATIONS_FORM_ID),
  translations: selectAllocationsTranslations,
  totalPercentage: selectAllocationsFormTotalPercentage,
  isAddingBeneficiary: selectIsAddingBeneficiary,
  allocations: selectAllocationsFormValidValues,
  chartAllocations: selectChartData,
  hasAllocations: selectHasValidAllocations,
  pieChartAnimationKey: selectNewPieChartAnimationKey,
  isPrimaryBeneficiarySpouse: selectIsPrimaryBeneficiarySpouse,
  addBeneficiaryForm: selectAddBeneficiaryFormProps,
  allocationsForm: createStructuredSelector({
    shouldDisplayHelp: selectShouldDisplayAllocationHelper,
    charities: selectCharities,
    backLink: selectBackLink,
    translations: selectAllocationsTranslations,
  }),
});
export const primaryBeneficiaryPageProps = createStructuredSelector({
  hasLoaded: selectHasLoadedDataById(PRIMARY_BENEFICIARY_FORM_ID),
  isLoading: selectIsLoadingById(PRIMARY_BENEFICIARY_FORM_ID),
  translations: selectAllocationsPrimaryTranslations,
  requiresFamilyPatrimonyInfo: selectRequiresFamilyPatrimonyInfo,
});
export const predeceasePageProps = createStructuredSelector({
  hasLoaded: selectHasLoadedDataById(PREDECEASE_FORM_ID),
  isLoading: selectIsLoadingById(PREDECEASE_FORM_ID),
  hasMultipleAllocations: selectHasMultipleAllocations,
  translations: selectAllocationsPredeceaseTranslations,
});
export const inheritancePageProps = createStructuredSelector({
  hasLoaded: selectHasLoadedDataById(INHERITANCE_FORM_ID),
  isLoading: selectIsLoadingById(INHERITANCE_FORM_ID),
  isInheritanceSpecificAges: selectIsFormInheritanceSpecificAges,
  ageOfMajority: selectAgeOfMajority,
  totalPercentage: selectFormInheritanceTotalPercentage,
  displayMilestone2: createSelector(
    selectInheritanceAgeMilestoneVisibilityState,
    (milestonesVisibility) => milestonesVisibility.displayMilestone2,
  ),
  displayMilestone3: createSelector(
    selectInheritanceAgeMilestoneVisibilityState,
    (milestonesVisibility) => milestonesVisibility.displayMilestone3,
  ),
  translations: selectFormInheritanceTranslations,
});
