import R from "ramda";
import { createStructuredSelector, createSelector } from "reselect";
import { selectTranslations } from "./translations";
import {
  selectLastInvitedUser,
  selectLastRevokedUser,
  selectLastResentUser,
} from "./your-account";
import { selectUserEmail } from "./auth";
import { selectOrderPlanSymbol, selectIsOrderPlanBundle } from "./utils/order";
import {
  selectLastAppointeeFullName,
  selectLastAppointeeEmail,
} from "./appointees";
import { selectLastModifiedAsset } from "./asset-list";
import {
  ADD_PERSON_ALLOCATION_SUCCESS,
  ADD_CHARITY_ALLOCATION_SUCCESS,
  ADD_PERSON_BACKUP_SUCCESS,
  ADD_CHARITY_BACKUP_SUCCESS,
  UPDATED_ALLOCATION_SUCCESS,
  REMOVED_ALLOCATION_SUCCESS,
  ADD_EXECUTOR_SUCCESS,
  EDIT_EXECUTOR_SUCCESS,
  REMOVE_EXECUTOR_SUCCESS,
  ADD_CHILD_SUCCESS,
  EDIT_CHILD_SUCCESS,
  REMOVE_CHILD_SUCCESS,
  ADD_SPOUSE_SUCCESS,
  EDIT_SPOUSE_SUCCESS,
  REMOVE_SPOUSE_SUCCESS,
  ADD_PET_SUCCESS,
  EDIT_PET_SUCCESS,
  REMOVE_PET_SUCCESS,
  ADD_CHILDREN_GUARDIAN_SUCCESS,
  EDIT_CHILDREN_GUARDIAN_SUCCESS,
  REMOVE_CHILDREN_GUARDIAN_SUCCESS,
  ADD_CHILDREN_GUARDIAN_AND_SPOUSE_SUCCESS,
  EDIT_CHILDREN_GUARDIAN_AND_SPOUSE_SUCCESS,
  REMOVE_CHILDREN_GUARDIAN_AND_SPOUSE_SUCCESS,
  REMOVE_PET_GUARDIAN_SUCCESS,
  EDIT_PET_GUARDIAN_SUCCESS,
  ADD_PET_GUARDIAN_SUCCESS,
  REMOVE_PROPERTY_ATTORNEY_SUCCESS,
  EDIT_PROPERTY_ATTORNEY_SUCCESS,
  ADD_PROPERTY_ATTORNEY_SUCCESS,
  REMOVE_PERSONAL_ATTORNEY_SUCCESS,
  EDIT_PERSONAL_ATTORNEY_SUCCESS,
  ADD_PERSONAL_ATTORNEY_SUCCESS,
} from "../constants/toasts";
import {
  selectLastAddedPersonAllocation,
  selectLastAddedCharityAllocation,
  selectUpdatedAllocation,
  selectRemovedAllocation,
} from "./beneficiary";
import { selectDiscountLabel } from "./utils/discounts";
import { selectLastSelectedExecutorData } from "./executors";
import { getExecutorNameAndTitle } from "../utilities/executors";
import { childFullName, selectLastSelectedChildData } from "./children";
import { getTranspiledMessage } from "../utilities/helpers";
import {
  selectSpouseBasicInfoFormValues,
  selectSpouseFullName,
} from "./spouse";
import { selectLastSelectedPetData } from "./pets";
import { selectLastSelectedChildrenGuardianData } from "./children-guardians";
import {
  selectLastSelectedPetGuardianData,
  petGuardianFullName,
  petGuardianSpouseFullName,
} from "./pet-guardians";
import { selectLastSelectedPropertyAttorneyFullNameAndTitle } from "./property-attorneys";
import { selectLastSelectedPersonalAttorneyFullNameAndTitle } from "./personal-attorneys";

export const selectIsToastVisible = R.path(["toast", "visible"]);
export const selectToastKey = R.path(["toast", "key"]);

const transformToastTranslations = (key, toastCopy, data, translations) => {
  const {
    currentEmail,
    lastInvitedUser,
    lastRevokedUser,
    lastResentUser,
    lastAppointeeFullName,
    lastAppointeeEmail,
    lastAddedAsset,
    lastAddedPersonAllocation,
    lastAddedCharityAllocation,
    cartPlanSymbol,
    isBundlePlan,
    discountLabel,
    updatedAllocation,
    removedAllocation,
    lastSelectedExecutorData,
    lastSelectedChildData,
    spouseBasicInfoForm,
    spouseFullName,
    lastSelectedPetData,
    lastSelectedChildrenGuardianData,
    lastSelectedPetGuardianData,
    lastSelectedPropertyAttorneyFullNameAndTitle,
    lastSelectedPersonalAttorneyFullNameAndTitle,
  } = data;
  const { firstName, lastName, email } = lastInvitedUser;
  const revokedEmail = R.prop("email", lastRevokedUser);
  const revokedFullName = R.prop("fullName", lastRevokedUser);
  const resentEmail = R.prop("email", lastResentUser);
  const resentFullName = R.prop("fullName", lastResentUser);
  const planSymbol = isBundlePlan ? "bundle" : cartPlanSymbol;
  const spouseModalFullName = spouseBasicInfoForm
    ? `${spouseBasicInfoForm[0].firstName} ${
        spouseBasicInfoForm[0].middleName
          ? spouseBasicInfoForm[0].middleName
          : ""
      } ${spouseBasicInfoForm[0].lastName}`
    : "";
  const spouseRemovalName =
    spouseModalFullName !== undefined ? spouseModalFullName : spouseFullName;
  const translatedPlanName = R.pathOr(
    "",
    [planSymbol, "planName"],
    translations,
  );

  switch (key) {
    case "auth.emailChanged":
      return {
        body: toastCopy.body.replace("CURRENT_EMAIL", currentEmail),
      };
    case "bundleInvite.upgradeSuccess":
    case "cart.addSuccess":
      return {
        body: toastCopy.body.replace("PLAN_NAME", translatedPlanName),
      };
    case "cart.upgradeAddSuccess":
      return {
        body: toastCopy.body.replace("PLAN_NAME", translatedPlanName),
      };
    case "bundleInvite.inviteSuccess":
      return {
        body: toastCopy.body.replace(
          "INVITED_FIRST_NAME INVITED_LAST_NAME (INVITED_EMAIL)",
          `${firstName} ${lastName} (${email})`,
        ),
      };
    case "bundleInvite.inviteRevoked":
      return {
        body: toastCopy.body.replace(
          "FULL_NAME_EMAIL",
          `${revokedFullName}'s (${revokedEmail})`,
        ),
      };
    case "bundleInvite.resendInviteSuccess":
      return {
        body: toastCopy.body.replace(
          "INVITED_FULL_NAME's (INVITED_EMAIL)",
          `${resentFullName}'s (${resentEmail})`,
        ),
      };
    case "email.appointeeNotificationSentSuccess":
      return {
        body: toastCopy.body.replace(
          "APPOINTEE_FIRST_LAST_NAME (APPOINTEE_EMAIL)",
          `${lastAppointeeFullName} (${lastAppointeeEmail})`,
        ),
      };
    case "assetList.addSuccess":
    case "assetList.updateSuccess":
    case "assetList.deleteSuccess":
      return {
        body: toastCopy.body.replace(
          "ASSET_DESCRIPTION",
          lastAddedAsset.description,
        ),
      };
    case ADD_PERSON_ALLOCATION_SUCCESS:
    case ADD_PERSON_BACKUP_SUCCESS:
      return {
        body: toastCopy.body.replace(
          "BENEFICIARY_NAME",
          `${lastAddedPersonAllocation.firstName} ${lastAddedPersonAllocation.lastName}`,
        ),
      };
    case ADD_CHARITY_ALLOCATION_SUCCESS:
    case ADD_CHARITY_BACKUP_SUCCESS:
      return {
        body: toastCopy.body.replace(
          "BENEFICIARY_NAME",
          `${lastAddedCharityAllocation.charityName || ""}`,
        ),
      };
    case UPDATED_ALLOCATION_SUCCESS:
      return {
        body: toastCopy.body.replace(
          "BENEFICIARY_NAME",
          `${updatedAllocation || ""}`,
        ),
      };
    case REMOVED_ALLOCATION_SUCCESS:
      return {
        body: toastCopy.body.replace(
          "BENEFICIARY_NAME",
          `${removedAllocation || ""}`,
        ),
      };
    case "discount.applied":
      return {
        body: toastCopy.body.replace("DISCOUNT_LABEL", discountLabel),
      };
    case REMOVE_EXECUTOR_SUCCESS:
    case EDIT_EXECUTOR_SUCCESS:
    case ADD_EXECUTOR_SUCCESS:
      return {
        body: getExecutorNameAndTitle(toastCopy, lastSelectedExecutorData),
      };
    case ADD_CHILD_SUCCESS:
    case EDIT_CHILD_SUCCESS:
    case REMOVE_CHILD_SUCCESS:
      return {
        body: getTranspiledMessage(
          "{CHILD}",
          childFullName(lastSelectedChildData),
          toastCopy.body,
        ),
      };
    case ADD_SPOUSE_SUCCESS:
    case EDIT_SPOUSE_SUCCESS:
      return {
        body: getTranspiledMessage(
          "{SPOUSE}",
          spouseModalFullName,
          toastCopy.body,
        ),
      };
    case REMOVE_SPOUSE_SUCCESS:
      return {
        body: getTranspiledMessage(
          "{SPOUSE}",
          spouseRemovalName,
          toastCopy.body,
        ),
      };
    case REMOVE_PET_SUCCESS:
    case EDIT_PET_SUCCESS:
    case ADD_PET_SUCCESS:
      return {
        body: toastCopy.body.replace("{PET}", lastSelectedPetData.firstName),
      };
    case ADD_CHILDREN_GUARDIAN_SUCCESS:
    case EDIT_CHILDREN_GUARDIAN_SUCCESS:
    case REMOVE_CHILDREN_GUARDIAN_SUCCESS: {
      const guardianName = `${lastSelectedChildrenGuardianData.firstName} ${
        lastSelectedChildrenGuardianData.middleName || ""
      } ${lastSelectedChildrenGuardianData.lastName}`;
      return {
        body: getTranspiledMessage("{GUARDIAN}", guardianName, toastCopy.body),
      };
    }
    case ADD_CHILDREN_GUARDIAN_AND_SPOUSE_SUCCESS:
    case EDIT_CHILDREN_GUARDIAN_AND_SPOUSE_SUCCESS:
    case REMOVE_CHILDREN_GUARDIAN_AND_SPOUSE_SUCCESS: {
      const guardianName = `${lastSelectedChildrenGuardianData.firstName} ${
        lastSelectedChildrenGuardianData.middleName || ""
      } ${lastSelectedChildrenGuardianData.lastName}`;
      const guardianSpouseName = `${
        lastSelectedChildrenGuardianData.spouseFirstName
      } ${lastSelectedChildrenGuardianData.spouseMiddleName || ""} ${
        lastSelectedChildrenGuardianData.spouseLastName
      }`;
      const guardianFullNames = `${guardianName} & ${guardianSpouseName}`;
      return {
        body: getTranspiledMessage(
          "{GUARDIAN}",
          guardianFullNames,
          toastCopy.body,
        ),
      };
    }
    case REMOVE_PET_GUARDIAN_SUCCESS:
    case EDIT_PET_GUARDIAN_SUCCESS:
    case ADD_PET_GUARDIAN_SUCCESS: {
      const { withSpouse, noSpouse } = toastCopy.body;
      const { spouseFirstName } = lastSelectedPetGuardianData;

      const hasSpouse =
        spouseFirstName != null && spouseFirstName.trim() !== "";

      const petGuardianName = petGuardianFullName(lastSelectedPetGuardianData);
      const spouseName = petGuardianSpouseFullName(lastSelectedPetGuardianData);

      const bodyTranslated = hasSpouse
        ? withSpouse
            .replace("{PET_GUARDIAN}", petGuardianName)
            .replace("{SPOUSE_PET_GUARDIAN}", spouseName)
        : noSpouse.replace("{PET_GUARDIAN}", petGuardianName);
      return {
        body: bodyTranslated,
      };
    }
    case REMOVE_PROPERTY_ATTORNEY_SUCCESS:
    case EDIT_PROPERTY_ATTORNEY_SUCCESS:
    case ADD_PROPERTY_ATTORNEY_SUCCESS: {
      const {
        fullName,
        appointeeTitle,
      } = lastSelectedPropertyAttorneyFullNameAndTitle;
      return {
        body: toastCopy.body
          .replace("{PROPERTY_ATTORNEY}", fullName)
          .replace("{PROPERTY_APPOINTEE_TITLE}", appointeeTitle.toLowerCase()),
      };
    }
    case REMOVE_PERSONAL_ATTORNEY_SUCCESS:
    case EDIT_PERSONAL_ATTORNEY_SUCCESS:
    case ADD_PERSONAL_ATTORNEY_SUCCESS: {
      const {
        fullName,
        appointeeTitle,
      } = lastSelectedPersonalAttorneyFullNameAndTitle;
      return {
        body: toastCopy.body
          .replace("{PERSONAL_ATTORNEY}", fullName)
          .replace("{PERSONAL_APPOINTEE_TITLE}", appointeeTitle.toLowerCase()),
      };
    }
    default:
      return toastCopy;
  }
};

const selectToastData = createSelector(
  selectUserEmail,
  selectLastInvitedUser,
  selectLastRevokedUser,
  selectLastResentUser,
  selectLastAppointeeFullName,
  selectLastAppointeeEmail,
  selectLastModifiedAsset,
  selectLastAddedPersonAllocation,
  selectLastAddedCharityAllocation,
  selectOrderPlanSymbol("cart"),
  selectIsOrderPlanBundle("cart"),
  selectDiscountLabel,
  selectUpdatedAllocation,
  selectRemovedAllocation,
  selectLastSelectedExecutorData,
  selectLastSelectedChildData,
  selectSpouseBasicInfoFormValues,
  selectSpouseFullName,
  selectLastSelectedPetData,
  selectLastSelectedChildrenGuardianData,
  selectLastSelectedPetGuardianData,
  selectLastSelectedPropertyAttorneyFullNameAndTitle,
  selectLastSelectedPersonalAttorneyFullNameAndTitle,
  (
    currentEmail,
    lastInvitedUser,
    lastRevokedUser,
    lastResentUser,
    lastAppointeeFullName,
    lastAppointeeEmail,
    lastAddedAsset,
    lastAddedPersonAllocation,
    lastAddedCharityAllocation,
    cartPlanSymbol,
    isBundlePlan,
    discountLabel,
    updatedAllocation,
    removedAllocation,
    lastSelectedExecutorData,
    lastSelectedChildData,
    spouseBasicInfoForm,
    spouseFullName,
    lastSelectedPetData,
    lastSelectedChildrenGuardianData,
    lastSelectedPetGuardianData,
    lastSelectedPropertyAttorneyFullNameAndTitle,
    lastSelectedPersonalAttorneyFullNameAndTitle,
  ) => ({
    currentEmail,
    lastInvitedUser,
    lastRevokedUser,
    lastResentUser,
    lastAppointeeFullName,
    lastAppointeeEmail,
    lastAddedAsset,
    lastAddedPersonAllocation,
    lastAddedCharityAllocation,
    cartPlanSymbol,
    isBundlePlan,
    discountLabel,
    updatedAllocation,
    removedAllocation,
    lastSelectedExecutorData,
    lastSelectedChildData,
    spouseBasicInfoForm,
    spouseFullName,
    lastSelectedPetData,
    lastSelectedChildrenGuardianData,
    lastSelectedPetGuardianData,
    lastSelectedPropertyAttorneyFullNameAndTitle,
    lastSelectedPersonalAttorneyFullNameAndTitle,
  }),
);

const selectToastTranslations = createSelector(
  selectTranslations(["global", "toast", "plans"]),
  selectToastKey,
  selectToastData,
  (translations, key, toastData) => {
    const toastCopy = R.propOr({}, key, translations);
    const toastTranslations = transformToastTranslations(
      key,
      toastCopy,
      toastData,
      translations,
    );

    return {
      ...translations.default,
      ...toastTranslations,
    };
  },
);

export const toastProps = createStructuredSelector({
  isVisible: selectIsToastVisible,
  translations: selectToastTranslations,
});
