export const MODAL_DELETE_ACCOUNT = "yourAccount.deleteAccount";
export const MODAL_DELETE_ACCOUNT_GOOGLE_SSO =
  "yourAccount.deleteAccountGoogleSSO";
export const MODAL_INVITE_USER = "yourAccount.inviteUser";
export const FRENCH_NOT_AVAILABLE = "province.frenchNotAvailable";
export const MODAL_ADD_PHYSICAL_ASSET = "assetList.addPhysicalAsset";
export const MODAL_ADD_FINANCIAL_ASSET = "assetList.addFinancialAsset";
export const MODAL_ADD_INSURANCE_ASSET = "assetList.addInsuranceAsset";
export const MODAL_ADD_BENEFICIARY_PERSON = "allocations.addBeneficiaryPerson";
export const MODAL_EDIT_BENEFICIARY_PERSON =
  "allocations.editBeneficiaryPerson";
export const MODAL_ADD_BENEFICIARY_CHARITY =
  "allocations.addBeneficiaryCharity";
export const MODAL_EDIT_BENEFICIARY_CHARITY =
  "allocations.editBeneficiaryCharity";
export const MODAL_ADD_CUSTOM_BENEFICIARY_CHARITY =
  "allocations.addCustomBeneficiaryCharity";
export const MODAL_EDIT_BENEFICIARY_CUSTOM_CHARITY =
  "allocations.editBeneficiaryCustomCharity";
export const MODAL_REMOVE_BENEFICIARY = "allocations.removeBeneficiary";
export const MODAL_ADD_BACKUP_PERSON = "backups.addBeneficiaryPerson";
export const MODAL_OUT_OF_PROVINCE_WARNING_ASSET =
  "assetList.outOfProvinceAsset";
export const VOLUME_PARTNER_DISCOUNT_APPLIED =
  "partnerDiscount.volumePartnershipApplied";
export const REVSHARE_PARTNER_DISCOUNT_APPLIED =
  "partnerDiscount.revsharePartnershipApplied";
export const REVSHARE_UNLIMITED_UPDATES_PARTNER_DISCOUNT_APPLIED =
  "partnerDiscount.revshareUnlimitedUpdatesPartnershipApplied";
export const MODAL_ADD_EXECUTORS = "executors.modalAddExecutors";
export const MODAL_ADD_CHILD_FORM = "children.modalAddChild";
export const MODAL_EDIT_CHILD_FORM = "children.modalEditChildMiniForm";
export const MODAL_EDIT_EXECUTORS = "executors.modalEditExecutors";
export const MODAL_MANAGE_PAYMENT_METHOD = "yourAccount.managePaymentMethod";
export const MODAL_BNPL_EXPERIMENT = "checkout.buyNowPayLater";
export const MODAL_KLARNA_PROCESSING_PAYMENT = "checkout.klarnaProcessing";
export const MODAl_KLARNA_PAYMENT_FAILED = "checkout.klarnaFailed";
export const MODAL_CHANGE_SSO_PASSWORD = "yourAccount.changeSsoPassword";
export const MODAL_ADD_SPOUSE = "yourFamily.addSpouse";
export const MODAL_EDIT_SPOUSE = "yourFamily.editSpouse";
export const MODAL_ADD_PET = "yourFamily.addPet";
export const MODAL_EDIT_PET = "yourFamily.editPet";
export const MODAL_ADD_GUARDIAN = "yourFamily.addGuardian";
export const MODAL_EDIT_GUARDIAN = "yourFamily.editGuardian";
export const MODAL_ADD_PET_GUARDIAN = "yourFamily.addPetGuardians";
export const MODAL_EDIT_PET_GUARDIAN = "yourFamily.editPetGuardians";
export const MODAL_ADD_PROPERTY_ATTORNEY =
  "yourArrangements.addPropertyAttorney";
export const MODAL_EDIT_PROPERTY_ATTORNEY =
  "yourArrangements.editPropertyAttorney";
export const MODAL_ADD_PERSONAL_ATTORNEY =
  "yourArrangements.addPersonalAttorney";
export const MODAL_EDIT_PERSONAL_ATTORNEY =
  "yourArrangements.editPersonalAttorney";

export const TOOLTIP_MODAL_IMPORTANT_NOTE = "importantNotePage";
export const TOOLTIP_MODAL_ESSENTIALS_PLAN_UNDERLINE =
  "plans.essentials.underlineModal";
export const TOOLTIP_MODAL_PREMIUM_PLAN_UNDERLINE =
  "plans.premium.underlineModal";
export const TOOLTIP_MODAL_BUNDLE_PLAN_UNDERLINE =
  "plans.bundle.underlineModal";
export const TOOPTIP_MODAL_BUNDLE_SEPARATE_WILLS = "plans.bundle.separateWills";
export const TOOLTIP_MODAL_CHANGE_SSO_PASSWORD =
  "changeSsoPassword.underlineModal";
export const TOOLTIP_MODAL_CHILDREN = "yourFamily.children";
