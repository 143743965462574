import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/styles";
import { useMediaQuery } from "@material-ui/core";

import {
  H1,
  Body,
  MiceType,
  SmallExternalLink,
  SmallTextLink,
} from "../ui/Typography";
import { PageLayoutWithPrevious } from "../ui/PageLayout";
import { InfoBox } from "../ui/InfoBox";
import TrustIcons from "../ui/icons/TrustIcons";
import { RESIDENCE_PATH } from "../../constants/routes";
import { PRODUCTS_FORM_ID } from "../../constants/forms";
import { BaseAccordion as Accordion } from "../ui/Accordion";
import { TextButton } from "../ui/Button";
import UnlimitedUpdatesInfoBox from "../ui/UnlimitedUpdatesInfoBox";
import PlanCard from "../plans/PlanCard";

const PlansOptions = ({
  updatePlans,
  availablePlans,
  isUserUpgrading,
  isNewBrunswickSelected,
  isQuebecSelected,
  isFrenchSelected,
  translations,
  analyticsClickExternalLink,
  setLoadingPlan,
  languageCode,
  displayTooltipModal,
  showExclusiveOffer,
  prices,
  strikeThroughPrices,
  recommendedForYou,
  goBack,
  showOverviewLink = true,
  overviewLinkPath,
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <PageLayoutWithPrevious>
      <TextButton
        text={translations.button.previous}
        onClick={goBack}
        displayArrowLeft
      />
      <H1>{translations.pageTitle}</H1>
      <Box maxWidth={800} mx="auto">
        <Body align="center">{translations.description}</Body>
        {!isQuebecSelected && (
          <Box display="flex" justifyContent="center">
            {window.env.FEATURE_PLANS_RECO_PAGE === "enabled" &&
              showOverviewLink && (
                <Box mx={1}>
                  <SmallTextLink
                    $displayArrowLeft
                    to={overviewLinkPath}
                    $targetBlank={false}
                    text={translations.backToOverview}
                    $hoverColor="willfulBlueDark2.main"
                  />
                </Box>
              )}
            <Box mx={1}>
              <SmallExternalLink
                href="#faq"
                targetBlank={false}
                text={
                  <>
                    <Typography variant="srOnly">
                      {translations.scrollTo}
                    </Typography>
                    {translations.faqLink}
                  </>
                }
                $hoverColor="willfulBlueDark2.main"
              />
            </Box>
          </Box>
        )}
      </Box>
      <Box pt={4.5}>
        <Box display="flex">
          <Grid justify="center" container spacing={2}>
            {isNewBrunswickSelected && <Grid item md={12} lg={4} />}
            {availablePlans.map((plan) => (
              <Grid key={plan.jsxKey} item sm={12} md={7} lg={4}>
                <PlanCard
                  {...plan}
                  languageCode={languageCode}
                  translations={translations}
                  analyticsClickExternalLink={analyticsClickExternalLink}
                  isQuebecSelected={isQuebecSelected}
                  isUpgrade={isUserUpgrading}
                  onClick={(quantity) => {
                    setLoadingPlan({ ...plan, quantity });
                    updatePlans({
                      isBundle: plan.isBundle,
                      symbol: plan.symbol,
                      quantity,
                      formId: PRODUCTS_FORM_ID,
                    });
                  }}
                  price={prices[plan.jsxKey]}
                  strikeThroughPrice={strikeThroughPrices[plan.jsxKey]}
                  showExclusiveOffer={showExclusiveOffer}
                  recommendedForYou={recommendedForYou === plan.jsxKey}
                  displayTooltipModal={displayTooltipModal}
                />
              </Grid>
            ))}
            {isNewBrunswickSelected && (
              <Grid item md={12} lg={4}>
                <Box m={2.5}>
                  <InfoBox
                    title={translations.essentialsOnlyProvinceInfobox.title}
                    paragraph={
                      translations.essentialsOnlyProvinceInfobox.paragraph
                    }
                    linkUrl={RESIDENCE_PATH}
                    linkText={
                      translations.essentialsOnlyProvinceInfobox.linkText
                    }
                  />
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>

      <Box mt={2} display="flex" justifyContent="center">
        <UnlimitedUpdatesInfoBox
          title={translations.unlimitedUpdatesInfoBox.title}
          paragraph={translations.unlimitedUpdatesInfoBox.paragraph}
          isDesktop={isDesktop}
        />
      </Box>

      <Box
        mt={isDesktop ? 3.5 : 0.5}
        mb={isDesktop ? 7.5 : 4.5}
        display="flex"
        justifyContent="center"
      >
        <TrustIcons
          isFrench={isFrenchSelected}
          translations={translations}
          shouldIncludeThirtyDay
          shouldIncludeDragonsDen
        />
      </Box>
      {!isQuebecSelected && (
        <Box mt={7.5} display="flex" justifyContent="center">
          <Grid item sm={12} md={8} lg={6}>
            <Accordion scrollToId="faq" translations={translations} />
          </Grid>
        </Box>
      )}
      {isQuebecSelected && (
        <Grid item>
          <Box mt={2} justifyContent="center">
            <MiceType color="willfulGrey.main">
              {translations.footNote}
            </MiceType>
          </Box>
        </Grid>
      )}
    </PageLayoutWithPrevious>
  );
};

export default PlansOptions;
