import R from "ramda";
import { createStructuredSelector, createSelector } from "reselect";
import { getFormValues } from "redux-form";
import { selectHasLoadedDataById, selectIsLoadingById } from "./requests";

import {
  PROPERTY_ATTORNEYS_FORM_ID,
  PROPERTY_ATTORNEYS_FIELD_ID,
  SPOUSE_IS_PROPERTY_ATTORNEY_FIELD_ID,
} from "../constants/forms";
import { selectTranslations } from "./translations";
import { selectHasSpouse } from "./spouse";
import { selectAppointeeLevelIndexs } from "./utils/form-appointees";
import { selectIsFieldComplete } from "./utils/progress";
import { selectPropertyAttorneysTranslations } from "./property-attorneys";
import { selectSelectedProvince } from "./provinces";

const selectSpouseArrangementsData = R.pathOr({}, ["spouseArrangements"]);

export const selectFormSpouseIsPropertyAttorney = createSelector(
  getFormValues(PROPERTY_ATTORNEYS_FORM_ID),
  (formValues) =>
    R.propOr(false, SPOUSE_IS_PROPERTY_ATTORNEY_FIELD_ID)(formValues),
);

// TODO: Add homestead act validation for provinces that require 2 PA's
export const selectIsSpousePropertyAttorneysComplete = createSelector(
  selectIsFieldComplete(
    selectSpouseArrangementsData,
    PROPERTY_ATTORNEYS_FIELD_ID,
  ),
  selectIsFieldComplete(
    selectSpouseArrangementsData,
    SPOUSE_IS_PROPERTY_ATTORNEY_FIELD_ID,
    true,
  ),
  (propertyAttorneyIsComplete, spouseIsPAIsComplete) =>
    spouseIsPAIsComplete || propertyAttorneyIsComplete,
);

const selectCanAddMorePropertyAttorneys = createSelector(
  selectSelectedProvince,
  selectAppointeeLevelIndexs(PROPERTY_ATTORNEYS_FORM_ID),
  (province, appointeeLevelIndexs) => {
    // Provinces outside BC can have as many property attorneys as they want
    if (province.code !== "BC") return true;
    // We only allow 2 property attorneys for BC because the document
    // signatures are getting truncated after the third backup
    const hasOnePOA = appointeeLevelIndexs.total < 1;
    if (hasOnePOA) return true;
    return false;
  },
);

const selectShouldShowCTAButton = createSelector(
  selectFormSpouseIsPropertyAttorney,
  selectHasSpouse,
  (isSpousePropertyAttorney, hasSpouse) => {
    return !hasSpouse || isSpousePropertyAttorney !== "";
  },
);

export const spousePropertyAttorneysPageProps = createStructuredSelector({
  hasLoaded: selectHasLoadedDataById(PROPERTY_ATTORNEYS_FORM_ID),
  isLoading: selectIsLoadingById(PROPERTY_ATTORNEYS_FORM_ID),
  appointeeLevelIndexs: selectAppointeeLevelIndexs(PROPERTY_ATTORNEYS_FORM_ID),
  hasSpouse: selectHasSpouse,
  spouseIsPropertyAttorney: selectFormSpouseIsPropertyAttorney,
  canAddMore: selectCanAddMorePropertyAttorneys,
  shouldShowCTAButton: selectShouldShowCTAButton,
  translations: createSelector(
    selectPropertyAttorneysTranslations,
    selectTranslations(["global", "spouseArrangements"]),
    (translations, { sectionTitle }) => ({ ...translations, sectionTitle }),
  ),
});
