import React, { useState } from "react";
import styled from "styled-components";
import { Box } from "@material-ui/core";
import { PieChart as ReactMinimalPieChart } from "react-minimal-pie-chart";
import { animationSpeed, colorPalette } from "../../../config/Theme";
import { SmallBody } from "../Typography";

const defaultLabelStyle = {
  fontFamily: "TT Norms",
  fontSize: "5px",
  fontWeight: "700",
  fill: colorPalette.willfulGrey,
};

const StyledColorCircle = styled.span`
  display: inline-block;
  position: relative;
  top: 4px;
  border-radius: 50%;
  border: 1px solid ${colorPalette.willfulGrey2};
  background-color: ${(props) => props.color};
  height: 16px;
  width: 16px;
  min-width: 16px;
  margin-right: 8px;
`;

const StyledLegendText = styled(SmallBody)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Legend = ({ chartData, useShortTitle }) => {
  return chartData.map((data, index) => {
    const { allocationName, valueStr, color, title } = data;
    let legendText = title;

    if (useShortTitle && allocationName.length >= 28) {
      legendText = `${allocationName.substring(0, 25)}... (${valueStr}%)`;
    }

    return (
      <Box
        key={`chart-legend-${index + 1}`}
        display="flex"
        alignItems="left"
        mb={0.5}
      >
        <StyledColorCircle color={color} />
        <StyledLegendText title={title} data-hj-suppress>
          {legendText}
        </StyledLegendText>
      </Box>
    );
  });
};

// The label prop expects a function that receives a `labelRenderProps` object as single argument:
// https://github.com/toomuchdesign/react-minimal-pie-chart#custom-labels-with-label-render-prop
const renderCustomLabel = ({ dataEntry }) =>
  `${Math.round(dataEntry.percentage)}%`;

// Chart props can be found on the library's GitHub page:
// https://github.com/toomuchdesign/react-minimal-pie-chart#options
const PieChart = ({
  minWidth = "100%",
  maxWidth = "100%",
  labelStyle = {},
  labelPosition = 60,
  startAngle = 270,
  lengthAngle = 360,
  chartData = [],
  tabIndex = 1,
  animate = true,
  animationDuration = animationSpeed.moderate,
  animationEasing = "ease-out",
  useShortTitle = true,
}) => {
  const [hideLabel, setHideLabel] = useState(animate);

  // the label style can be overriden by using the prop `labelStyle`
  const labelStyleProps = Object.assign(defaultLabelStyle, labelStyle);

  // filter out allocations with amount < 0 so the pie chart does not show 0% slices
  const filteredData = chartData.filter((data) => data.value > 0);

  // if there is only one valid value, the label stays centered
  let labelPositionProps = filteredData.length > 1 ? labelPosition : 0;

  // when the user adds many allocations we increase the label
  // position so it is easier to see the percentages
  if (filteredData.length > 8) {
    labelPositionProps = 70;
  }

  // During the chart animation we will hide the label temporarily
  // based on the animation duration
  if (animate) {
    setTimeout(() => {
      setHideLabel(false);
    }, animationDuration);
  }

  return (
    <Box align="center">
      <Box minWidth={minWidth} maxWidth={maxWidth}>
        <ReactMinimalPieChart
          data={filteredData}
          segmentsTabIndex={tabIndex}
          animate={hideLabel}
          animationDuration={animationDuration}
          label={hideLabel ? false : renderCustomLabel}
          labelPosition={labelPositionProps}
          labelStyle={{
            ...labelStyleProps,
          }}
          startAngle={startAngle}
          lengthAngle={lengthAngle}
          animationEasing={animationEasing}
        />
      </Box>
      <Box minWidth={minWidth} maxWidth={maxWidth} mt={2}>
        <Legend chartData={chartData} useShortTitle={useShortTitle} />
      </Box>
    </Box>
  );
};

export default PieChart;
